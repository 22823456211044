import React from 'react';
import {
  AtributesStyled,
  AttributesItem,
  AttributesItemText,
  AttributesItemTitle,
  Pill
} from './attributes-styled'; // Ensure this path is correct

export const Attributes = ({ attributes }) => {
  return (
    <AtributesStyled>
      {
        attributes?.map((attribute, index) => (
          <AttributesItem key={index}>
            <AttributesItemText>
              <AttributesItemTitle>{attribute.name}:</AttributesItemTitle>
              <AttributesItemText>{attribute.value}</AttributesItemText>
            </AttributesItemText>
          </AttributesItem>
        ))
      }
    </AtributesStyled>
  );
};

import { useEffect, useState } from 'react';
import { PCardBasicView } from './p-card-basic-view';
import { PCardStoreFront } from './p-card-store-front';
import { PCardClothingView } from './p-card-clothing';
import { PCardDealsView } from './p-card-deals';
import { PCardGroceriesView } from './p-card-groceries-view';
import { PCardOnlyViewView } from './p-card-only-view-view';
import PCardTradesView from './p-card-trades-view';

export const ProductCardTypes = {
    BASIC: 'BASIC',
    GROCERIES: 'GROCERIES',
    AUCTION: 'AUCTION',
    ONLY_VIEW: 'ONLY_VIEW',
    TRADES: 'TRADES',
    CLOTHING: 'CLOTHING',
    DEALS: 'DEALS',
    STORE: 'STORE'
};

export const PCard = ({ product, type, hasLabel, currentViewType, business }) => {
    const [isFav, setIsFav] = useState(false);

    const handleFavBtnClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsFav(!isFav);
    };

    switch (type) {
        case ProductCardTypes.BASIC:
            return (
                <PCardBasicView
                    product={product}
                    hasLabel={hasLabel}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                />
            );
        case ProductCardTypes.STORE:
            return (
                <PCardStoreFront
                    product={product}
                    hasLabel={hasLabel}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                    business={business}
                />
            );
        case ProductCardTypes.GROCERIES:
            return (
                <PCardGroceriesView
                    product={product}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                    currentViewType={currentViewType}
                />
            );
        case ProductCardTypes.ONLY_VIEW:
            return (
                <PCardOnlyViewView
                    product={product}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                    currentViewType={currentViewType}
                />
            );
        case ProductCardTypes.TRADES:
            return (
                <PCardTradesView
                    product={product}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                    currentViewType={currentViewType}
                />
            );
        case ProductCardTypes.CLOTHING:
            return (
                <PCardClothingView
                    product={product}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                    currentViewType={currentViewType}
                />
            );
        case ProductCardTypes.DEALS:
            return (
                <PCardDealsView
                    product={product}
                    handleFavBtnClick={handleFavBtnClick}
                    isFav={isFav}
                />
            );
    }
};

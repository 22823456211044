import styled, { css } from 'styled-components';
import { viewType } from '../view-selection/view-selection';
import { device } from '../../assets/style-helpers/breakpoints';

export const ImgWrapper = styled.div`
    height: 220px;
    width: 100%;
    position: relative;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.currentViewType === viewType.list &&
        css`
            @media ${device.mobile} {
                height: unset;
                max-width: 112px;
            }
        `}

    &.card-slider-list-view {
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

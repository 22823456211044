import { BreadcrumbsLink, Container } from './breadcrumbs-styled';

export const Breadcrumbs = ({ links, product }) => {
  
  const categories = product?.data?.categories;
  
  return (
    <Container>
      {categories && categories.length > 0 ? (
        categories.map((category, i) => (
          <BreadcrumbsLink
            to={`/category/${category}`} 
            key={i}
            isActive={i === categories.length - 1} 
          >
            {category} {/* Display the category name */}
          </BreadcrumbsLink>
        ))
      ) : (
        <BreadcrumbsLink to="#"></BreadcrumbsLink> 
      )}
    </Container>
  );
  

   {/* return (
    <Container>
      {links ? (
        links.map((link, i) => {
          return (
            <BreadcrumbsLink to={link.link} key={i} isActive={i === links.length - 1}>
              {link.name}
            </BreadcrumbsLink>
          );
        })
      ) : (
        <>
          <BreadcrumbsLink to='#'></BreadcrumbsLink>
          <BreadcrumbsLink isActive to='#'>
          
          </BreadcrumbsLink>
        </>
      )}
    </Container>
  );*/
  }  
};

import thumbnail from '../../../../assets/all-order-sidebar-table-icon.png';
import { ReactComponent as Dots } from '../../../../assets/dots-services.svg';
import Product from '../../../../assets/product.png';
import { ReactComponent as Star } from '../../../../assets/star-2.svg';
import Checkbox from '../../../../components/checkbox/checkbox';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { Radio } from '../../../../components/radio/radio';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import {
  ActionsWrapper,
  Categories,
  CheckboxWrapper,
  CheckboxWrapperHeader,
  DropdownWrapper,
  ProductBox,
  ServiceDescription,
  ServiceTitle,
  SidebarInputWrapper,
  StarBox,
  Status,
  StyledId,
  Thumbnail
} from './all-sell-items-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';

export const Tags = ['Dishwasher', 'Zara Blue Chino', '+2 more'];

export const columnsData = [

  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true
  },
  {
    Header: 'Product',
    accessor: 'product',
    disableSortBy: true
  },
  {
    Header: 'Creation Date',
    accessor: 'creationdate'
  },
  {
    Header: 'Categories',
    accessor: 'categories'
  },
  {
    Header: 'Regular Price',
    accessor: 'regularprice'
  },
  {
    Header: 'Sale Price',
    accessor: 'saleprice'
  },
  {
    Header: 'Stock',
    accessor: 'stock'
  },

  {
    Header: 'Orders',
    accessor: 'orders'
  },

  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true
  }
];
export const columnsRows = [
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    thumbnail: <Thumbnail src={Product} alt='Photo' />,
    product: (
      <ProductBox>
        <ServiceTitle>NEW Phone Huawei Y7</ServiceTitle>
        <ServiceDescription>
          Lorem ipsum dolor sit amet consectetur. Amet et tincidu...
        </ServiceDescription>
      </ProductBox>
    ),
    creationdate: (
      <Status>
        <Radio id={'2'} isChecked={true} name={'status1'} title={'Published'} value={'no'} />
        <div>25 Sep 2023</div>
      </Status>
    ),
    daysleft: '30',
    categories: <Categories>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</Categories>,
    regularprice: '$250 000',
    saleprice: '$250 000',
    stock: '50k+',
    type: 'For Sale',
    orders: '50k+',
    featured: (
      <StarBox>
        <Star />
      </StarBox>
    ),
    actions: (
      <ActionsWrapper>
        <GlobalButton type={'primary'}>Promote</GlobalButton>
        <Dots />
      </ActionsWrapper>
    )
  },
  {
    id: (
      <StyledId>
        <CheckboxWrapper>
          <Checkbox withoutCheckmark />
        </CheckboxWrapper>
        12520
      </StyledId>
    ),
    thumbnail: <Thumbnail src={Product} alt='Photo' />,
    product: (
      <ProductBox>
        <ServiceTitle>NEW Phone Huawei Y7</ServiceTitle>
        <ServiceDescription>
          Lorem ipsum dolor sit amet consectetur. Amet et tincidu...
        </ServiceDescription>
      </ProductBox>
    ),
    creationdate: (
      <Status>
        <Radio id={'1'} isChecked={true} name={'status'} title={'Published'} value={'no'} />
        <div>25 Sep 2023</div>
      </Status>
    ),
    daysleft: '30',
    categories: <Categories>Clothing & Apparel / Shoes / Formal Shoes / Men`s Shoes</Categories>,
    regularprice: '$250 000',
    saleprice: '$250 000',
    stock: '50k+',
    type: 'For Sale',
    orders: '50k+',
    featured: (
      <StarBox>
        <Star />
      </StarBox>
    ),
    actions: (
      <ActionsWrapper>
        <GlobalButton type={'primary'}>Promote</GlobalButton>
        <Dots />
      </ActionsWrapper>
    )
  }
];

export const SidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail111'
  },
  {
    Header: 'Product Name',
    accessor: 'productName'
  },
  {
    Header: 'Colour',
    accessor: 'colour'
  },
  {
    Header: 'Capacity',
    accessor: 'capacity'
  },
  {
    Header: 'Model',
    accessor: 'model'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const SidebarColumnsRows = [
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    colour: (
      <DropdownWrapper>
        <Dropdown name={'Maroon'} withBorders />
      </DropdownWrapper>
    ),
    capacity: (
      <DropdownWrapper>
        <Dropdown name={'3kg'} withBorders />
      </DropdownWrapper>
    ),
    model: (
      <DropdownWrapper>
        <Dropdown name={'KM100'} withBorders />
      </DropdownWrapper>
    ),
    qty: (
      <SidebarInputWrapper>
        <GlobalInput value={'1'} width={'40px'} />
      </SidebarInputWrapper>
    ),
    price: (
      <SidebarInputWrapper>
        <GlobalInput value={'$2000'} width={'58px'} />
      </SidebarInputWrapper>
    ),
    total: '$2000'
  }
];

export const SecondSidebarColumnsData = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail'
  },
  {
    Header: 'Product Name',
    accessor: 'productName'
  },
  {
    Header: 'Qty',
    accessor: 'qty'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
];

export const SecondSidebarColumnsRows = [
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  },
  {
    id: 12520,
    thumbnail: <Thumbnail src={thumbnail} />,
    productName: 'Dish Washing Machine',
    qty: 100,
    price: '$200 000 000',
    total: '$200 000 000'
  }
];

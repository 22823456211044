import {
    InputGroupWrapper,
    Section,
    SectionTitle
  } from 'pages/dashboard/pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../../../components/input-group/input-group';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalButton } from 'components/global-button/global-button';
import { useState } from 'react';
import { Star } from './reviews-styled';
import { createProductReview } from '../../../../../../redux/slices/products/reviews/productReviewsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
  
  export const AddProductReview = ({product}) => {
  
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const { status } = useSelector((state) => state.reviews);
    const {slug} = useParams();

    const [reviewData, setReviewData] = useState({
        review: '',
        rating: 0
      });

      const handleStarClick = (index) => {
        setReviewData((prevState) => ({
          ...prevState,
          rating: index + 1
        }));
      };

      const handleReviewSubmit = () => {
        if (reviewData.review.trim() === '' || reviewData.rating === 0) {
          setValidationError('Please provide a review description and rating.');
          return;
        }    
        setValidationError('');
        setLoading(true);
        dispatch(createProductReview({
          productID: product.id,
          review: reviewData.review,
          rating: reviewData.rating,
          authorID: 1,
          slug: slug
        }));
        setLoading(false);
      };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setValidationError('');
        setReviewData((prevState) => ({
          ...prevState,
          [name]: value
        }));
      };
  
  
    return (
      <Section>
        <SectionTitle>Add a Review</SectionTitle>
        
        <InputGroupWrapper>
            {[...Array(5)].map((_, index) => (
            <Star
                key={index}
                selected={index < reviewData.rating}
                onClick={() => handleStarClick(index)}
            >
                ★
            </Star>
            ))}
        </InputGroupWrapper>
        <InputGroupWrapper>
          {/* TODO {Future release} use AI to help vendor generate business description */}
          <InputGroup >
            <GlobalTextarea placeholder={'write a review'} name="review" onChange={handleChange}  value={reviewData.description} />
          </InputGroup>
        </InputGroupWrapper>
        {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
        <InputGroupWrapper>
        <GlobalButton onClick={handleReviewSubmit}>
             {status == "submitting" ? <><ColourRingLoader height={12}  width={12}/>{'Submitting'}</>: "Submit"}
          </GlobalButton>
        </InputGroupWrapper>
      </Section>
    );
  };
  
import {
    CheckboxWrapper,
    GridInputWrapper,
    PricingBtnWrapper,
    PricingRow,
    Section,
    SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { Link } from 'react-router-dom';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { useState } from 'react';

export const ProductPricing = ({ selectOptions, updateProductDetails, productPrices }) => {
    const handleCurrencyChange = (index, value) => {};

    // const handleChange = (key, index, field, value) => {
    //     const updatedPrices = { ...productPrices };
    //     updatedPrices[key][index][field] = value;
    //     updateProductDetails('productPrices', updatedPrices);
    // };
    const handleChange = (key, index, field, value) => {
        const updatedPrices = { ...productPrices };
        if (!updatedPrices[key]) {
            updatedPrices[key] = {};
        }
        updatedPrices[key][field] = value;
        updateProductDetails('productPrices', updatedPrices);
    };

    return (
        <Section>
            <SectionTitle>Pricing</SectionTitle>
            <CheckboxWrapper>
                {/* <GlobalCheckbox>Price is on inquiry</GlobalCheckbox> */}

                <GridInputWrapper>
                    <InputGroup label={'Currency'}>
                        <GlobalSelect
                            options={[{ label: 'USD', value: 'USD' }]}
                            value={{ label: 'USD', value: 'USD' }}
                            isDisabled={true}
                            placeholder={'USD'}

                            // onChange={(value) => handleCurrencyChange(value)}
                        />
                    </InputGroup>
                    <InputGroup label={'Regular price'}>
                        <GlobalInput
                            placeholder={'$'}
                            // value={entry.regularPrice}
                            // onChange={(e) => handleChange('regularPrice', e.target.value)}
                            onChange={(e) =>
                                handleChange('regularPrice', 0, 'amount', e.target.value)
                            }
                            type={'number'}
                        />
                    </InputGroup>
                    <InputGroup label={'Sale price'}>
                        <GlobalInput
                            placeholder={'$'}
                            // value={entry.salePrice}
                            // onChange={(e) => handleChange('salePrice', e.target.value)}
                            onChange={(e) => handleChange('salePrice', 0, 'amount', e.target.value)}
                            type={'number'}
                        />
                    </InputGroup>
                </GridInputWrapper>
            </CheckboxWrapper>
        </Section>
    );
};
// const [selectedCurrencies, setSelectedCurrencies] = useState([
//     {
//         // currency: productPrices.currency?.label,
//         salePrice: [
//             {
//                 currency: 'USD',
//                 amount: productPrices.salePrice
//             }
//         ],
//         regularPrice: [
//             {
//                 currency: 'USD',
//                 amount: productPrices.regularPrice
//             }
//         ]
//     }
// ]);
// const handleChange = (key, value) => {
//     const updatedCurrencies = { ...productPrices, [key]: value };
//     updateProductDetails('productPrices', updatedCurrencies);
// };

// const availableCurrencies = selectOptions.filter(
//     (option) => !selectedCurrencies.some((item) => item.currency === option.value)
// );

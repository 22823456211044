import React from 'react';

import {
  Badge,
  Buttons,
  BuyBtn,
  ImgWrapper,
  Main,
  MainPriceTradesView,
  Price,
  MainBox,
  ContactsBox,
  TitleTradesView,
  Text,
  ContainerTradesView,
  RateStarsDesktop,
  ContentTradesView,
  ZWLPrice
} from './p-card-styled';

import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import { ApproveSeller } from '../approve-seller/approve-seller';
import { FavBtn } from '../categories-card/categories-card-styled';
import { viewType } from '../view-selection/view-selection';
import { getRouteById } from 'routes/routesHelper';
import { Link } from 'react-router-dom';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist} from '../../redux/slices/product/productSlice';
import { useDispatch , useSelector} from 'react-redux';

const PCardTradesView = ({ product, isMobile, currentViewType }) => {
  const { productsInCart, wishslist } = useSelector((state) => state.product);
  const productUrl = getRouteById('product', { slug: product.slug });

     const handleAddToCart = () => {
          // dispatch(addProductToCart({ product, quantity: 1, variation: { color: 'red', size: 'M' } }));
          dispatch(addProductToCart({ product, quantity: 1, variation: { } }))
     };
  
      const isProductInCart = productsInCart?.some(item => item.product.id === product.id);

  return (
    <ContainerTradesView currentViewType={currentViewType}>
      
      <ImgWrapper currentViewType={currentViewType}>
        <ApproveSeller />
        <img src={product.image.url} alt={product.title} />
        </ImgWrapper>
    
      <Main currentViewType={currentViewType}>
        <MainBox>
          <RateStarsDesktop>
            {product.rating && (
              <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Link to={productUrl}>
          <TitleTradesView currentViewType={currentViewType}>{product.title}</TitleTradesView>
          {product.description && (
              <Text>
                {product.description.length > 80
                  ? `${product.description.substring(0, 80)}...`
                  : product.description}
              </Text>
            )}
          </Link>
        <ContentTradesView>
          <Price currentViewType={currentViewType}>
            <MainPriceTradesView currentViewType={currentViewType}>
              {product.normalPrice?.amount}
            </MainPriceTradesView>
            {/* <ZWLPrice currentViewType={currentViewType}>ZWL 200</ZWLPrice> */}
          </Price>
          <Buttons>
            
          </Buttons>

          <Buttons>
            {isProductInCart ? (
                <Link to="/cart">
                      <Button>
                        View
                    </Button>
                </Link>
            ) : (
                <>
                {currentViewType === viewType.list && (
              <FavBtn landScape={currentViewType === viewType.list}>
                <SvgIcon name={'heart-2'} />
              </FavBtn>
            )}
            <Link to={productUrl}>
              <BuyBtn>
                <SvgIcon name='eye' />
                </BuyBtn>
                  </Link>
                </>
            )}
        </Buttons>
        </ContentTradesView>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </ContainerTradesView>
  );
};

export default PCardTradesView;

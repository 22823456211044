import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { Container, Title } from '../add-new-product/add-new-product-styled';
import {
  CalendarBtn,
  StatCardStyled,
  StatsWrapper,
  TitleWrapper,
  StatCardTop,
  StatCardTitle,
  Value,
  StatCardIconWrapper,
  Percent,
  Side,
  Wrapper,
  ChartWrapper,
  Chart,
  ChartLegend,
  LegendItemGreen,
  LegendItemRed,
  LegendItemOrange,
  Main,
  StyledTable,
  MessageStyled,
  MessageAvatar,
  MessageMain,
  MessageTop,
  MessageAuthor,
  MessageDate,
  MessagetText,
  MessageList
} from './dashboard-main-styled';

import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as DollarIcon } from './icons/currency-dollar.svg';
import { ReactComponent as PackageIcon } from './icons/package.svg';
import { ReactComponent as StorefrontIcon } from './icons/storefront.svg';
import chartImg from './temp-img/dashboard.png';
import avatarImg from './temp-img/avatar.png';
import { DashboardWidget } from '../../components/dashboard-widget/dashboard-widget';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAnalytics } from '../../../../redux/slices/dashboard/dashboardSlice';
import { getRouteById } from 'routes/routesHelper';
import { DayPicker } from 'react-day-picker';
import DateRangePicker from 'pages/dashboard/components/date-range-picker/date-range-picker';
import { WhatsupIcon } from 'components/svg-icon/icons/whatsup-icon';
import { PhoneIcon } from 'components/svg-icon/icons/phone-icon';
import GeneralLineChart from 'pages/dashboard/components/charts/general-line-chart/general-line-chart';
import { Tooltip } from 'components/tooltip/tooltip';
import StatsLoader from 'pages/dashboard/components/preloaders/statsLoader';
import GraphLoader from 'pages/dashboard/components/preloaders/graph-loader';

const breadcrumbs = [
    // { link: '#', name: 'Sell Items' },
    // { link: '#', name: 'Dashboard' }
];

const StatCard = ({ icon, title, percent, value, tooltipText }) => (
    <StatCardStyled>

      <StatCardTop>
        <StatCardIconWrapper>{icon}</StatCardIconWrapper>
        <StatCardTitle>{title}</StatCardTitle> &nbsp;
        <Tooltip>{tooltipText}</Tooltip>
      </StatCardTop>
    <Value>{value}</Value>
    {/* <Percent>
      {percent > 0 ? (
          <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M3.44859 0.4685C3.73049 0.11076 4.2695 0.110761 4.55141 0.468501L7.84597 4.64934C8.2124 5.11435 7.88379 5.8002 7.29456 5.8002H0.705444C0.116207 5.8002 -0.212401 5.11435 0.154035 4.64934L3.44859 0.4685Z'
            fill='#388638'
          />
        </svg>
      ) : (
          <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M3.44859 5.5315C3.73049 5.88924 4.2695 5.88924 4.55141 5.5315L7.84597 1.35066C8.2124 0.885649 7.88379 0.199805 7.29456 0.199805H0.705444C0.116207 0.199805 -0.212401 0.88565 0.154035 1.35066L3.44859 5.5315Z'
            fill='#E54D45'
            />
        </svg>
      )}
      {percent > 0 && '+'} {percent}% than last year
    </Percent> */}
  </StatCardStyled>
);

const Message = () => {
    return (
        <MessageStyled>
      <MessageAvatar>
        <img src={avatarImg} alt='avatar' />
      </MessageAvatar>
      <MessageMain>
        <MessageTop>
          <MessageAuthor>Langton Shoriwa</MessageAuthor>
          <MessageDate>1 day ago</MessageDate>
        </MessageTop>
        <MessagetText>
          Lorem ipsum dolor sit amet consectetur Sagittissti ipsum dolor sit amet
        </MessagetText>
      </MessageMain>
    </MessageStyled>
  );
};


export const DashboardMain = () => {
    const [dashboardStats, setDashboardStats] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState({
      start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end: new Date()
    });
    const [endDate, setEndDate] = useState(null);

    const dispatch = useDispatch();
    const stats = [
        { title: 'Product Views', tooltipText: 'Number of times your products were viewed.',icon: <PackageIcon />, value: dashboardStats?.views.products, percent: '3' },
        { title: 'Storefront Views', tooltipText:'Number of times your storefront was viewed.', icon: <StorefrontIcon />, value: dashboardStats?.views.storefront, percent: '-23' },
        { title: 'WA Messages', tooltipText: 'Number of times buyers tried to contact you via WhatsApp.' ,icon: <WhatsupIcon />, value: dashboardStats?.leads.whatsapp, percent: '-23' },
        { title: 'Calls Initiated', tooltipText: 'Number of times buyers tried to call your business.' , icon: <PhoneIcon />, value: dashboardStats?.leads.calls, percent: '-23' },
        { title: 'Total Orders', tooltipText: 'Total number of orders placed online.' , icon: <CartIcon />, value: dashboardStats?.orders, percent: '3' },
        { title: 'Total Revenue', tooltipText: 'Total order value', icon: <DollarIcon />, value: dashboardStats?.totalRevenue, percent: '23' }
    ];

    const params = {
        bID: 80,
        range: selectedDateRange
    }
    
    const {status} = useSelector((state) =>state?.dashboard);
    console.log(status);
        
    useEffect(() => {
        dispatch(getDashboardAnalytics(params)).then((result) => {
            console.log(result.payload.data);
        setDashboardStats(result.payload.data);
        })
    }, [selectedDateRange]);

    const handleDateRangeChange = (range) => {
      console.log(range);
      setSelectedDateRange(range);
    }

  return (
    <Container>
      <Breadcrumbs links={breadcrumbs} />
      <Wrapper>
        <Main>
          <TitleWrapper>
            <Title>Dashboard</Title>
            <DateRangePicker handleDateRangeChange={ handleDateRangeChange} range={selectedDateRange}/>

            {/* <CalendarBtn>
              { dashboardStats?.range.start + ' - ' + dashboardStats?.range.end }
              <svg
                width='8'
                height='6'
                viewBox='0 0 8 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 1.5L4 4.5L7 1.5'
                  stroke='#29263A'
                  strokeWidth='1.7'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              
            </CalendarBtn> */}
          </TitleWrapper>
          <StatsWrapper>
           
            {stats.map((item, i) => (
              status == 'loading' ?
              <StatCardStyled>
                  <StatsLoader/>
              </StatCardStyled>
              :
              <StatCard
                key={i}
                icon={item.icon}
                title={item.title}
                value={item.value}
                percent={item.percent}
                tooltipText={item.tooltipText}
              />
            ))}
          </StatsWrapper>
          <ChartWrapper>
            <Chart>
              {
                status == 'loading' ?
                <GraphLoader/>
                :
                <GeneralLineChart data={dashboardStats?.graphData} title= {'Store Perfomance'} />
              }
            </Chart>
            {/* <ChartLegend>
              <LegendItemGreen>Orders</LegendItemGreen>
              <LegendItemRed>Views</LegendItemRed>
              <LegendItemOrange>Enquiries</LegendItemOrange>
            </ChartLegend> */}
          </ChartWrapper>
        </Main>
        <Side>
        <DashboardWidget title={'New Orders'} notifications={dashboardStats?.newOrders?.length || 0} link={getRouteById('newOrders')}>
                    <StyledTable>
                        {
                        dashboardStats?.orders > 0 ? (
                            <>
                                <table>
                                    <tr>
                                        <th>ID</th>
                                        <th>Customer</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Total</th>
                                    </tr>

                                    
                                    {dashboardStats?.newOrders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{order.id}</td>
                                            <td>{order.customer.name}</td>
                                            <td>{order.date}</td>
                                            <td>{order.status}</td>
                                            <td>{order.orderValue.currency}{order.orderValue.value}</td>
                                        </tr>
                                    ))}

                                </table>
                            </>
                        ) : (
                            <></>
                        )
                        }
                    </StyledTable>
                </DashboardWidget>
        </Side>
      </Wrapper>
    </Container>
  );
};

import { RateStars } from '../../../../../rate-stars/rate-stars';
import {
  Btn,
  Container,
  ReportCard,
  ReportCardAvatar,
  ReportCardName,
  ReportCardText,
  ReportCardTop,
  ReportCartBottom,
  ScrollableContainer,
  Time
} from './reviews-styled';
import { useDispatch, useSelector } from 'react-redux';
import { getProductReviews } from '../../../../../../redux/slices/products/reviews/productReviewsSlice';
import { AddProductReview } from './add-review';
import React, { useEffect, useState } from 'react';
import { Description } from 'pages/dashboard/pages/business-information/components/business-settings-sidebar-styled';
import { useParams } from 'react-router';
import { SectionTitle } from 'pages/dashboard/components/additional-information-popup/additional-information-popup-styled';

const AvatarIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle r='14.5' transform='matrix(-1 0 0 1 15 15)' stroke='#E2E5EB' />
      <path
        d='M9 20C10.5572 18.2512 12.6713 17.1765 15 17.1765C17.3287 17.1765 19.4428 18.2512 21 20M18 11.1765C18 12.9308 16.6569 14.3529 15 14.3529C13.3431 14.3529 12 12.9308 12 11.1765C12 9.42215 13.3431 8 15 8C16.6569 8 18 9.42215 18 11.1765Z'
        stroke='#C7CED6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const TimeIcon = () => {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2560_87628)'>
        <path
          d='M6.99935 3.99996V7.49996L9.33268 8.66663M12.8327 7.49996C12.8327 10.7216 10.221 13.3333 6.99935 13.3333C3.77769 13.3333 1.16602 10.7216 1.16602 7.49996C1.16602 4.2783 3.77769 1.66663 6.99935 1.66663C10.221 1.66663 12.8327 4.2783 12.8327 7.49996Z'
          stroke='#ACADB9'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2560_87628'>
          <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Reviews = ({ product }) => {
  const dispatch = useDispatch();
  const {slug} = useParams();
  const [showMore, setShowMore] = useState(false);
  const { reviewSubmitted, reviews, status, error } = useSelector((state) => state.reviews);

  if (status === 'failed') {
    //return <div>Error: {error}</div>;
  }

  const handleReadMore = () => {
    dispatch(getProductReviews({ slug: slug }));
    setShowMore(true);
  };
  
  const displayedReviews = showMore || reviewSubmitted ? reviews : (product?.data?.reviews || []);

  return (
    <Container>
      {reviewSubmitted ? <SectionTitle style={{ color: 'green' }}>Your review was submitted</SectionTitle>:<AddProductReview product={product.data} />}
      {displayedReviews?.length > 0 ? (
        <ScrollableContainer>
          {displayedReviews.map((review, index) => (
            <ReportCard key={index}>
              <ReportCardTop>
                <ReportCardAvatar>
                  <AvatarIcon />
                </ReportCardAvatar>
                <ReportCardName>{review.reviewer}</ReportCardName>
              </ReportCardTop>
              <RateStars rate={review.rating} height={14} width={14} />
              <ReportCardText>{review.review}</ReportCardText>
              <ReportCartBottom>
                <Time>
                  <TimeIcon />
                  {review.date}
                </Time>
                <Btn>Reply</Btn>
              </ReportCartBottom>
            </ReportCard>
          ))}
          {!showMore && product.data.reviews.length >= 5 && (
            <Btn onClick={handleReadMore}>Read More</Btn>
          )}
        </ScrollableContainer>
      ) : (
        <Description></Description>
      )}
      
    </Container>
  );
};
import { ModalWrapper } from '../../../../components/modal-wrapper/modal-wrapper';
import {
    Btns,
    CheboxSectionWrapper,
    CheckboxSection,
    Container,
    RadiosWrapper,
    Section,
    SectionTitle,
    Title
} from './additional-information-popup-styled';
import Checkbox from '../../../../components/checkbox/checkbox';
import { Radio } from '../../../../components/radio/radio';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { useState } from 'react';

const checkboxes = ['Everything Zimbabwean', 'Vendor delivery', 'No delivery'];

export const AdditionalInformationPopup = ({
    isVisible,
    onClose,
    onSaveProduct,
    updateProductDetails
}) => {
    const [selectedMethods, setSelectedMethods] = useState([]);

    const handleCheckboxChange = (label) => {
        setSelectedMethods((prev) =>
            prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
        );
        updateProductDetails('deliveryMethod', selectedMethods);
    };

    return (
        <ModalWrapper isVisible={isVisible} onClose={onClose}>
            <Container>
                <Title>Additional Information</Title>

                <Section>
                    <SectionTitle>
                        <svg
                            width='20'
                            height='21'
                            viewBox='0 0 20 21'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M11.666 5.77777H13.6137C13.8176 5.77777 13.9195 5.77777 14.0154 5.8008C14.1004 5.82121 14.1817 5.85488 14.2563 5.90057C14.3404 5.95211 14.4124 6.02417 14.5565 6.16829L17.9422 9.55391C18.0863 9.69804 18.1583 9.7701 18.2099 9.8542C18.2556 9.92876 18.2892 10.01 18.3097 10.0951C18.3327 10.191 18.3327 10.2929 18.3327 10.4967V12.8611C18.3327 13.2494 18.3327 13.4435 18.2692 13.5967C18.1847 13.8009 18.0224 13.9631 17.8183 14.0477C17.6651 14.1111 17.471 14.1111 17.0827 14.1111M12.916 14.1111H11.666M11.666 14.1111V5.94444C11.666 5.01102 11.666 4.54431 11.4844 4.18779C11.3246 3.87418 11.0696 3.61922 10.756 3.45943C10.3995 3.27777 9.93277 3.27777 8.99935 3.27777H4.33268C3.39926 3.27777 2.93255 3.27777 2.57603 3.45943C2.26243 3.61922 2.00746 3.87418 1.84767 4.18779C1.66602 4.54431 1.66602 5.01102 1.66602 5.94444V12.4444C1.66602 13.3649 2.41221 14.1111 3.33268 14.1111M11.666 14.1111H8.33268M8.33268 14.1111C8.33268 15.4918 7.21339 16.6111 5.83268 16.6111C4.45197 16.6111 3.33268 15.4918 3.33268 14.1111M8.33268 14.1111C8.33268 12.7304 7.21339 11.6111 5.83268 11.6111C4.45197 11.6111 3.33268 12.7304 3.33268 14.1111M17.0827 14.5278C17.0827 15.6784 16.1499 16.6111 14.9993 16.6111C13.8488 16.6111 12.916 15.6784 12.916 14.5278C12.916 13.3772 13.8488 12.4444 14.9993 12.4444C16.1499 12.4444 17.0827 13.3772 17.0827 14.5278Z'
                                stroke='#29263A'
                                strokeWidth='1.6'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Delivery Methods/Options
                    </SectionTitle>
                    <CheboxSectionWrapper>
                        <CheckboxSection>
                            {checkboxes.map((item) => {
                                return (
                                    <Checkbox
                                        label={item}
                                        key={item}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                );
                            })}
                        </CheckboxSection>
                    </CheboxSectionWrapper>
                </Section>

                {/* <Section>
                    <SectionTitle>
                        <svg
                            width='20'
                            height='21'
                            viewBox='0 0 20 21'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M7.5625 9.62428L9.1875 11.241L12.8438 7.60342M16.5 10.0284C16.5 13.9961 12.1499 16.8819 10.5671 17.8006C10.3872 17.905 10.2973 17.9572 10.1704 17.9842C10.0719 18.0053 9.92814 18.0053 9.82963 17.9842C9.7027 17.9572 9.61276 17.905 9.43288 17.8006C7.85009 16.8819 3.5 13.9961 3.5 10.0284V6.16264C3.5 5.51636 3.5 5.19322 3.60624 4.91545C3.7001 4.67007 3.85261 4.45112 4.0506 4.27753C4.27471 4.08103 4.57884 3.96756 5.18708 3.74064L9.54354 2.11533C9.71245 2.05231 9.79691 2.0208 9.8838 2.00831C9.96087 1.99723 10.0391 1.99723 10.1162 2.00831C10.2031 2.0208 10.2875 2.05231 10.4565 2.11533L14.8129 3.74064C15.4212 3.96756 15.7253 4.08103 15.9494 4.27753C16.1474 4.45112 16.2999 4.67007 16.3938 4.91545C16.5 5.19322 16.5 5.51636 16.5 6.16264V10.0284Z'
                                stroke='#29263A'
                                strokeWidth='1.6'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Warranty Options
                    </SectionTitle>
                    <RadiosWrapper>
                        <Radio
                            isChecked={true}
                            title={
                                'Limited Warranty. Lorem ipsum dolor sit amet consectetur. Leo portti vitae at at mattis ultrices parturient vel.'
                            }
                        />
                        <Radio
                            isChecked={false}
                            title={
                                'Full Warranty. Lorem ipsum dolor sit amet consectetur. Leo portti vitae at at mattis ultrices parturient vel.'
                            }
                        />
                    </RadiosWrapper>
                </Section> */}

                <Btns>
                    <GlobalButton type={'gray'} onClick={onClose}>
                        <svg
                            width='21'
                            height='20'
                            viewBox='0 0 21 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M8.25 7.5L13.25 12.5M13.25 7.5L8.25 12.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                stroke='#ACADB9'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Cancel
                    </GlobalButton>
                    <GlobalButton type={'primary'} onClick={onSaveProduct}>
                        <svg
                            width='21'
                            height='20'
                            viewBox='0 0 21 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M7 10L9.5 12.5L14.5 7.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                stroke='white'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Publish product
                    </GlobalButton>
                </Btns>
            </Container>
        </ModalWrapper>
    );
};

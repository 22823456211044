import {
  Container,
  ImgWrapper,
  OldPrice,
  Price,
  MainPrice,
  Title,
  MainBox,
  Main,
  Label,
  RateStarsDesktop,
  Content,
  ContactsBox,
  Buttons,
  FavBtn4,
  LabelNotAvailable,
  BuyButton,
  Description
} from './p-card-small-styled';

import { SvgIcon } from '../svg-icon/svg-icon';

import { RateStars } from '../rate-stars/rate-stars';
import { LabelInStock, LabelOutStock, ZWLPrice } from '../p-card/p-card-styled';
import { ApproveSeller } from '../approve-seller/approve-seller';
import React, { useState } from 'react';
import { getRouteById } from 'routes/routesHelper';
import { Link } from 'react-router-dom';

export const PCardSmall = ({ vendor, product, className }) => {
  const [isFav, setIsFav] = useState(false);

   const productUrl = getRouteById('product', { slug: product.slug }); 

  const handleFavBtnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFav(!isFav);
  };
  return (
    <Container className={className}>
      <Link to={productUrl}>
        <ImgWrapper>
          <ApproveSeller />
          <img src={product?.image?.url} alt={product.image?.altText} />
          <FavBtn4 onClick={handleFavBtnClick} isFav={isFav}>
            <SvgIcon name={'heart-2'} />
          </FavBtn4>
          {product?.availability?.status ? (
            <LabelInStock>Stock</LabelInStock>
          ) : (
            <LabelOutStock>Out stock</LabelOutStock>
          )}
          {product.delivery ? (
            <Label>Delivery Available</Label>
          ) : (
            <LabelNotAvailable>Delivery not available</LabelNotAvailable>
          )}
          </ImgWrapper>
        </Link>
      <Main>
        <MainBox>
          <RateStarsDesktop>
            {product.rating && (
              <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <Link to={productUrl}>
          <Title>{product.title}</Title>
          <Description>{vendor}</Description>
        </Link>
        <Content>
          <Price>
            <OldPrice>{product?.price.normalPrice?.[0]?.currency} {product?.price.normalPrice?.[0]?.amount}</OldPrice>
            <MainPrice>{product?.price.normalPrice?.[0]?.currency} {product?.price.normalPrice?.[0]?.amount}</MainPrice>
            {/* <ZWLPrice>ZWL 200</ZWLPrice> */}
          </Price>
          <Buttons>
            <BuyButton>
              <SvgIcon name={'cart'} />
            </BuyButton>
          </Buttons>
        </Content>
      </Main>
    </Container>
  );
};

import {
    IconWrapper,
    ProductCardStyled,
    ProductCardSubtitle,
    ProductCardTitle
} from './dashboard-item-card-styled';

export const DashboardItemCard = ({ icon, title, subtitle, children }) => {
    return (
        <ProductCardStyled>
            <IconWrapper>{icon}</IconWrapper>
            <ProductCardTitle>{title}</ProductCardTitle>
            <ProductCardSubtitle>{subtitle}</ProductCardSubtitle>
            {children}
        </ProductCardStyled>
    );
};

import React from 'react'
import ContentLoader from 'react-content-loader'

const StatsLoader = (props) => {
    return (

        <ContentLoader
            speed={2}
            viewBox="0 0 300 140"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="76" y="27" rx="1" ry="1" width="171" height="19" /> 
            <circle cx="273" cy="36" r="14" /> 
            <rect x="17" y="87" rx="2" ry="2" width="133" height="43" /> 
            <rect x="16" y="15" rx="0" ry="0" width="41" height="42" />
        </ContentLoader>
    )
}

export default StatsLoader
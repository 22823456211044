import React from 'react'
import ContentLoader from 'react-content-loader'

const GraphLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            viewBox="0 0 500 200"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
            >
            <circle cx="32" cy="49" r="4" /> 
            <rect x="37" y="47" rx="3" ry="3" width="43" height="5" /> 
            <circle cx="92" cy="49" r="4" /> 
            <rect x="97" y="47" rx="3" ry="3" width="43" height="5" /> 
            <circle cx="159" cy="49" r="4" /> 
            <rect x="164" y="47" rx="3" ry="3" width="43" height="5" /> 
            <circle cx="224" cy="49" r="4" /> 
            <rect x="229" y="47" rx="3" ry="3" width="43" height="5" /> 
            <path d="M 189 134.5 h 19 v 35 h -19 z M 214 112.5 h 19 v 57 h -19 z M 241 131.5 h 19 v 38 h -19 z M 266 121.5 h 19 v 48 h -19 z M 292 88.5 h 19 v 81 h -19 z" /> 
            <rect x="25" y="20" rx="0" ry="0" width="81" height="15" />
        </ContentLoader>
    )
}

export default GraphLoader
import { SvgIcon } from '../../../svg-icon/svg-icon';
import { Container, Main, ReportBtn, TabBtn, TabsSwitcher, Top } from './product-details-styled';
import { useState } from 'react';
import { Attributes } from './components/attributes/attributes';
import { Reviews } from './components/reviews/reviews';
import { Reports } from './components/reports/reports';

const Tab = {
  attr: 'attr',
  reviews: 'reviews',
  report: 'report'
};

export const ProductDetails = ({
  attributesTitle = 'Property Attributes',
  reviewsTitle = 'Reviews',
  reviews = true,
  product
}) => {
  const [currentTab, setCurrentTab] = useState(Tab.attr);
  const attributes = product?.data?.attributes;

  return (
    <Container>
      <Top>
        <TabsSwitcher>
          <TabBtn
            isActive={currentTab === Tab.attr}
            onClick={() => {
              setCurrentTab(Tab.attr);
            }}
          >
            {attributesTitle}
          </TabBtn>
          {reviews && (
            <TabBtn
              isActive={currentTab === Tab.reviews}
              onClick={() => {
                setCurrentTab(Tab.reviews);
              }}
            >
              {reviewsTitle}
            </TabBtn>
          )}
        </TabsSwitcher>
        <ReportBtn
          isActive={currentTab === Tab.report}
          onClick={() => {
            setCurrentTab(Tab.report);
          }}
        >
          <SvgIcon name={'alert'} />
          Report
        </ReportBtn>
      </Top>
      <Main>
        {currentTab === Tab.attr && <Attributes attributes={attributes}/>}
        {currentTab === Tab.reviews && <Reviews product={product} />}
        {currentTab === Tab.report && <Reports />}
      </Main>
    </Container>
  );
};

import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import React, { useState, useEffect } from 'react';
import filter from '../../../../assets/filter.svg';
import search from '../../../../assets/search.svg';
import Checkbox from '../../../../components/checkbox/checkbox';
import Table from '../../../../components/table/table';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import {
  AddItemsBtn,
  CheckBlock,
  CheckBox,
  Container,
  DropdownsWrapper,
  FilterButton,
  InputWrapper,
  LeftSide,
  MobileSearch,
  Navigation,
  RightSide,
  SearchButton,
  SearchInput,
  SellItemBtn,
  Title
} from './all-sell-items-styled';
import { columnsData } from './mock';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';
import { getBusinessProducts } from '../../../../redux/slices/business/businessSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Description } from 'components/product-info/product-info-styled';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';

const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'My Products' },
  { link: '#', name: 'All Sell Items' }
];

const DropdownItems = ['Service Type', 'Status', 'Category'];

const AllSellItemsDashboard = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const dispatch = useDispatch();
  const { business } = useSelector((state) => state.business);
  const [businessProducts, setBusinessProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const params = {
      bID: 80 //business.bID,
    };
    dispatch(getBusinessProducts(params)).then((response) => {
      setBusinessProducts(response.payload);
      setFilteredProducts(response.payload);
    });
  }, [dispatch]);

  const handleQuickSearch = (value) => {
    setQuickSearch(value);
    const filtered = businessProducts.filter((product) =>
      product.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <>
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Products</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => handleQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              {DropdownItems.map((data, index) => {
                return <Dropdown name={data} key={index} withBorders />;
              })}
            </DropdownsWrapper>
            <CheckBox>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={true}
                  label={'Deals Of The Day'}
                />
              </CheckBlock>
              <CheckBlock>
                <Checkbox
                  wrapperClassName={'checkbox'}
                  checked={false}
                  label={'Featured Product'}
                />
              </CheckBlock>
            </CheckBox>
          </LeftSide>
          <RightSide>
            <Link to={getRouteById('addProductSingle')}>
              <AddItemsBtn>
                <GlobalButton type='secondary'>
                  <SvgIcon name='plus-second' />
                  <p>Add items</p>
                </GlobalButton>
              </AddItemsBtn>
            </Link>
          </RightSide>
        </Navigation>
        {businessProducts?.length > 0 || filteredProducts?.length > 0 ? (
          <Table columnsData={columnsData} columnsRows={filteredProducts.length > 0 ? filteredProducts : businessProducts} hasCheckbox hasMenu />
        ) : (
          <Description>You have no products, click 'Add items' to add your products</Description>
        )}
      </Container>
      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default AllSellItemsDashboard;
import React from 'react';
import { RateStars } from '../rate-stars/rate-stars';
import './p-card-slick.scss';

import {
    Badge,
    Buttons,
    BuyBtn,
    Content,
    Main,
    MainPrice,
    OldPrice,
    OnlyViewCardStyled,
    OnlyViewWrapper,
    Price,
    ContactsBox,
    SubTitle,
    MainBox,
    RateStarsDesktop,
    Title,
    ZWLPrice,
    Container,
    ContentTradesView
} from './p-card-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { ProductCardSlider } from '../product-card-slider/product-card-slider';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';

export const PCardOnlyViewView = ({ product, handleFavBtnClick, isFav, currentViewType }) => {

    const productUrl = getRouteById('product', { slug: product?.slug });
    return (
        <Container currentViewType={currentViewType}>
            <ProductCardSlider
                img={product.image ? product.image.url : product.img}
                title={product.title}
                isFav={isFav}
                handleFavBtnClick={handleFavBtnClick}
                currentViewType={currentViewType}
            />
            <Main currentViewType={currentViewType}>
                <MainBox>
                    <RateStarsDesktop>
                        {product.rating && (
                            <RateStars
                                rate={product.rating}
                                reviews={product.totalReviews}
                                color={'#388638'}
                            />
                        )}
                    </RateStarsDesktop>
                    <ContactsBox>
                        <a href='#'>
                            <SvgIcon name={'whatsup'} />
                        </a>
                        <a href='#'>
                            <SvgIcon name={'email'} />
                        </a>
                        <a href='#'>
                            <SvgIcon name={'phone'} />
                        </a>
                    </ContactsBox>
                </MainBox>
                <Link to={productUrl}>
                <Title currentViewType={currentViewType}>{product.title}</Title>
                    {/* {product.description && <SubTitle>{product.description}</SubTitle>}*/}
                    {product.location && (
                        <SubTitle currentViewType={currentViewType}>{product.location}</SubTitle>
                    )}
                    </Link>
                <ContentTradesView currentViewType={currentViewType}>
                    <Price currentViewType={currentViewType}>
                        {/* <OldPrice currentViewType={currentViewType}>{product.old_price}</OldPrice> */}
                        <MainPrice currentViewType={currentViewType}>
                            USD
                            {product.price.normalPrice
                                ? product.price.normalPrice.amount
                                : product.price}
                        </MainPrice>
                        {/* <ZWLPrice currentViewType={currentViewType}>ZWL 200</ZWLPrice> */}
                    </Price>
                    <Buttons>
                        <OnlyViewWrapper>
                            <Link to={`/product/${product.slug}`}>
                                <BuyBtn>
                                    <SvgIcon name={'eye'} />
                                </BuyBtn>
                            </Link>
                        </OnlyViewWrapper>
                    </Buttons>
                </ContentTradesView>
                {product.badge && <Badge>{product.badge}</Badge>}
            </Main>
        </Container>
    );
};

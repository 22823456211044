import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { Container, Union } from './categories-general-styled';
import Main from './components/main/main';
import { GlobalFilter } from '../../components/global-filter/global-filter';
import { BreadcrumbsContainer } from '../categories-real-estate/categories-real-estate-styled';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCategoryAndProducts } from '../../redux/slices/category/categorySlice';
import React, {useState, useEffect} from 'react';
import { ROUTES } from '../../routes/routes';
import { getCategories } from '../../redux/slices/category/categorySlice';


const CategoriesGeneral = () => {
  const [isMobileFilterVisible, setMobileFilterVisible] = useState(false);
  const { category } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const { slug, page } = useParams();

     useEffect(() => {
       const params = {
           slug: slug,
           page: page != undefined ? page : 1,
           per_page: 12
      };
      dispatch(getCategoryAndProducts(params));
      dispatch(getCategories());
    },[slug, page]);

    const breadcrumbs = [
      { link: ROUTES.HOME.path, name: 'Home' },
      { link: ROUTES.CATEGORY.path.replace(':slug', slug), name: category?.name },
    ];

  return (
    <>
      <Header />
      <BreadcrumbsContainer>
        <Breadcrumbs links={breadcrumbs} />
      </BreadcrumbsContainer>
      <Container>
        <GlobalFilter
          catoryTitle={category?.name}
          categories={category?.subCategories}
          isMobileFilterVisible={isMobileFilterVisible}
          onCloseFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
        <Main
          onFilterBtnClick={() => {
            setMobileFilterVisible(false);
          }}
        />
      </Container>
    </>
  );
};

export default CategoriesGeneral;

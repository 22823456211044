import {
    CheckboxWrapper,
    Section,
    SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import { useState } from 'react';
import { useEffect } from 'react';
import Checkbox from 'components/checkbox/checkbox';

export const ProductInventory = ({
    updateProductDetails,
    productStock,
    setProductStock,
    backorders,
    setBackOrders
}) => {
    // const [stock, setStock] = useState('');

    const handleChange = (e) => {
        const value = Number(e.target.value);
        setProductStock(value);
        updateProductDetails('productStock', value);
    };

    const handleBackOrders = (e) => {
        const isChecked = e.target.checked;
        setBackOrders(isChecked);
        updateProductDetails('backOrder', isChecked);
    };

    useEffect(() => {
        // console.log('Updated productStock:', productStock);
    }, []);

    return (
        <Section>
            <SectionTitle>Inventory</SectionTitle>
            <InputGroup label={'stock quantity'}>
                <GlobalInput
                    placeholder={'Write the stock quantity'}
                    value={productStock}
                    onChange={handleChange}
                    type={'number'}
                />
            </InputGroup>
            <InputGroup>
                <CheckboxWrapper>
                    <Checkbox
                        id='backorders-checkbox'
                        isChecked={backorders}
                        onChange={handleBackOrders}
                        label={'Allow back-orders when stock is depleted'}
                    />
                    {/* <GlobalCheckbox
                        id='backorders-checkbox'
                        isChecked={backorders}
                        onChange={handleBackOrders}
                    >
                        Allow back-orders when stock is depleted
                    </GlobalCheckbox> */}
                </CheckboxWrapper>
            </InputGroup>
        </Section>
    );
};

import styled, { css } from 'styled-components';
import { RoundBtn } from '../../assets/style-helpers/style-helpers';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const MainSlider = styled.div`
    height: 530px;
    margin-bottom: 12px;
    position: relative;
    @media ${device.laptop} {
        height: 364px;
    }
    .swiper {
        height: 100%;
    }

    .swiper-slide {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
`;

export const ThumbsContainer = styled.div`
    .swiper {
        height: 100%;
    }

    .swiper-slide {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
`;

export const MainSliderSlide = styled.div`
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
    }
`;

export const ThumbSlierSlide = styled.div`
    height: 118px;
    border-radius: 8px;
    overflow: hidden;
    @media ${device.laptop} {
        height: 80px;
    }
`;

export const PrevButton = styled(RoundBtn)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    border: 1px solid var(--divider);
    z-index: 2;
    left: 16px;
`;

export const NextButton = styled(RoundBtn)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    border: 1px solid var(--divider);
    z-index: 2;
    right: 16px;
    svg {
        transform: rotate(180deg);
    }
`;



export const FavBtn = styled(RoundBtn)`
    background: #ffffff;
    border: 1px solid var(--divider);
    position: absolute;
    top: 20px;
    right: 16px;
    z-index: 2;
    ${(props) =>
        props.isFav &&
        css`
            > svg {
                fill: #000000;
            }
        `}
`;

import styled, { css } from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { buttonReset, LatoFont, RoundBtn } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div`
    position: relative;
    border-radius: 12px;
    background: #fff;
    border: 1px solid var(--main-gray-2);
    min-width: 0;
    //max-width: 295px;
    //width: 295px;
    width: 100%;
    transition: ease 0.5s;
    overflow: hidden;
    &:hover {
        box-shadow: 0 4px 40px rgba(78, 94, 108, 0.2);
        border: 1px solid transparent;
    }
    @media ${device.tablet} {
        max-width: 345px;
        width: 345px;
    }
    @media ${device.mobile} {
        max-width: max-content !important;
        width: 100% !important;
    }
`;

export const Main = styled.div`
    padding: 20px 12px;
`;

export const Title = styled.p`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #29263a;
    margin-bottom: 6px;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      color: #29263a;
                      font-family: Poppins, sans-serif;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 19px; /* 146.154% */
                      margin-bottom: 3.5px;
                      height: unset;
                  `
                : css``}
    }
`;

export const Location = styled.p`
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px; /* 200% */
    color: #acadb9;
    margin-bottom: 8px;
    img {
        margin-right: 8.66px;
    }
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      line-height: 16.294px; /* 135.78% */
                      margin-bottom: 3.5px;
                  `
                : css``}
    }
`;

export const RateStarsDesktop = styled.div`
    display: block;
    @media ${device.tablet} {
        display: none;
    }
`;

export const MainBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
`;

export const ContactsBox = styled.div`
    display: flex;
    align-items: center;

    a {
        color: var(--main-green);
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a + a {
        margin-left: 8px;
    }

    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

export const Tags = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 4px;
    flex-wrap: wrap;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 10px;
    background: #f9fcf9;
    border: 1px solid #bdd7bd;
    border-radius: 20px;
    ${LatoFont};
    font-weight: 700;
    font-size: 12px;
    flex-shrink: 0;
    img {
        margin-right: 6px;
    }
`;

export const PriceInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: block;
                      color: #29263a;
                      font-family: Poppins, sans-serif;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 16.294px; /* 116.383% */
                      margin-bottom: unset;
                  `
                : css``}
    }
`;

export const Price = styled.p`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #29263a;
    margin-right: 6px;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      color: var(--dark, #29263a);
                      font-family: Poppins, sans-serif;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 16.294px; /* 116.383% */
                  `
                : css``}
    }
`;

export const Bids = styled.p`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #acadb9;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

export const SecondPrice = styled.p`
    display: none;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: block !important;
                      color: #acadb9;
                      font-family: Lato, sans-serif;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 16.294px; /* 135.78% */
                      text-decoration-line: line-through;
                  `
                : css``}
    }
`;

export const ZWLPrice = styled.p`
    display: none;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: block;
                      color: #acadb9;
                      font-family: Poppins, sans-serif;
                      font-size: 9px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 16.294px; /* 181.04% */
                  `
                : css``}
    }
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
`;

export const Logo = styled.img`
    width: 42px;
    height: 42px;
    margin-right: 10px;
`;

export const Text = styled.p`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #5a5670;
`;

export const Info = styled.div`
    position: absolute;
    width: calc(100% - 36px);
    bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ContactInfo = styled.div`
    display: flex;
    align-items: center;
    > img {
        margin-right: 19px;
        cursor: pointer;
    }
    > :last-child {
        margin-right: unset;
    }
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      position: absolute;
                      bottom: 12px;
                      right: 12px;
                  `
                : css``}
    }
`;

export const EyeButton = styled.div`
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    background-color: var(--main-green);
    justify-content: center;
    border-radius: 50%;
    > svg {
        filter: brightness(100);
    }
    cursor: pointer;
`;

export const Button = styled.button`
    padding: ${(props) => (props.isSpareParts ? '8px' : '8px 16px')};
    background: #f76d21;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    border: none;
    line-height: 24px;
    margin-right: 8px;
    white-space: nowrap;
    cursor: pointer;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

// landscape style

export const LandScapeContainer = styled.div`
    display: flex;
    border-radius: 12px;
    background: #fff;
    border: 1px solid var(--main-gray-2);
    min-width: 0;
    width: 100%;
    transition: ease 0.5s;
    overflow: hidden;
    position: relative;
    &:hover {
        box-shadow: 0 4px 40px rgba(78, 94, 108, 0.2);
        border: 1px solid transparent;
    }
    > :first-child {
        min-width: 283px;
        max-width: 283px;
        height: unset;
    }
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      > :first-child {
                          min-width: unset;
                          max-width: 112px;
                          height: unset;
                      }
                  `
                : css``}
    }
`;

export const LandScapeMain = styled.div`
    width: 100%;
    display: block;
    padding: 18px 22px;
    @media ${device.mobile} {
        padding: 12px 12px 12px 10px;
    }
`;

export const LandScapeInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const Name = styled.p`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #29263a;
    margin-bottom: 4px;
`;

export const Role = styled.p`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    color: #3664f6;
`;

export const UserLogo = styled.img`
    max-width: 42px;
    margin-right: 6px;
`;

export const Credentials = styled.div`
    display: block;
`;

export const LandScapeDescription = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

export const FavBtn = styled(RoundBtn)`
    display: none;
    ${buttonReset};
    width: 44px;
    height: 44px;
    border: 1px solid var(--main-gray);
    background: var(--main-white);
    margin-right: 8px;
    @media ${device.mobile} {
        ${(props) =>
            props.landScape
                ? css`
                      display: flex;
                  `
                : css``}
    }
`;

import { Link } from 'react-router-dom';
import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Address,
  BottomMenu,
  BtnsWrapper,
  Container,
  Content,
  Description,
  Divider,
  ImgWrapper,
  Logo,
  MainBox,
  ContactsBox,
  Text,
  Title,
  RateStarsDesktop,
  ViewBtn
} from './store-card-styled';
import { get } from 'lodash';
import { getRouteById } from 'routes/routesHelper';

export const StoreCard = ({
  store,
  isListView = false,
  isMobileListDefault = true,
  isAddressHidden = false
}) => {
  return (
    <Container isListView={isListView} isMobileListDefault={isMobileListDefault}>
      <Link to={getRouteById('storefront', { bSlug: store.link })}>
      <ImgWrapper>
        <img src={store.coverImage} alt={store.name} />
        <Logo>
          <img src={store.logo} alt={store.name} />
        </Logo>
      </ImgWrapper>
      </Link>
      <Content>
        <MainBox></MainBox>
        <Title>
          <Link to={getRouteById('storefront', { bSlug: store.link })}>{store.name}</Link>
          </Title>
        <Description>{store.category}</Description>
        {isListView && <Text>{store.description}</Text>}
        {isAddressHidden && <Divider />}
        {!isAddressHidden && <Address>{store.address}</Address>}
        <BottomMenu>
          <RateStars rate={store.rate} reviews={store.reviews} />
          <BtnsWrapper>
            {isListView && (
              <ContactsBox>
                <a href='#'>
                  <SvgIcon name={'whatsup'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'email'} />
                </a>
                <a href='#'>
                  <SvgIcon name={'phone'} />
                </a>
              </ContactsBox>
            )}
            <ViewBtn>
              <Link to={getRouteById('storefront', { bSlug: store.link })} style={{ color: 'white' }}>
                <SvgIcon name={'eye'} />
              </Link>
            </ViewBtn>
          </BtnsWrapper>
        </BottomMenu>
      </Content>
    </Container>
  );
};

import { RateStars } from '../rate-stars/rate-stars';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  Badge,
  Buttons,
  BuyBtn,
  Container,
  Content,
  Control,
  FavBtn4,
  ImgWrapper,
  Label,
  LabelNotAvailable,
  Main,
  MainPrice,
  MainBox,
  OldPrice,
  ContactsBox,
  Price,
  RateStarsDesktop,
  RateStarsMobile,
  SubTitle,
  Title,
  ZWLPrice,
  TitleTradesView
} from './p-card-styled';
import React from 'react';
import { ApproveSeller } from '../approve-seller/approve-seller';
import { viewType } from '../view-selection/view-selection';
import { FavBtn } from '../categories-card/categories-card-styled';
import { Link } from 'react-router-dom';


export const PCardClothingView = ({ product, handleFavBtnClick, isFav, currentViewType }) => {
  return (
    <Container currentViewType={currentViewType}>
      <ImgWrapper currentViewType={currentViewType}>
        <ApproveSeller />
      
          <img src={product.image} alt={product.title} />
        
        {product.status ? (
          <Label>Delivery Available</Label>
        ) : (
          <LabelNotAvailable>Delivery not available</LabelNotAvailable>
        )}
        <FavBtn4 onClick={handleFavBtnClick} isFav={isFav}>
          <SvgIcon name={'heart-2'} />
        </FavBtn4>
      </ImgWrapper>
      <Main currentViewType={currentViewType}>
        <MainBox>
          <RateStarsDesktop>
            {product.rate && (
              <RateStars rate={product.rate} reviews={product.totalReviews} color={'#388638'} />
            )}
          </RateStarsDesktop>
          <ContactsBox>
            <a href='#'>
              <SvgIcon name={'whatsup'} />
            </a>
            <a href='#'>
              <SvgIcon name={'email'} />
            </a>
            <a href='#'>
              <SvgIcon name={'phone'} />
            </a>
          </ContactsBox>
        </MainBox>
        <TitleTradesView subtitle={product.subtitle}>{product.title}</TitleTradesView>
        {product.subtitle && <SubTitle>{product.subtitle}</SubTitle>}
        <Content>
          <Price currentViewType={currentViewType}>
            {product.normalPrice && <OldPrice>{product.normalPrice.currency} {product.normalPrice.amount}</OldPrice>}
            <MainPrice>${product.normalPrice.amount}</MainPrice>
            {product.sellingPrice && <ZWLPrice>{product.sellingPrice.currency} {product.sellingPrice.amount}</ZWLPrice>}
          </Price>
          <Control>
            <RateStarsMobile>
              {product.rating && (
                <RateStars rate={product.rating} reviews={product.totalReviews} color={'#388638'} />
              )}
            </RateStarsMobile>
            <Buttons>
              {currentViewType === viewType.list && (
                <FavBtn landScape={currentViewType === viewType.list}>
                  <SvgIcon name={'heart-2'} />
                </FavBtn>
              )}
              <BuyBtn>
                <SvgIcon name='cart' />
              </BuyBtn>
            </Buttons>
          </Control>
        </Content>
        {product.badge && <Badge>{product.badge}</Badge>}
      </Main>
    </Container>
  );
};

import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';

import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as KeyIcon } from './icons/key.svg';
import { ReactComponent as ServicesIcon } from './icons/services.svg';
import { ReactComponent as TicketIcon } from './icons/ticket.svg';

import { Container, Title, Subtitle, CardsWrapper } from './add-new-product-styled';
import { DashboardItemCard } from '../../components/dashboard-item-card/dashboard-item-card';
import { getRouteById } from 'routes/routesHelper';
import { Link } from 'react-router-dom';

const breadcrumbs = [
    { link: '#', name: 'Sell Items' },
    { link: '#', name: 'Add New Product' }
];

const productCategories = [
    {
        title: 'General Product',
        subtitle: 'General Merchandise',
        icon: <CartIcon />,
        link: getRouteById('addProductSingle')
    }
    // {
    //   title: 'Services',
    //   subtitle: 'Trades & Services',
    //   icon: <ServicesIcon />
    // },
    // {
    //   title: 'Rentals',
    //   subtitle: 'Houses, Rooms, Offices & More',
    //   icon: <KeyIcon />
    // },
    // {
    //   title: 'Bookings',
    //   subtitle: 'Hotels, BnB, Tickets & More',
    //   icon: <TicketIcon />
    // }
];

export const AddNewProduct = () => {
    return (
        <Container>
            <Breadcrumbs links={breadcrumbs} />
            <Title>Add New Product</Title>
            <Subtitle>Select the type of product you would like to upload</Subtitle>
            <CardsWrapper>
                {productCategories.map((item, i) => {
                    return (
                        <Link to={item.link}>
                            <DashboardItemCard
                                title={item.title}
                                icon={item.icon}
                                subtitle={item.subtitle}
                                key={i}
                            />
                        </Link>
                    );
                })}
            </CardsWrapper>
        </Container>
    );
};

import React from 'react';
import ContentLoader from 'react-content-loader';
import { IconWrapper, Inner, Item, Title } from './featured-categories-cards-styled';
import { SvgIcon } from '../svg-icon/svg-icon';

export const PopularCategoriesLoader = () => {
    return (
        <Inner>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
            <Item>
                <ContentLoader
                    viewBox='0 0 300 90' // Reduced height to fit the smaller layout
                    height={90}
                    width={300}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                >
                    {/* Icon (circle) */}
                    <rect x='0' y='10' rx='30' ry='30' width='60' height='50' />{' '}
                    {/* Smaller height for icon */}
                    {/* Title (text below the icon) */}
                    <rect x='0' y='65' rx='1' ry='1' width='280' height='15' />{' '}
                    {/* Reduced height for title */}
                </ContentLoader>
            </Item>
        </Inner>
    );
};

import React from 'react'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { update } from 'lodash';
import localStorage from 'redux-persist/es/storage';

const businessURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_BUSINESS_API_URL;
const businessesURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_BUSINESSES_API_URL;
const apiBase = process.env.REACT_APP_API_BASE_ENDPOINT;


const initialState = {
    business: null,
    status: 'idle',
    error: null,
    businessProducts: [],
    activeBusiness: null,
};

export const getBusiness = createAsyncThunk(
    'business/getBusiness',
    async (params, { rejectWithValue }) => {
        const { bSlug } = params
        try {
            const response = await axios.post(`${businessURL}?bSlug=${bSlug}`,
                {
                    bSlug
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

export const getBusinessProducts = createAsyncThunk(
    'business/getBusinessProducts',
    async (params, { rejectWithValue }) => {
        const { bID } = params;

        try {
            const response = await axios.post(`${apiBase}/products?bID=${bID}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response.data);

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
)

export const getBusinesses = createAsyncThunk(
    'business/getBusinesses',
    async (params, { rejectWithValue }) => {
        const { limit = -1 } = params
        try {
            const response = await axios.get(`${businessesURL}?limit=${limit}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response?.data?.data?.businesses;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

export const getPromotedBusinesses = createAsyncThunk(
    'business/getPromotedBusinesses',
    async (params, { rejectWithValue }) => {
        const { limit } = params
        try {
            const response = await axios.get(`${businessesURL}/promoted/?limit=${limit}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response?.data?.data?.businesses;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
);


const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        setActiveBusiness: (state, action) => {
            state.activeBusiness = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                    state.error = null; // Clear any previous errors
                }
            )
            // Handle fulfilled states for both login and loginWithEmail
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    const business = action.payload;
                    state.status = 'success';
                    state.business = business?.data;
                    state.error = null;
                }
            )

            // Handle rejected states for both login and loginWithEmail
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'rejected';
                    state.error = action.payload;
                }
            )

            .addMatcher(
                (action) => action.type === getBusinessProducts.fulfilled,
                (state, action) => {
                    state.status = 'success';
                    state.businessProducts = action.payload;
                }
            )

            .addMatcher(
                (action) => action.type === getBusinessProducts.rejected,
                (state, action) => {
                    state.status = 'rejected';
                    state.error = action.payload;
                }
            )
    },
}
);

export const { setActiveBusiness } = businessSlice.actions
export default businessSlice.reducer;
import React from 'react';
import ContentLoader from 'react-content-loader';

// Skeleton loader to match ProductInfo content layout
const ProductInfoLoader = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 500 600"  // Adjust based on the size of your content
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Shop Name */}
    <rect x="20" y="20" rx="4" ry="4" width="250" height="20" />

    {/* Product Title */}
    <rect x="20" y="50" rx="4" ry="4" width="300" height="25" />

    {/* Rating */}
    <rect x="20" y="90" rx="4" ry="4" width="100" height="20" />  {/* Rating stars */}
    <rect x="130" y="90" rx="4" ry="4" width="150" height="20" />  {/* Reviews */}
    <rect x="290" y="90" rx="4" ry="4" width="100" height="20" />  {/* Orders */}

    {/* Meta Info */}
    <rect x="20" y="130" rx="4" ry="4" width="200" height="20" />  {/* Item Published */}
    <rect x="20" y="160" rx="4" ry="4" width="150" height="20" />  {/* Product ID */}

    {/* Price */}
    <rect x="20" y="190" rx="4" ry="4" width="150" height="25" />  {/* Price */}

    {/* Description */}
    <rect x="20" y="230" rx="4" ry="4" width="400" height="40" />  {/* Description */}

    {/* Product Condition */}
    <rect x="20" y="280" rx="4" ry="4" width="200" height="20" />  {/* Product Condition */}

    {/* Share Button */}
    <rect x="20" y="320" rx="4" ry="4" width="100" height="20" />  {/* Share Button */}

    {/* Add to Cart & Buy Now Buttons */}
    <rect x="20" y="360" rx="5" ry="5" width="150" height="40" />  {/* Add to Cart */}
    <rect x="180" y="360" rx="5" ry="5" width="150" height="40" />  {/* Buy Now */}

    {/* Contact Seller Button */}
    <rect x="20" y="420" rx="5" ry="5" width="150" height="40" />  {/* Contact Seller */}
  </ContentLoader>
);

export default ProductInfoLoader;

import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { Tooltip } from 'components/tooltip/tooltip';
import { GlobalButton } from 'components/global-button/global-button';
import { ReactComponent as CircleCheck } from './check-circle.svg';
import {
    ButtonsWrapper,
    CheckboxContent,
    Content,
    Price,
    PromoteItem,
    SelectWrapper,
    Subtitle,
    Title,
    TotalWrapper
} from './promote-popup-styled';
import {
    customDropdownIndicatorSmall,
    GlobalSelect,
    selectCustomStyleSmall
} from '../../../../components/global-select/global-select';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';

const selectOptions = [
    { label: 'test', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];

export const ErrorPopup = ({ onClose, isVisible, onPayBtnClick }) => {
    return (
        <ModalWrapper onClose={onClose} isVisible={isVisible} alignCenter>
            <Content
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '100%',
                    gap: '20px'
                }}
            >
                <Title>Failed to save Product</Title>
                <PromoteItem style={{ border: 'none' }}>
                    <svg
                        stroke='#F76D21'
                        fill='#F76D21'
                        stroke-width='0'
                        version='1.1'
                        viewBox='0 0 16 16'
                        height='5em'
                        width='5em'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z'></path>
                        <path d='M10.5 4l-2.5 2.5-2.5-2.5-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 2.5-2.5 2.5 2.5 1.5-1.5-2.5-2.5 2.5-2.5z'></path>
                    </svg>
                </PromoteItem>
            </Content>
        </ModalWrapper>
    );
};

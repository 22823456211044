import {
    Block,
    ButtonInfo,
    MobileOpenPreviwBtnWrapper,
    SubmitWrapper
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { PromotePopup } from '../promote-popup/promote-popup';
import { useEffect, useState } from 'react';
import { AdditionalInformationPopup } from '../additional-information-popup/additional-information-popup';
import { useDispatch } from 'react-redux';
import { createProduct } from '../../../../redux/slices/product/productSlice';
import { SaveDraftPopup } from '../promote-popup/saveProductDraftPopup';
import { SuccessSavePopup } from '../promote-popup/successPopup';
import { ErrorPopup } from '../promote-popup/errorPopup';

export const ProductSubmitBtns = ({
    setIsSidebarHidden,
    isSidebarHidden,
    product,
    bID,
    updateProductDetails
}) => {
    const [isPromotePopupVisible, setIsPromotePopupVisible] = useState(false);
    const [isAdditionalInfoPopupVisible, setIsAdditionalInfoPopupVisible] = useState(false);
    const [isSaveDraftPopupVisible, setIsSaveDraftPopupVisible] = useState(false);
    const [isSaveSuccessPopupVisible, setIsSaveSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        title: '',
        price: '',
        images: '',
        quantity: ''
    });

    const dispatch = useDispatch();
    const validateProduct = () => {
        let uploadErrors = {};
        if (!product.title) {
            uploadErrors.title = 'Product name';
        } else if (!product.price) {
            uploadErrors.price = 'Product price';
        } else if (!product.images[0]) {
            uploadErrors.images = 'Select main image';
        } else if (!product.inventory.quantity) {
            uploadErrors.quantity = 'Enter stock quantity';
        }
        setErrorMessage(uploadErrors);

        return Object.keys(uploadErrors).length === 0;
    };
    const handleSaveButton = async () => {
        console.log(bID);

        setIsAdditionalInfoPopupVisible(false);
        setIsPromotePopupVisible(true);
        try {
            const productData = { ...product, bID };
            console.log(productData);
            const response = await dispatch(createProduct(productData));
            if (response.payload.message === 'success') {
                console.log('Product saved successfully:', response.payload.data);
                console.log(response);
                setIsPromotePopupVisible(false);
                setIsSaveSuccessPopupVisible(true);
            } else {
                console.log('Failed to save product:', response.payload);
                setIsPromotePopupVisible(false);
                setIsErrorPopupVisible(true);
            }
        } catch (error) {
            console.error('Error saving product:', error);
            setIsAdditionalInfoPopupVisible(false);
            setIsErrorPopupVisible(true);
        }
        // try {
        //     console.log('Creating product');
        //     const response = await dispatch(
        //         createProduct({ ...product, bID, post_status: 'publish' })
        //     );
        //     if (response.payload.status === 200) {
        //         setIsPromotePopupVisible(false);
        //         console.log('Product created');
        //         console.log(response);
        //         setIsPromotePopupVisible(false);
        //         setIsSaveSuccessPopupVisible(true);
        //     } else {
        //         console.log('Error creating product', response.error);
        //         setIsPromotePopupVisible(false);
        //         setIsErrorPopupVisible(true);
        //     }
        // } catch (err) {
        //     console.log(err);
        //     setIsAdditionalInfoPopupVisible(false);
        //     setIsErrorPopupVisible(true);
        // }
    };

    const handleSaveDraft = async () => {
        setIsSaveDraftPopupVisible(true);

        try {
            console.log('Creating product');
            const response = await dispatch(
                createProduct({ ...product, bID, post_status: 'draft' })
            );
            if (response.payload.status === 200) {
                console.log('Product created');
                setIsSaveDraftPopupVisible(false);
                setIsSaveSuccessPopupVisible(true);
            } else {
                console.log('Error creating product', response.error);
                setIsSaveDraftPopupVisible(false);
                setIsErrorPopupVisible(true);
            }
        } catch (err) {
            console.log(err);
            setIsSaveDraftPopupVisible(false);
            setIsErrorPopupVisible(true);
        }
    };

    const saveProduct = () => {
        console.log(product);
        if (validateProduct()) {
            setIsAdditionalInfoPopupVisible(true);
        } else {
            console.log('Please enter details');
        }
    };

    return (
        <>
            <h4 style={{ color: 'red' }}>
                {errorMessage.title && <span>Enter product title</span>}
                {errorMessage.images && <span>Select the main image</span>}
                {errorMessage.price && <span>Enter product price</span>}
                {errorMessage.quantity && <span>Enter stock quantity</span>}
            </h4>
            <SubmitWrapper>
                <PromotePopup
                    isVisible={isPromotePopupVisible}
                    onClose={() => {
                        setIsPromotePopupVisible(false);
                    }}
                    onPayBtnClick={() => {
                        setIsPromotePopupVisible(false);
                    }}
                />
                <SaveDraftPopup
                    isVisible={isSaveDraftPopupVisible}
                    onClose={() => {
                        setIsSaveDraftPopupVisible(false);
                    }}
                />
                <SuccessSavePopup
                    isVisible={isSaveSuccessPopupVisible}
                    onClose={() => {
                        setIsSaveSuccessPopupVisible(false);
                    }}
                />
                <ErrorPopup
                    isVisible={isErrorPopupVisible}
                    onClose={() => {
                        setIsErrorPopupVisible(false);
                    }}
                />
                <AdditionalInformationPopup
                    isVisible={isAdditionalInfoPopupVisible}
                    onClose={() => setIsAdditionalInfoPopupVisible(false)}
                    onSaveProduct={handleSaveButton}
                    updateProductDetails={updateProductDetails}
                    product={product}
                />

                <Block>
                    <GlobalButton type={'primary'} onClick={saveProduct}>
                        <SvgIcon name={'plus'} />
                        Save product
                    </GlobalButton>
                </Block>
                <Block>
                    <GlobalButton type={'transparent'} onClick={handleSaveDraft}>
                        Save Draft
                    </GlobalButton>
                    <MobileOpenPreviwBtnWrapper>
                        <GlobalButton
                            type={'secondary'}
                            onClick={() => {
                                setIsSidebarHidden(!isSidebarHidden);
                            }}
                        >
                            <SvgIcon name={'eye'} />
                            Product Preview
                        </GlobalButton>
                    </MobileOpenPreviwBtnWrapper>
                </Block>
            </SubmitWrapper>
        </>
    );
};

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth/authSlice';
import productReducer from './slices/product/productSlice';
import businessReducer from './slices/business/businessSlice';
import ordersReducer from './slices/orders/ordersSlice';
import dashboardReducer from './slices/dashboard/dashboardSlice';
import productReviewsReducer from './slices/products/reviews/productReviewsSlice';
import categoryReducer from './slices/category/categorySlice';
import categoriesReducer from './slices/products/categoriesSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import businessSettingsReducer from './slices/businessSettingsSlice';

// Correctly combining reducers
const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  businessSettingsReducer: businessSettingsReducer,
  product: productReducer,
  reviews: productReviewsReducer,
  categories: categoryReducer,
  category: categoriesReducer,
  business: businessReducer,
  dashboard: dashboardReducer,
});


// product: productReducer,
// business: businessReducer,


// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Persisting the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuring the Redux store
const store = configureStore({
  reducer: persistedReducer, // The combined and persisted reducers go here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Creating the persistor for the store
const persistor = persistStore(store);

export { store, persistor };


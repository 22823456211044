import Header from '../../components/header/header';
import {
    BannerWrapper,
    CardsWithBanner,
    CardsWrapper,
    Container,
    FeaturesCategoriesCardsWrapper,
    PopularCategoriesAdSpace,
    Title,
    TitleWrapper,
    TagsWrapper
} from './popular-categories-styled';
import { Tags } from '../home/components/home-main/components/tags/tags';
import { FeaturesCategoriesCards } from '../../components/featured-categories-cards/features-categories-cards';
import { PopularCategoriesCards } from '../../components/featured-categories-cards/popular-categories-cards';
import { FoodAndGroceries } from '../home/components/food-and-groceries/food-and-groceries';
import { SectionWithSlider } from '../home/components/section-with-slider/section-with-slider';
import { AdvBanner } from '../../components/adv-banner/adv-banner';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { PCardSmall } from '../../components/p-card-small/p-card-small';
import { SectionHeader } from '../../components/section-header/section-header';
import temp1 from '../../assets/temp-img/temp1.png';
import temp2 from '../../assets/temp-img/temp2.png';
import temp3 from '../../assets/temp-img/temp3.png';
import { PopularCategoriesLoader } from '../../components/featured-categories-cards/PopularCategoriesLoader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularCategories } from '../../redux/slices/category/categorySlice';
import { useState } from 'react';
import { getProductsBySlug } from '../../redux/slices/product/productSlice';
import { getProductsByCategory } from '../../redux/slices/product/productSlice';
import { PopularSectionWithSlider } from 'pages/home/components/section-with-slider/popular-section-with-slider';
import { getRouteById } from 'routes/routesHelper';
import { ProductCardTypes } from 'components/p-card/p-card';

const breadcrumbs = [
    { link: '#', name: 'Home' },
    { link: '#', name: 'Popular Categories' }
];

export const PopularCategories = () => {
    const dispatch = useDispatch();

    const { popularCategories, status, error } = useSelector((state) => state.categories);

    useEffect(() => {
        dispatch(getPopularCategories());
    }, [dispatch]);

    const defaultSections = [
        {
            "title": "Real Estate",
            "slug": "real-estate"
        },
        {
           "title": "Trades & Services",
            "slug": "trades-services"
        },
        {
            "title": "Motor Vehicles",
            "slug": "real-estate"
        },
    ]

    const sections = popularCategories?.popularCategories?.length> 0 ? popularCategories?.popularCategories.map(category => ({
        slug: category.slug,
        title: category.title
    })): defaultSections;

    return (
        <>
            <Header />
            <Container>
                {/* <TagsWrapper> */}
                {/*  <Tags />*/}
                {/* </TagsWrapper> */}
                <Breadcrumbs links={breadcrumbs} />
                <Title>Popular Categories</Title>
                {status === 'loading' ? (
                    <>
                        <FeaturesCategoriesCardsWrapper>
                            <PopularCategoriesLoader />
                        </FeaturesCategoriesCardsWrapper>
                    </>
                ) : (
                    <>
                        <FeaturesCategoriesCardsWrapper>
                            <PopularCategoriesCards
                                popularCategories={popularCategories}
                            />
                        </FeaturesCategoriesCardsWrapper>
                    </>
                )}

                {/* TODO deals of the day section */}
                {/* <CardsWithBanner>
                    <TitleWrapper>
                        <SectionHeader hasClockCount title={'Deals of the Day'} />
                        <CardsWrapper>
                            {products.map((item, index) => (
                                <PCardSmall product={item} key={index} />
                            ))}
                        </CardsWrapper>
                    </TitleWrapper>
                    <BannerWrapper>
                        <AdvBanner
                            buttonText='30 % Save'
                            title={'Healthy Vegetables'}
                            subtitle='Limited Time Offer'
                            img={'https://picsum.photos/200/300'}
                        />
                    </BannerWrapper>
                </CardsWithBanner> */}
            </Container>
            <>
                <>
                    <PopularSectionWithSlider sectionTitle={'Real Estate'} />
                    <PopularSectionWithSlider sectionTitle={'Trades & Services'} slug={"trades-services"} cardType={ProductCardTypes.TRADES}/>
                   
                    <PopularSectionWithSlider sectionTitle={'Food & Groceries'} slug={"food-groceries"} cardType={ProductCardTypes.GROCERIES} />
                    <PopularSectionWithSlider sectionTitle={'Motor Vehicles'} />
                </>
            </>
        </>
    );
};

import GoogleMapReact from 'google-map-react';
import {
    Container,
    Content,
    StoresSectionStyled,
    Map,
    Main,
    FilterWrapper,
    CardsSection,
    SearchWrapper,
    TopFilterSection,
    SelectWrapper,
    ControlWrapper,
    CardsWrapper,
    MapBtn,
    TopFilterSectionWrapper,
    MobileFilterBtn,
    MobileSort,
    MobileSearch,
    MapTop
} from './stores-section-styled';
import InputWithLabel from 'components/input-with-label/input-with-label';
import { SelectWithLabel } from 'components/select-with-label/select-with-label';
import { Filter } from './components/filter/filter';
import { ViewSelection, viewType } from '../../../../components/view-selection/view-selection';
import { useEffect, useState } from 'react';
import { StoreCard } from '../../../../components/store-card/store-card';
import { storeFinderStoresMock } from '../../../store-finder/mock/mock';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { useNavigate } from 'react-router';
import { SortWithIcon } from '../../../../components/sort-with-icon/sort-with-icon';
import { SearchOnButtonClick } from '../../../../components/SearchOnButtonClick/SearchOnButtonClick';
import { ReactComponent as Pin } from './pin.svg';
import { LoadMore } from '../../../../components/load-more/load-more';
import { MobSort } from '../../../../components/mob-sort/mob-sort';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { useDispatch } from 'react-redux';
import { getBusinesses } from '../../../../redux/slices/business/businessSlice';
import { set } from 'lodash';

export const StoresSection = () => {
    const [currentViewType, setCurrentViewType] = useState(viewType.grid);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [stores, setStores] = useState(null);
    const [filteredStores, setFilteredStores] = useState(null);
    const [storesToShow, setStoresToShow] = useState(12);
    const [searchTerm, setSearchTerm] = useState('');

    const params = {
        limit: -1
    }
    
    useEffect(() => {
        dispatch(getBusinesses(params)).then((result) => {
        setStores(result?.payload);
        setFilteredStores(result?.payload);
        });
    }, [dispatch]);

    const handleSearchAndFilters = (stores) => {
        const filteredStores = stores
            .filter((stores) =>
                searchTerm ? stores.name.toLowerCase().includes(searchTerm.toLowerCase()) : true
            );    
        setFilteredStores(filteredStores);
    }

    const handleMore = () => {
        setStoresToShow(prev => prev + 8);
    }

  return (
    <StoresSectionStyled>
        <Content>
            <Container>
            <Main>
                <FilterWrapper>
                    <Filter stores={stores} setFilteredStores={setFilteredStores} />
                </FilterWrapper>
                <CardsSection>
                    <TopFilterSectionWrapper>
                        <TopFilterSection>
                            <MobileFilterBtn>
                                <GlobalButton height={'40'} type={'primary'}>
                                    <SvgIcon name={'filter'} />
                                    Filters
                                </GlobalButton>
                            </MobileFilterBtn>
                            <SearchWrapper>
                                <InputWithLabel height={38} name={'Find A Store'} onChange={
                                    e => {
                                        setSearchTerm(e.target.value)
                                        handleSearchAndFilters(stores);
                                    }
                                } value={searchTerm} />
                            </SearchWrapper>

                            <MobileSort>
                                <MobSearch stores={stores} searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearchAndFilters={handleSearchAndFilters} />
                                {/* <MobSort /> */}
                                {/* <GlobalButton
                                height={'40'}
                                type='secondary'
                                onClick={() => {
                                navigate('/store-finder');
                                }}
                                >
                                <Pin />
                                <span>Store Locator</span>
                                </GlobalButton> */}
                            </MobileSort>
                            {/* <SelectWrapper>
                                <SelectWithLabel label={'Sort by:'} />
                            </SelectWrapper> */}
                            {/* <ControlWrapper>
                                <ViewSelection
                                    isMobileHidden={false}
                                    currentViewType={currentViewType}
                                    setCurrentViewType={setCurrentViewType}
                                />
                                {/* <MapBtn>
                                    <SvgIcon name='marker-pin' />
                            </ControlWrapper> */}
                        </TopFilterSection>
                    </TopFilterSectionWrapper>

                    <CardsWrapper isListView={currentViewType === viewType.list}>
                        {filteredStores?.slice(0, storesToShow).map((store, index) => (
                            <StoreCard
                                isMobileListDefault={false}
                                key={index}
                                store={store}
                                isListView={currentViewType === viewType.list}
                            />
                        ))}
                    </CardsWrapper>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LoadMore onClick={handleMore}/>
                    </div>
                                
                </CardsSection>
            </Main>
                            </Container>
                            </Content>
                            {/* <Map>
                            <MapTop>
                            <GlobalButton
                            height={'40'}
                            type='secondary'
                            onClick={() => {
                                navigate('/store-finder');
                            }}
                            >
                            <Pin />
                            Store Locator
                            </GlobalButton>
                            </MapTop>
                            <GoogleMapReact
                            bootstrapURLKeys={{ key: '' }}
                            defaultZoom={9}
                            defaultCenter={{
                            lat: -19.23878101020672,
                            lng: 29.339569563132443
                            }}
                            />
                            </Map> */}
    </StoresSectionStyled>
  );
};

import styled, { css } from 'styled-components';
import check from 'assets/icons/check-solid.svg';

export const Text = styled.label`
    cursor: pointer;
    transition: all 0.3s;
    transform: translateZ(0);
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    & + & {
        margin-top: 8px;
    }

    &:hover {
        ${Text} {
            opacity: 0.5;
        }
    }
`;

export const Input = styled.input`
    display: block;
    visibility: hidden;
    opacity: 0;
`;

export const Label = styled.label`
    display: flex;
    width: 20px;
    height: 20px;
    border: 1px solid var(--main-gray-3);
    cursor: pointer;
    border-radius: 6px;
    margin-right: 8px;
    // align-items: center;
    position: relative;
    background: ${(props) => (props.isChecked ? '#E7F0E7' : 'var(--main-white)')};
    &::before {
        position: absolute;
        content: '';
        background: url(${check}) no-repeat center center;
        background-size: 7px 6px;
        width: 20px;
        height: 20px;
        transition: all 0.3s;
        opacity: 0;
    }
    ${(props) =>
        props.isChecked &&
        css`
            &::before {
                opacity: 1;
            }
        `};
`;

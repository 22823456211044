import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import avatar from 'assets/temp-img/feedback-avatar.png';
import {
    AvatarWrapper,
    Info,
    InfoCategory,
    InfoMain,
    InfoTitle
} from '../../../company-page/components/reviews/components/feedback-popup/feedback-popup-styled';
import {
    BookingForm,
    BookingFormContent,
    BookingFormLeft,
    BookingFormRight,
    BookingFormTitle,
    InputGroupWrapper,
    Notice,
    NoticeText,
    NoticeTitle,
    Slot,
    SlotDate,
    SlotSection,
    SlotsTitle,
    SlotsWrapper
} from 'pages/store-front-trades-services/components/apointment-booking-popup/apointment-booking-popup-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalSelect } from 'components/global-select/global-select';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import { TitleWrapper } from 'components/product-info/components/colors/colors-styled';
import { Title } from 'components/auth-pages-container/auth-pages-container-styled';
import {
    Line,
    PopupLine,
    BusinessesLine
} from 'components/google-facebook-auth/google-facebook-auth-styled';
import { Btns } from '../additional-information-popup/additional-information-popup-styled';
import {
    Account,
    AccountInfoMap,
    Logo,
    Text,
    BusinessesText,
    UserInfo,
    BusinessesLogo,
    BusinessesInfo,
    ActiveBusinessText,
    BusinessesTitle
} from 'pages/company-page/components/account-info/account-info-styled';
import { Address, Avatar, UserMain } from '../sidebar/sidebar-styled'
import { OtherBusinesses, BusinessesCheckMark, Content, ActiveBusiness, User } from './switch-business-popup-styled';
import { Container } from '../sidebar/sidebar-styled';
import { act, useEffect, useState } from 'react';
import { BusinessesSection } from 'components/global-filter/global-filter-styled';
import { CheckBox } from 'pages/dashboard/pages/all-sell-items/all-sell-items-styled';
import { hover } from '@testing-library/user-event/dist/hover';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';

import { jwtDecode } from 'jwt-decode';
import { setActiveBusiness } from '../../../../redux/slices/business/businessSlice';
import { useDispatch } from 'react-redux';
const selectOptions = [
    { label: 'Select service', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];
import { useSelector } from 'react-redux';
import { BoldText } from '../notifications-sidebar/notifications-sidebar-styled';
export const SwitchBusinessPopup = ({ isApointmentBookingVibisle, onClose }) => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const token = user.token;
    const [userBusinesses, setUserBusinesses] = useState([]);
    const { activeBusiness } = useSelector((state) => state.business);
    const [checkboxStates, setCheckboxStates] = useState({});
    const [selectedBusiness, setSelectedBusiness] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);
                console.log(decoded.data.userBusinesses);
                setUserBusinesses(decoded.data.userBusinesses || []);

                const initialStates = userBusinesses.reduce((acc, business) => {
                    acc[business.bID] = false;
                    return acc;
                }, {});
                setCheckboxStates(initialStates);
            } catch (error) {
                console.error('Failed to decode token:', error.message);
            }
        } else {
            console.warn('No token found in localStorage.');
        }
    }, [token, dispatch]);

    // const handleCheckboxChange = (id) => {
    //     setCheckboxStates((prevState) => {
    //         const newState = {
    //             ...prevState,
    //             [id]: !prevState[id]
    //         };

    //         if (!newState[id]) {
    //             setSelectedBusiness(null);
    //         } else {
    //             const selected = userBusinesses.find((business) => business.bID === id);
    //             console.log('Selected business:', selected);
    //             setSelectedBusiness(selected);
    //         }

    //         return newState;
    //     });
    // };
    const handleCheckboxChange = (id) => {
        setCheckboxStates((prevState) => {
            const newState = {};

            userBusinesses.forEach((business) => {
                newState[business.bID] = business.bID === id;
            });

            const selected = userBusinesses.find((business) => business.bID === id);
            setSelectedBusiness(selected);

            return newState;
        });
    };

    const handleSwitchButton = () => {
        dispatch(setActiveBusiness(selectedBusiness));
        onClose();
    };

    return (
        <ModalWrapper alignUnset isVisible={isApointmentBookingVibisle} onClose={onClose}>
            <Content>
                <TitleWrapper>
                    <Title>Switch to another business</Title>
                </TitleWrapper>
                <PopupLine />
                {/* <AccountInfoMap> */}
                    <ActiveBusiness>
                        <UserInfo>
                            <User>
                                <Avatar>
                                    <img
                                        src={activeBusiness ? activeBusiness.logo : ''}
                                        alt=''
                                        style={{
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px'
                                        }}
                                    />
                                </Avatar>
                                <UserMain>
                                    {activeBusiness ? activeBusiness.name : ''}
                                    <Address>
                                        {activeBusiness ? activeBusiness.category : ''}
                                    </Address>
                                </UserMain>
                                
                            </User>
                        </UserInfo>
                        <BusinessesCheckMark>
                                <svg
                                    stroke='#388638'
                                    fill='#388638'
                                    stroke-width='0'
                                    viewBox='0 0 1024 1024'
                                    height='3.5em'
                                    width='3.5em'
                                    xmlns='http://www.w3.org/2000/svg'
                                    style={{ marginTop: '0' }}
                                >
                                    <path d='M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z'></path>
                                    <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z'></path>
                                </svg>
                                {/* <p className='name'> Active</p> */}
                        </BusinessesCheckMark>
                    </ActiveBusiness>
                {/* </AccountInfoMap> */}

                <center>
                    <BusinessesText>
                        <BoldText style={{ color: '#29263A', display: 'block', marginTop: '20px', marginBottom:'15px', fontSize: '15px' }}>
                            Select the business you want to switch to
                        </BoldText>
                    </BusinessesText>
                    <BusinessesLine />
                </center>
                {userBusinesses
                    ? userBusinesses
                          .filter(
                              (business) => activeBusiness && business.bID !== activeBusiness.bID
                          )
                          .map((business) => {
                              return (
                                  <center>
                                      <BusinessesSection>
                                          <OtherBusinesses>
                                              <ActiveBusiness
                                                  style={{
                                                      cursor: 'pointer'
                                                  }}
                                              >
                                                <UserInfo>
                                                    <User>
                                                        
                                                        <Avatar>
                                                            <img
                                                                src={business.logo}
                                                                alt=''
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                                    borderRadius: '50%',
                                                                    width: '40px',
                                                                    height: '40px'
                                                                }}
                                                            />
                                                        </Avatar>
                                                        <UserMain>
                                                            {business.name}
                                                            <Address>
                                                                {business.category}
                                                            </Address>
                                                        </UserMain>
                                                        
                                                        <BusinessesCheckMark>
                                                            <GlobalCheckbox
                                                                isChecked={
                                                                    checkboxStates[
                                                                        business.bID
                                                                    ] || false
                                                                }
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        business.bID
                                                                    )
                                                                }
                                                                id={business.bID}
                                                            />
                                                        </BusinessesCheckMark>
                                                        {/* <BusinessesInfo>
                                                            <BusinessesLogo>
                                                            </BusinessesLogo>
                                                            <BusinessesText>
                                                                <BusinessesTitle>
                                                                    <p
                                                                        className='name'
                                                                        style={{
                                                                            color: '#29263A',
                                                                            textAlign: 'left'
                                                                        }}
                                                                    >
                                                                    </p>
                                                                    <p
                                                                        className='role'
                                                                        style={{
                                                                            color: '#29263A',
                                                                            textAlign: 'left'
                                                                        }}
                                                                    >
                                                                    </p>
                                                                </BusinessesTitle>
                                                            </BusinessesText>

                                                            <BusinessesCheckMark>
                                                                <GlobalCheckbox
                                                                    isChecked={
                                                                        checkboxStates[
                                                                            business.bID
                                                                        ] || false
                                                                    }
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            business.bID
                                                                        )
                                                                    }
                                                                    id={business.bID}
                                                                />
                                                            </BusinessesCheckMark>
                                                        </BusinessesInfo> */}
                                                    </User>
                                                </UserInfo>
                                              </ActiveBusiness>
                                          </OtherBusinesses>
                                          <BusinessesLine />
                                      </BusinessesSection>
                                  </center>
                              );
                          })
                    : null}
                {/* <center style={{ marginBottom: '100px' }}>
                    <Section>
                        <OtherBusinesses>
                            <ActiveBusiness>
                                <User>
                                    <BusinessesInfo>
                                        <BusinessesLogo>
                                            <img
                                                src=''
                                                alt=''
                                                style={{
                                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                                                }}
                                            />
                                        </BusinessesLogo>
                                        <BusinessesText>
                                            <p
                                                className='name'
                                                style={{ color: 'black', textAlign: 'left' }}
                                            >
                                                SamaR Haute Plug
                                            </p>
                                            <p
                                                className='role'
                                                style={{ color: 'black', textAlign: 'left' }}
                                            >
                                                General merchandise
                                            </p>
                                        </BusinessesText>
                                        {isCheckVisible ? (
                                            <BusinessesCheckMark>
                                                <svg
                                                    stroke='gray'
                                                    fill='gray'
                                                    stroke-width='0'
                                                    viewBox='0 0 1024 1024'
                                                    height='5em'
                                                    width='5em'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path d='M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z'></path>
                                                    <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z'></path>
                                                </svg>
                                            
                                            </BusinessesCheckMark>
                                        ) : (
                                            ''
                                        )}
                                    </BusinessesInfo>
                                </User>
                            </ActiveBusiness>
                        </OtherBusinesses>
                        <BusinessesLine />
                    </Section>
                </center> */}

                <PopupLine style={{ marginTop: '100px' }} />
                <Btns>
                    <GlobalButton type={'gray'}>
                        <svg
                            width='21'
                            height='20'
                            viewBox='0 0 21 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M8.25 7.5L13.25 12.5M13.25 7.5L8.25 12.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                stroke='#ACADB9'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Cancel
                    </GlobalButton>
                    <GlobalButton type={'primary'} onClick={handleSwitchButton}>
                        <svg
                            width='21'
                            height='20'
                            viewBox='0 0 21 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M7 10L9.5 12.5L14.5 7.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                stroke='white'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                        Select Business
                    </GlobalButton>
                </Btns>

                {/* <BookingForm>
                    <BookingFormTitle>Appointment Booking</BookingFormTitle>
                    <BookingFormContent>
                        <BookingFormLeft>
                            <InputGroupWrapper>
                                <InputGroup label={'Service'}>
                                    <GlobalSelect options={selectOptions} />
                                </InputGroup>
                            </InputGroupWrapper>
                            <InputGroupWrapper>
                                <InputGroup label={'Brief description  of appointment'}>
                                    <GlobalTextarea placeholder={'Describe the appointment'} />
                                </InputGroup>
                            </InputGroupWrapper>
                            <InputGroupWrapper>
                                <InputGroup label={'appointment location'}>
                                    <GlobalSelect options={selectOptions} />
                                </InputGroup>
                            </InputGroupWrapper>
                        </BookingFormLeft>
                        <BookingFormRight>
                            <SlotsTitle>Next Available Slots</SlotsTitle>
                            <SlotSection>
                                <SlotDate>May 17</SlotDate>
                                <SlotsWrapper>
                                    <Slot isSelected={true}>11:00 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                </SlotsWrapper>
                            </SlotSection>
                            <SlotSection>
                                <SlotDate>May 17</SlotDate>
                                <SlotsWrapper>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                </SlotsWrapper>
                            </SlotSection>
                            <SlotSection>
                                <SlotDate>May 17</SlotDate>
                                <SlotsWrapper>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                </SlotsWrapper>
                            </SlotSection>
                            <SlotSection>
                                <SlotDate>May 17</SlotDate>
                                <SlotsWrapper>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                    <Slot>11:30 AM</Slot>
                                </SlotsWrapper>
                            </SlotSection>
                        </BookingFormRight>
                    </BookingFormContent>
                    <PopupLine />
                    <Btns>
                        <GlobalButton type={'gray'}>
                            <svg
                                width='21'
                                height='20'
                                viewBox='0 0 21 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M8.25 7.5L13.25 12.5M13.25 7.5L8.25 12.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                    stroke='#ACADB9'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                            Cancel
                        </GlobalButton>
                        <GlobalButton type={'primary'}>
                            <svg
                                width='21'
                                height='20'
                                viewBox='0 0 21 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M7 10L9.5 12.5L14.5 7.5M7.25 17.5H14.25C15.6501 17.5 16.3502 17.5 16.885 17.2275C17.3554 16.9878 17.7378 16.6054 17.9775 16.135C18.25 15.6002 18.25 14.9001 18.25 13.5V6.5C18.25 5.09987 18.25 4.3998 17.9775 3.86502C17.7378 3.39462 17.3554 3.01217 16.885 2.77248C16.3502 2.5 15.6501 2.5 14.25 2.5H7.25C5.84987 2.5 5.1498 2.5 4.61502 2.77248C4.14462 3.01217 3.76217 3.39462 3.52248 3.86502C3.25 4.3998 3.25 5.09987 3.25 6.5V13.5C3.25 14.9001 3.25 15.6002 3.52248 16.135C3.76217 16.6054 4.14462 16.9878 4.61502 17.2275C5.1498 17.5 5.84987 17.5 7.25 17.5Z'
                                    stroke='white'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                            Select Business
                        </GlobalButton>
                    </Btns>
                </BookingForm> */}
            </Content>
        </ModalWrapper>
    );
};

import Header from 'components/header/header';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import {
  Col,
  Container,
  Main,
  Subtitle,
  Title,
  TitleWrapper,
  TopWrapper,
  Wrapper
} from './cart-styled';
import { SponsoredAdv } from './components/sponosred-adv/sponsored-adv';
import { ProductsYouMayLike } from './components/products-you-may-like/products-you-may-like';
import { CartBlock } from './components/cart-block/cart-block';
import { useSelector, } from 'react-redux';
import img from './components/product-item/img/temp-img.png';
import { AdSpace } from './components/ad-space/ad-space';
import { GlobalButton } from '../../components/global-button/global-button';
import { clearCart } from '../../redux/slices/product/productSlice';
import { useDispatch } from 'react-redux';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: '#', name: 'Cart' }
];

const products = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

const products2 = [
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  },
  {
    title: 'Huawei Y7',
    subtitle: 'Eltrons Electronics',
    price: '$250',
    img: img
  }
];

export const Cart = () => {
  const { productsInCart } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const handleClearCart = () => {
    dispatch(clearCart());
  }

  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Wrapper>
          <Col>
            <ProductsYouMayLike />
            <SponsoredAdv />
          </Col>
          <Main>
            <TopWrapper>
              <TitleWrapper>
                <Title>Cart Items</Title>
                <Subtitle>You have {productsInCart?.length} items in your cart</Subtitle>
                {productsInCart?.length > 0 && <p style={{ marginTop: 1, marginBottom: 10, color: 'red'}} onClick={handleClearCart}>Clear Cart</p>}
              </TitleWrapper>
              <GlobalButton type={'orange-secondary'}>Save for later</GlobalButton>
            </TopWrapper>

            <CartBlock />
          </Main>
          <Col>
            <SponsoredAdv />
            <AdSpace />
          </Col>
        </Wrapper>
      </Container>
    </>
  );
};

import styled from 'styled-components';

export const AtributesStyled = styled.div`
  padding: 16px 0;
`;

export const AttributesItem = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`;

export const AttributesItemTitle = styled.span`
  font-weight: 700;
`;

export const AttributesItemText = styled.div``;

export const Pill = styled.span`
  background-color: #e0e0e0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  padding: 2px 4px; /* Smaller padding */
  margin: 2px; /* Reduced space around each pill */
  font-size: 0.7em; /* Smaller font size */
  display: inline-block; /* Ensures the pills stay inline */
`;

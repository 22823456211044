import img from './img/img.png';
import { Container, ImgWrapper, Main, Title, Subtitle } from './business-link-card-styled';
import { RateStars } from '../../../../components/rate-stars/rate-stars';
import { ReactComponent as ChevronRight } from './img/chevron-right.svg';
import { useSelector } from 'react-redux';
import { getRouteById } from 'routes/routesHelper';

export const BusinessLinkCard = () => {
  const { productsInCart } = useSelector((state) => state.product);

    // Group products by business name
    const groupedProducts = productsInCart?.reduce((acc, item) => {
      const businessName = item.product?.business?.name;
      if (!acc[businessName]) {
        acc[businessName] = [];
      }
      acc[businessName].push(item);
      return acc;
    }, {});
  
    // Ensure groupedProducts is an object
    const filteredBusinesses = Object.entries(groupedProducts || {}).filter(([businessName, items]) => {
      const productNames = items.map(item => item.product?.name);
      return new Set(productNames).size === 1;
    });



  return (
    <>
    {filteredBusinesses?.length == 1 && filteredBusinesses?.map(([businessName, items]) => (
      <Container to={getRouteById('storefront', {bSlug: items[0].product?.business?.bSlug})} key={businessName}>
        <ImgWrapper>
          <img src={items[0].product?.business?.logo} alt='' />
        </ImgWrapper>
        <Main>
          <Title>{businessName}</Title>
          <Subtitle>{items[0].product.business.category}</Subtitle>
          <RateStars rate={items[0].product.business.rating} reviews={items[0].product.business.totalReviews} color={'#388638'} width={14} height={14} />
        </Main>
        <ChevronRight />
      </Container>
    ))}
  </>
  );
};

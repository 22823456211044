import React from 'react';
import ContentLoader from 'react-content-loader';

const ImageLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 600 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {/* Placeholder for image */}
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="800" />
    </ContentLoader>
  );
};

export default ImageLoader;

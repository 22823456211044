import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiBase = process.env.REACT_APP_API_BASE_ENDPOINT;

const initialState = {
    reviews: [],
    status: 'idle',
    reviewSubmitted: false,
    error: null,
    message: '',
};

export const getProductReviews = createAsyncThunk(
    'products/getProductReviews',
    async (params, { rejectWithValue }) => {
        const { slug } = params;
        try {
            const response = await axios.get(`${apiBase}/products/reviews?slug=${slug}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (err) {
            console.log(err);
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);

export const createProductReview = createAsyncThunk(
    'products/createProductReview',
    async (params, { rejectWithValue, dispatch }) => {
        const { productID, review, rating, authorID } = params;
        try {
            const response = await axios.post(`${apiBase}/products/reviews`, {
                productID,
                review,
                rating,
                authorID
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Dispatch getProductReviews after creating a review
            dispatch(getProductReviews({ slug: params.slug }));
            return response.data;
        } catch (err) {
            console.log(err);
            const errorMessage = err.response?.data?.message || 'An error occurred';
            return rejectWithValue(errorMessage);
        }
    }
);

const productReviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductReviews.pending, (state) => {
                state.status = 'loading';
                state.reviewSubmitted = false;
                state.reviews = [];
            })
            .addCase(getProductReviews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reviews = action.payload.data.reviews;
                state.message = action.payload.data.message;
            })
            .addCase(getProductReviews.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })
            .addCase(createProductReview.pending, (state) => {
                state.status = 'submitting';
                state.reviewSubmitted = false;
            })
            .addCase(createProductReview.fulfilled, (state, action) => {
                state.status = 'submitted';
                state.reviewSubmitted = true;
                state.message = action.payload.data.message;
            })
            .addCase(createProductReview.rejected, (state, action) => {
                state.status = 'failed';
                state.reviewSubmitted = false;
                state.error = action.payload || action.error.message;
            });
    },
});

export default productReviewsSlice.reducer;
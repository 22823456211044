import { FavBtn } from '../p-card/p-card-styled';
import Slider from 'react-slick';
import { SvgIcon } from '../svg-icon/svg-icon';
import React from 'react';
import { ImgWrapper } from './product-card-slider-styled';
import { ApproveSeller } from '../approve-seller/approve-seller';

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={'card-slider-next-btn'} onClick={onClick}>
            <svg
                width='10'
                height='18'
                viewBox='0 0 10 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M1 17L9 9L1 1'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </div>
    );
}
function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={'card-slider-prev-btn'} onClick={onClick}>
            <svg
                width='10'
                height='18'
                viewBox='0 0 10 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M9 17L1 9L9 1'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </div>
    );
}

export const ProductCardSlider = ({
    img,
    title,
    isFav,
    handleFavBtnClick,
    className,
    showDots,
    currentViewType,
    showArrows = true,
    showFav = true
}) => {
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: showDots,
        arrows: showArrows,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <ImgWrapper className={className} currentViewType={currentViewType}>
            <ApproveSeller />
            <Slider {...sliderSettings} className={'card-slider'}>
                <img src={img} />
                {/* {img.map((item, key) => {
                    return <img src={item} alt={title} key={key} />;
                })} */}
            </Slider>
            <FavBtn onClick={handleFavBtnClick} isFav={isFav} showFav={showFav}>
                <SvgIcon name={'heart-2'} />
            </FavBtn>
        </ImgWrapper>
    );
};

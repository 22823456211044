import {
    Attributes,
    AttributesList,
    AttributesListItem,
    AttributesTitle,
    Category,
    Description,
    DescriptionColor,
    DescriptionItem,
    DescriptionList,
    DescriptionListItem,
    DescriptionTitle,
    GalleryImages,
    GalleryWrapper,
    Main,
    MobileBackBtn,
    MobileTitle,
    PreviewBtn,
    PreviewBtnSidebar,
    Price,
    SidePreviewStyled,
    Subtitle,
    Title,
    SideImgPlaceholder
} from './side-preview-styled';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { useEffect } from 'react';
import { Line } from 'components/filter-widget/components/filter-widget-bottom/filter-widget-bottom-styled';
import {
    // ImgPlaceholder,
    ImgPlaceholderBig
} from 'pages/dashboard/pages/add-new-product-single/add-new-product-single-styled';
import imgPlaceholder from 'assets/icons/img-placeholder.svg';

export const SidePreview = ({
    isSidebarHidden,
    onPreviewButtonClick,
    sidebarDetails,
    product,
    business
}) => {
    return (
        <SidePreviewStyled isSidebarHidden={isSidebarHidden}>
            <PreviewBtn isSidebarHidden={isSidebarHidden} onClick={onPreviewButtonClick}>
                <SvgIcon name={'eye'} />
                Product Preview
            </PreviewBtn>
            <PreviewBtnSidebar isSidebarHidden={isSidebarHidden} onClick={onPreviewButtonClick}>
                <SvgIcon name={'eye'} />
                Hide Product Preview
            </PreviewBtnSidebar>
            <MobileBackBtn onClick={onPreviewButtonClick}>
                <SvgIcon name={'chevron-left'} />
                Back to Add New Product
            </MobileBackBtn>
            <MobileTitle>Product Preview</MobileTitle>
            <GalleryWrapper>
                {product.images[0] ? (
                    <ImgPlaceholderBig
                        mainImage={product.images[0].url}
                        style={{ backgroundSize: '90%' }}
                    />
                ) : (
                    <ImgPlaceholderBig mainImage={imgPlaceholder} />
                )}
                <GalleryImages>
                    {Array(4)
                        .fill(null)
                        .map((_, index) => (
                            <SideImgPlaceholder
                                key={index}
                                mainImage={
                                    product.images[index + 1]
                                        ? product.images[index + 1].url
                                        : imgPlaceholder
                                }
                                style={{
                                    backgroundSize: product.images[index + 1] ? '90%' : ''
                                }}
                            />
                        ))}
                </GalleryImages>
            </GalleryWrapper>
            <Main>
                <Category>{business ? business.name : 'Business name'}</Category>
                <Title>{product.title ? product.title : 'Product name'}</Title>
                <Price>
                    {product.price && product.price.regularPrice ? (
                        <span>
                            {product.price.regularPrice.currency}:{' '}
                            {product.price.regularPrice.amount || 'Price'}
                        </span>
                    ) : (
                        'Price'
                    )}
                    {/* {product.price && product.price.regularPrice
                        ? product.price.regularPrice.map((price, index) => (
                              <span key={index}>
                                  {price.currency}: {price.amount || 'Price'}
                              </span>
                          ))
                        : 'Price'} */}
                    {/* {' '}
                    ${' '}
                    {(() => {
                        const usdsalePrice = product.price.find(
                            (price) => price.currency === 'USD' && price.salePrice
                        );
                        if (usdsalePrice) {
                            return usdsalePrice.salePrice;
                        }

                        const usdregularPrice = product.price.find(
                            (price) => price.currency === 'USD' && price.regularPrice
                        );
                        if (usdregularPrice) {
                            return usdregularPrice.regularPrice;
                        }

                        const otherCurrencysalePrice = product.price.find(
                            (price) => price.salePrice
                        );
                        if (otherCurrencysalePrice) {
                            return (
                                otherCurrencysalePrice.salePrice +
                                ' ' +
                                otherCurrencysalePrice.currency
                            );
                        }

                        const otherCurrencyregularPrice = product.price.find(
                            (price) => price.regularPrice
                        );
                        if (otherCurrencyregularPrice) {
                            return (
                                otherCurrencyregularPrice.regularPrice +
                                ' ' +
                                otherCurrencyregularPrice.currency
                            );
                        }

                        // Default message if no price is available
                        return 'Price';
                    })()}{' '} */}
                </Price>

                <br />
                <Description>
                    <DescriptionTitle>Description:</DescriptionTitle>
                    {product.description
                        ? product.description
                        : ' Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra leo ornare viverra.'}
                </Description>

                <DescriptionItem>
                    <DescriptionTitle> Stock Quantity:</DescriptionTitle>
                    <Description>
                        {' '}
                        {product.inventory.quantity ? product.inventory.quantity : 0}
                    </Description>
                </DescriptionItem>
                <DescriptionItem>
                    <DescriptionTitle> Product Condition :</DescriptionTitle>
                    <Description> {product.condition ? product.condition : 'New'}</Description>
                </DescriptionItem>
                <Attributes>
                    <AttributesTitle>Product Attributes</AttributesTitle>
                    <AttributesList>
                        {product.attributes.length > 0 ? (
                            product.attributes.map((attribute) => {
                                return (
                                    <AttributesListItem key={attribute.id}>
                                        <span>{attribute.name}:</span> {attribute.value}
                                    </AttributesListItem>
                                );
                            })
                        ) : (
                            <>
                                <h4>No attributes selected for this product</h4>
                            </>
                        )}
                        <AttributesListItem>{/* <span>Brand:</span> Huawei */}</AttributesListItem>
                    </AttributesList>
                </Attributes>
            </Main>
        </SidePreviewStyled>
    );
};


import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  InputWrapper,
  SearchButton,
  SearchInput,
  Navigation,
  ButtonWrapper,
  DropdownsWrapper,
  LeftSide,
  FilterButton,
} from './all-order-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import {
  columnsData,

  SecondSidebarColumnsData,
  SecondSidebarColumnsRows,
  SidebarColumnsData,
  SidebarColumnsRows
} from './mock';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import filter from '../../../../assets/filter.svg';
import Sidebar from '../../../../components/sidebar/sidebar';
import { MobSearch } from '../../../../components/mob-search/mob-search';
import { FilterWidget } from '../../../../components/filter-widget/filter-widget';
import CreateOrderWidget from './create-order-widget';
import OrderDetailsWidget from './ordersDetailsWidget';
import axios from 'axios';
import {
  TagsWrapper,
  Tag,
  StyledId,
  CheckboxWrapper,
  CheckboxWrapperHeader,
  DropdownWrapper,
  Thumbnail,
  SidebarInputWrapper
} from './all-order-styled';
import { Status } from '../../../../components/status/status';
import { ReactComponent as Eye } from '../../../../assets/eye-table-icon.svg';
import thumbnail from '../../../../assets/all-order-sidebar-table-icon.png';
import Checkbox from '../../../../components/checkbox/checkbox';
import { useMemo } from 'react';
import { TableWrapper, Styles } from 'components/table/table-styled';
import { Button } from 'components/categories-card/categories-card-styled';

// import OrderDetailsWidget from './ordersDetailsWidget';

import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getOrder, getOrders } from '../../../../redux/slices/orders/ordersSlice';
import { useSelector } from 'react-redux';
import DataTable from './loaders/tableLoading';
import TableLoader from './loaders/ordersLoading';
import { GlobalSelect } from 'components/global-select/global-select';
import { set } from 'lodash';
import { Content, Section, SidebarTitle } from './all-order-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInput } from 'components/global-input/global-input';
import OrderCreatedDetailsWidget from './order-details-widget';
import { useRef } from 'react';
const breadcrumbs = [
  { link: '#', name: 'Sell Items' },
  { link: '#', name: 'Orders' },
  { link: '#', name: 'All Orders' }
];

// TODO filter by delivery method

const DropdownItems = ['Status', 'Payment Method', 'Delivery Method'];
const CustomerList = () => {
  const [quickSearch, setQuickSearch] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showOrderSidebar, setShowOrderSidebar] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [paymentFilter, setPaymentFilter] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [createdOrderDetails, setCreatedOrderDetails] = useState(false);

  const orderStatusFilterOptions = [
    { value: '', label: 'Status' },

    { value: 'pending', label: 'Pending' },
    { value: 'on-hold', label: 'On hold' },
    { value: 'processing', label: 'Processing' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'draft', label: 'Draft' },

  ];
  const orderPaymentOptions = [
    { value: '', label: 'Payment method' },
    { value: 'cash', label: 'Cash on delivery' },
    { value: 'ecocash', label: 'Ecocash' },
  ]


  const dispatch = useDispatch();

  const ordersStatus = useSelector(state => state.orders.ordersStatus);
  const [localStatus, setLocalStatus] = useState(ordersStatus);


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [orderId, setOrderId] = useState(null);
  const [refreshOrders, setRefreshOrders] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [successDraftMessage, setSuccessDraftMessage] = useState(false);
  const [orderBID, setOrderBID] = useState(null);
  const elementRef = useRef(null);


  useEffect(() => {
    setLocalStatus(ordersStatus);
  }, [ordersStatus]);
  // TODO GET BUSINESS ID FROM URL 
  const bID = 80;

  useEffect(() => {
    const params = {
      bID: bID,
    }

    dispatch(getOrders(params)).then((result) => {
      setAllOrders(result.payload.data.orders);
    });

  }, [refreshOrders]);

  const filteredOrders = useMemo(() => {

    const filtered = allOrders && allOrders.filter((order) => {
      const matchesQuickSearch = quickSearch
        ? order.customer.name?.toLowerCase().includes(quickSearch.toLowerCase()) ||
        order.id.toString().includes(quickSearch) ||
        order.status?.toLowerCase().includes(quickSearch.toLowerCase())
        : true;

      const matchesStatusFilter = statusFilter
        ? order.status?.toLowerCase() === statusFilter.toLowerCase()
        : true;

      const matchesPaymentFilter = paymentFilter
        ? order.paymentMethod?.toLowerCase() === paymentFilter.toLowerCase()
        : true;

      return matchesQuickSearch && matchesStatusFilter && matchesPaymentFilter;
    });


    return filtered;
  }, [quickSearch, statusFilter, paymentFilter, allOrders]);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedOrders = filteredOrders?.slice(startIndex, endIndex);
  const handleNextPage = () => {
    if (endIndex < filteredOrders?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle previous page (optional)
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewOrder = (orderId) => {

    setShowOrderSidebar(true);
    setOrderId(orderId);
  }



  const noOrdersMessage = statusFilter
    ? `No orders with status: ${statusFilter}`
    : paymentFilter === "cash"
      ? `No orders found with payment method: cash`
      : 'No orders found';

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 30,
      boxShadow: 'none',
      borderColor: 'none',
      '&:hover': {
        color: 'green'
      },
      border: state.isFocused ? '1.5px solid #DCDCDC' : '1.5px solid #DCDCDC'
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? '#FFF' : styles.color,
      backgroundColor: state.isSelected ? 'green' : styles.color,
      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
      '&:hover': {
        color: '#FFF',
        backgroundColor: 'green'
      }
    }),
    input: (base) => ({
      ...base,
      'input:focus': {
        boxShadow: 'none',
        border: '1px solid green'
      }
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 })
  }
  const [orderData, setOrderData] = useState(null);
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop === true) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elementRef.current.focus();
    }
  }, [scrollToTop]);
  const url = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_CREATE_API_URL;

  const createOrder = async (orderDetails) => {
    setLoading(true);
    try {
      const response = await axios.post(url, orderDetails);

      if (response.data.status === 200) {
        setScrollToTop(true);
        setSuccessMessage(true);
        setRefreshOrders(true);
        setOrderData(response.data.data.order);
        setOrderBID(response.data.data.bID);
        setCreatedOrderDetails(true);
        setShowSidebar(false);
        setLoading(false)
      }
    } catch (error) {
      console.error("Failed to create order:", error);
      setLoading(false)
    }
  };
  const createOrderDraft = async (orderDetails) => {
    setDraftLoading(true);
    try {
      const response = await axios.post(url, orderDetails);
      if (response.data.status === 200) {
        console.log(response);
        setSuccessDraftMessage(true);
        setRefreshOrders(true);
        setOrderData(orderDetails);
        setShowSidebar(false);
        setShowOrderSidebar(false);
        setDraftLoading(false)
      }
    } catch (error) {
      console.error("Failed to create order:", error);
      setDraftLoading(false)
    }
  };

  const downloadOrderJSON = (data) => {
    const jsonContent = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = url;
    link.download = `order_${data.orderDetails.status}.json`;
    document.body.appendChild(link);
    link.click();


    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  return (
    <>
      {/*TODO Remove date and add instructions, capture value , remove order number and total number,  // id and quantity*/}
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>All Orders</Title>
        <Navigation>
          <LeftSide>
            <FilterButton
              onClick={() => {
                setIsFilterVisible(true);
              }}
            >
              <img src={filter} alt='' />
              Filters
            </FilterButton>
            <MobSearch />
            <InputWrapper>
              <SearchInput
                placeholder={'Quick Search...'}
                value={quickSearch}
                onChange={(e) => setQuickSearch(e.target.value)}
              />
              <SearchButton>
                <img src={search} alt='' />
              </SearchButton>
            </InputWrapper>
            <DropdownsWrapper>
              <GlobalSelect
                options={orderStatusFilterOptions}
                placeholder={orderStatusFilterOptions.find(option => option.value === statusFilter)?.label}
                value={statusFilter}
                onChange={(selectedOption) => {
                  const newStatusFilter = selectedOption.value;

                  setStatusFilter(newStatusFilter);
                }}
                styles={customStyles}
              />
              {/* Filter by payment method */}
              {/* <GlobalSelect
                options={orderPaymentOptions}
                value={paymentFilter}
                onChange={(selectedOption) => {
                  const newpaymentFilter = selectedOption.value;
                  // console.log("New paymentFilter:", newpaymentFilter);
                  setPaymentFilter(newpaymentFilter);
                }}
                styles={customStyles}
              /> */}
            </DropdownsWrapper>
          </LeftSide>
          <ButtonWrapper onClick={() => setShowSidebar(true)}>

            <GlobalButton fullWidth height={40} type={'secondary'}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
                  stroke='#388638'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <p>Create Order</p>
            </GlobalButton>
          </ButtonWrapper>
        </Navigation>

        <TableWrapper>

          <Styles>
            {localStatus === 'loading' ? <DataTable /> :
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Products</th>
                    {/* <th>Payment Method</th> */}
                    {/* <th>Delivery Method</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>


                <tbody>
                  {filteredOrders?.length === 0 ? (
                    <tr>
                      <td colSpan="9">{noOrdersMessage}</td>
                    </tr>
                  ) : (
                    paginatedOrders?.map((order) => {
                      const displayedProducts = order.products.slice(0, 1);
                      const remainingProducts = order.products.length - 2;

                      return (
                        <tr key={order.id}>
                          <td>{order.id}</td>
                          <td>{order.customer.name}</td>
                          <td>{order.date}</td>
                          <td>
                            <Status label={order.status} isChecked={order.status === 'completed'} />
                          </td>
                          <td>{`${order.orderValue.currency} ${order.orderValue.value}`}</td>
                          <td>
                            <TagsWrapper>
                              {displayedProducts?.map((product, index) => (
                                <a href={product.url} target='_blank'>
                                  <Tag key={index}>{(product.name).slice(1, 14)}..</Tag></a>
                              ))}
                              {remainingProducts > 0 && <Tag>+{remainingProducts} more</Tag>}
                            </TagsWrapper>
                          </td>
                          {/* <td>{order.paymentMethod}</td> */}
                          {/* <td></td> */}
                          {/* <td>{order.deliveryDetails.method}</td> */}
                          <td>
                            <Eye onClick={() => handleViewOrder(order.id)} />

                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>

              </table>}
            {localStatus === 'success' && <div style={{ textAlign: 'right', margin: '20px 0' }}>
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                style={{
                  padding: '10px 20px',
                  margin: '0 5px',
                  backgroundColor: currentPage === 1 ? '#ccc' : '#F76D21',
                  color: currentPage === 1 ? '#666' : '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                disabled={endIndex >= filteredOrders?.length}
                style={{
                  padding: '10px 20px',
                  margin: '0 5px',
                  backgroundColor: endIndex >= filteredOrders?.length ? '#ccc' : '#F76D21',
                  color: endIndex >= filteredOrders?.length ? '#666' : '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: endIndex >= filteredOrders?.length ? 'not-allowed' : 'pointer',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                Next
              </button>
            </div>}


          </Styles>
        </TableWrapper>

        <Sidebar
          title={showSidebar ? 'Create New Order' : orderId ? `Order #${orderId}` : 'Order created'}
          width={showSidebar ? '1100px' : '900px'}
          showSidebar={showSidebar || showOrderSidebar || createdOrderDetails}
          onClose={() => {
            setShowSidebar(false);
            setShowOrderSidebar(false);
            setCreatedOrderDetails(false);
          }}
          onCloseText={'Back to All Orders'}
        >
          {createdOrderDetails && <OrderCreatedDetailsWidget orderData={orderData} orderBID={orderBID}
            scrollToTop={scrollToTop} elementRef={elementRef} />}
          {showSidebar ? (

            <CreateOrderWidget onCreateOrder={createOrder} onCreateOrderDraft={createOrderDraft}
              successMessage={successMessage} successDraftMessage={successDraftMessage}
              loading={loading} draftLoading={draftLoading} />
          ) : (
            showOrderSidebar && (
              <>
                <OrderDetailsWidget orderId={orderId} bID={bID} setRefreshOrders={setRefreshOrders} />

              </>
            )

          )}

        </Sidebar>
      </Container>

      <FilterWidget
        onClose={() => {
          setIsFilterVisible(false);
        }}
        isVisible={isFilterVisible}
        setIsVisible={setIsFilterVisible}
      />
    </>
  );
};

export default CustomerList;
/*TODO Create UI components for delete,Option to print PDFs*/


import styled, { css } from 'styled-components';
import { title20 } from 'assets/style-helpers/typography';
import { headerContainer, LatoFont, linkReset } from 'assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';
import { Link } from 'react-router-dom';




export const Container = styled.div`
  position: fixed;
  top: 134px;
  overflow-y: auto;
  left: 0;
  width: 100%;
  height: calc(100% - 134px);
  background: #ffffff;
  /* drop shadow green_v */
  z-index: 100;
  box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
  transition: all 0.3s;
  ${(props) =>
    props.isOpened
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

export const Title = styled.div`
  ${title20};
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  ${headerContainer};
  @media ${device.tablet} {
    display: none;
  }
`;

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const MenuIcon = styled.div`
  margin-right: 10px;
`;

export const MenuItemMain = styled.div``;

export const MenuTitle = styled.div`
  margin-bottom: 10px;
`;

export const MenuSublist = styled.div``;

export const Subitem = styled.div`
  ${LatoFont};

  & + & {
    margin-top: 8px;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    padding: 32px 20px 24px;
  }
`;

export const MobileMenuSection = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const MobileMenuTitlte = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const MobileMenuList = styled.div``;

export const MobileMenuItem = styled(Link)`
  ${LatoFont};
  display: flex;
  align-items: center;
  ${linkReset};
  ${(props) =>
    props.isRedColor &&
    css`
      color: #e54d45;
    `}
  img {
    display: flex;
    margin-right: 10px;
  }
  & + & {
    margin-top: 16px;
  }
`;




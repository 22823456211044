import { Container, InfoSection, InfoSectionMain, InfoSectionTop } from './delivery-info-styled';

import { ReactComponent as TruckIcon } from './icons/truck.svg';
import { ReactComponent as WalletIcon } from './icons/wallet.svg';
import { ReactComponent as ShieldTick } from './icons/shield-tick.svg';

export const DeliveryInfo = () => {
  return (
    <Container>
      {/* <InfoSection> */}
        {/* <InfoSectionTop> */}
          {/* <TruckIcon /> */}
          {/* Delivery to: <span>Harare</span> */}
        {/* </InfoSectionTop> */}
        {/* <InfoSectionMain> */}
          {/* <table> */}
            {/* <tr> */}
              {/* <td>1. Pickup from Post Office</td> */}
              {/* <td>Free</td> */}
            {/* </tr> */}
            {/* <tr> */}
              {/* <td>2. Delivery Service Everything Zimbabwean </td> */}
              {/* <td>$9.99</td> */}
            {/* </tr> */}
          {/* </table> */}
        {/* </InfoSectionMain> */}
      {/* </InfoSection> */}
      {/* <InfoSection> */}
        {/* <InfoSectionTop> */}
          {/* <WalletIcon /> */}
          {/* Payment */}
        {/* </InfoSectionTop> */}
        {/* <InfoSectionMain> */}
          {/* Prepayment with CityHopper, Mukuru, InnBucks, Visa, Mastercard, PayPal. Cash on Delivery. */}
        {/* </InfoSectionMain> */}
      {/* </InfoSection> */}
      <InfoSection>
        <InfoSectionTop>
          <ShieldTick />
          Warranty Options
        </InfoSectionTop>
        <InfoSectionMain>
          <b>Full Warranty.</b> Lorem ipsum dolor sit amet consectetur. Leo portti vitae at at
          mattis ultrices parturient vel.
        </InfoSectionMain>
      </InfoSection>
    </Container>
  );
};

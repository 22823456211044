import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from 'utils';
const ordersURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDERS_API_URL;
const orderURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_API_URL;
const updateOrderURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_UPDATE_API_URL;
const createOrderURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_ORDER_CREATE_API_URL;

const initialState = {
    orders: [],
    singleOrder: null,
    ordersStatus: 'idle',
    singleOrderStatus: 'idle',
    updateStatus: 'idle',
    error: null
};
const token = getToken();



export const getOrders = createAsyncThunk(
    'orders/getOrders',
    async (params, { rejectWithValue }) => {

        const { bID } = params


        try {

            const response = await axios.post(ordersURL,
                {
                    bID
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }

            );

            return response.data;
        } catch (err) {
            console.log(err.response?.data);
            return rejectWithValue(err.response?.data?.message);

        }
    }
);

export const getOrder = createAsyncThunk(
    'orders/getOrder',
    async (params, { rejectWithValue }) => {
        const { bID, orderID } = params;

        try {

            const response = await axios.post(orderURL,
                {
                    bID, orderID
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`

                    },
                }

            );
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
)
export const updateOrder = createAsyncThunk(
    'orders/updateOrder',
    async (params, { rejectWithValue }) => {
        const { bID, orderID, orderStatus } = params;

        try {

            const response = await axios.post(updateOrderURL,
                {
                    bID, orderID, orderStatus
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }

            );
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
)

export const createOrder = createAsyncThunk(
    'orders/createOrder',
    async (params, { rejectWithValue }) => {

        try {

            const { order } = params;
            console.log(token)
            const response = await axios.post(createOrderURL, order,
                {
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`,

                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message);
        }
    }
)

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('getOrders/pending'),
                (state) => {
                    state.ordersStatus = 'loading';
                    state.error = null;
                }
            )

            .addMatcher(
                (action) => action.type.endsWith('getOrders/fulfilled'),
                (state, action) => {
                    // const orders = action.payload;
                    state.ordersStatus = 'success';
                    state.orders = action.payload;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('getOrders/rejected'),
                (state, action) => {
                    state.ordersStatus = 'rejected';
                    state.error = action.payload;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('getOrder/pending'),
                (state, action) => {
                    state.singleOrderStatus = 'loading';
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('getOrder/fulfilled'),
                (state, action) => {
                    // const orders = action.payload;
                    state.singleOrderStatus = 'success';
                    state.singleOrder = action.payload;
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('getOrder/rejected'),
                (state, action) => {
                    state.singleOrderStatus = 'rejected';
                    // state.error = action.payload;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('updateOrder/pending'),
                (state, action) => {
                    state.updateStatus = 'loading';
                }
            )

            .addMatcher(
                (action) => action.type.endsWith('updateOrder/fulfilled'),
                (state, action) => {
                    // const orders = action.payload;
                    state.updateStatus = 'success';
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('updateOrder/rejected'),
                (state, action) => {
                    state.updateStatus = 'rejected';
                    // state.error = action.payload;
                }
            )

    },
});

export default ordersSlice.reducer;
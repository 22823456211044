import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const ColourRingLoader = (props) => {
    const { height, width } = props;
    return (
        <>
            <ColorRing
                visible={true}
                height={height ? height : 80}
                width={width ? width : 80}
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#388638', '#f76d21', '#FED101', '#DD0000', '#000000']}
            />
        </>
    )
}

export default ColourRingLoader
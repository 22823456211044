import {
    InputGroupWrapper,
    Section,
    SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInput } from 'components/global-input/global-input';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import { GlobalSelect } from 'components/global-select/global-select';
import { useEffect, useState } from 'react';

export const ProductDetails = ({ selectOptions, productDetails, updateProductDetails }) => {
    const handleChange = (key, value) => {
        updateProductDetails(key, value);
    };
    return (
        <Section>
            <SectionTitle>Product Details</SectionTitle>
            <InputGroupWrapper>
                <InputGroup label={'product name'}>
                    <GlobalInput
                        placeholder={'Write the name of the product'}
                        value={productDetails.productName}
                        onChange={(e) => {
                            handleChange('productName', e.target.value);
                        }}
                    />
                </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
                <InputGroup label={'product description'}>
                    <GlobalTextarea
                        placeholder={'Describe your product'}
                        value={productDetails.productDescription}
                        onChange={(e) => {
                            handleChange('productDescription', e.target.value);
                        }}
                    />
                </InputGroup>
            </InputGroupWrapper>
            <InputGroupWrapper>
                <InputGroup label={'product condition'}>
                    <GlobalSelect
                        options={selectOptions}
                        value={productDetails.productCondition}
                        onChange={(value) => handleChange('productCondition', value)}
                    />
                </InputGroup>
            </InputGroupWrapper>
        </Section>
    );
};

import styled from 'styled-components';
import { device } from '../../assets/style-helpers/breakpoints';
import { buttonReset } from '../../assets/style-helpers/style-helpers';

export const Container = styled.div``;

export const TextWithLines = styled.div`
    margin-bottom: 14px;
    display: flex;
    align-items: center;
`;

export const Text = styled.div`
    margin: 0 16px;
    color: var(--main-subtext);
    font-family: 'Lato', sans-serif;
`;

export const Line = styled.div`
    flex-grow: 1;
    height: 1px;
    background: var(--main-subtext);
`;
export const BusinessesLine = styled.div`
    flex-grow: 1;
    height: 1px;
    width: 70%;
    background: var(--main-gray-2);
`;

export const PopupLine = styled.div`
    flex-grow: 1;
    height: 1px;
    background: var(--main-gray-2);
    margin-bottom: 10px;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
`;

export const Button = styled.button`
    ${buttonReset};
    border: 1px solid var(--main-gray-2);
    border-radius: 6px;
    flex-basis: calc(50% - 12px);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 12px;
    }
    & + & {
        margin-left: 24px;
    }
`;

export const BottomSection = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${device.mobile} {
        margin-top: 22px;
    }
`;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { range } from 'lodash';
import React from 'react'

const businessURL = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_BUSINESS_API_URL;

const initialState = {
    bID: null,
    status: 'idle',
    error: null,
};

export const getDashboardAnalytics = createAsyncThunk(
    'analytics/dashboard',
    async ( params, { rejectWithValue }) => {
        const { bID, range } = params
        console.log('Range', range)

        try {
            const response = await axios.post(businessURL + '/analytics/dashboard',
                {
                    bID, range
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Dashboard response', response)
            return response.data;
        } catch (err) {
            console.log('Dashboard response', err.response?.data?.message)
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
           .addCase(getDashboardAnalytics.pending, (state) => {
            state.status = 'loading';
            state.error = null; 
          })
          .addCase(getDashboardAnalytics.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload.data;
            state.error = null;
          })
          .addCase(getDashboardAnalytics.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = action.payload || action.error.message;
          })
    },

});

export default dashboardSlice.reducer;  

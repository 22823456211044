import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { Tooltip } from 'components/tooltip/tooltip';
import { GlobalButton } from 'components/global-button/global-button';
import { ReactComponent as CircleCheck } from './check-circle.svg';
import {
    ButtonsWrapper,
    CheckboxContent,
    Content,
    Price,
    PromoteItem,
    SelectWrapper,
    Subtitle,
    Title,
    TotalWrapper
} from './promote-popup-styled';
import {
    customDropdownIndicatorSmall,
    GlobalSelect,
    selectCustomStyleSmall
} from '../../../../components/global-select/global-select';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';

const selectOptions = [
    { label: 'test', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];

export const SuccessSavePopup = ({ onClose, isVisible, onPayBtnClick }) => {
    return (
        <ModalWrapper onClose={onClose} isVisible={isVisible} alignCenter>
            <Content
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '100%',
                    gap: '20px'
                }}
            >
                <Title>Product Saved</Title>
                <PromoteItem style={{ border: 'none' }}>
                    <svg
                        stroke='green'
                        fill='green'
                        stroke-width='0'
                        viewBox='0 0 1024 1024'
                        height='5em'
                        width='5em'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z'></path>
                        <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z'></path>
                    </svg>
                </PromoteItem>
                {/* <TotalWrapper>
                    <span>Total Amount</span>
                    <span>$2,99</span>
                </TotalWrapper> */}
                {/* <ButtonsWrapper>
                    <GlobalButton onClick={onPayBtnClick}>
                        <CircleCheck />
                        Pay
                    </GlobalButton>
                    <GlobalButton type={'secondary'}>Publish without advertising</GlobalButton>
                </ButtonsWrapper> */}
            </Content>
        </ModalWrapper>
    );
};

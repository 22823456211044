import {
    CategoriesBreadcrumbsWrapper,
    CategoriesSectionSelectsWrapper,
    Section,
    SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalSelect } from 'components/global-select/global-select';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { InputGroup } from 'components/input-group/input-group';
import { useState } from 'react';
import { useEffect } from 'react';

export const ProductCategories = ({
    selectOptions,
    breadcrumbs,
    updateSidebar,
    updateProductDetails,
    productCategory,
    setProductCategory,
    categories,
    handleMenuScrollToBottom
}) => {
    const [subCategory, setSubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedChildCategory, setSelectedChildCategory] = useState(null);
    const [childCategories, setChildCategories] = useState(null);
    const [grandChildCategories, setGrandChildCategories] = useState(null);

    const handleChange = (key, value) => {
        const updatedCategories = { ...productCategory, [key]: value.value };
        if (key === 'parentCategory') {
            setSelectedCategory(value);

            const selectedCategoryObject = categories.find(
                (category) => category.id === value.value
            );
            setChildCategories(selectedCategoryObject ? selectedCategoryObject.subCategories : []);
            setGrandChildCategories([]);
        }
        if (key === 'childCategory') {
            setSelectedChildCategory(value);

            const selectedChildCategoryObject = childCategories.find(
                (subcategory) => subcategory.id === value.value
            );
            setGrandChildCategories(
                selectedChildCategoryObject ? selectedChildCategoryObject.subCategories : []
            );
        }
        setProductCategory(updatedCategories);
        updateProductDetails('productCategory', updatedCategories);
    };
    return (
        <Section>
            <SectionTitle>Categories</SectionTitle>
            <CategoriesSectionSelectsWrapper>
                <InputGroup label={'category'}>
                    <GlobalSelect
                        options={
                            categories
                                ? categories.map((category) => ({
                                      label: category.name,
                                      value: category.id
                                  }))
                                : []
                        }
                        onChange={(value) => handleChange('parentCategory', value)}
                        onMenuScrollToBottom={handleMenuScrollToBottom}
                    />
                </InputGroup>
                <InputGroup label={'Subcategory'}>
                    <GlobalSelect
                        options={
                            childCategories
                                ? childCategories.map((category) => ({
                                      label: category.name,
                                      value: category.id
                                  }))
                                : []
                        }
                        onChange={(value) => handleChange('childCategory', value)}
                    />
                </InputGroup>
                <InputGroup label={'sub-Subcategory'}>
                    <GlobalSelect
                        options={
                            grandChildCategories
                                ? grandChildCategories.map((category) => ({
                                      label: category.name,
                                      value: category.id
                                  }))
                                : []
                        }
                        onChange={(value) => handleChange('grandChildCategory', value)}
                    />
                </InputGroup>
            </CategoriesSectionSelectsWrapper>
            <CategoriesBreadcrumbsWrapper>
                <span>E.g.</span>
                <Breadcrumbs links={breadcrumbs} />
            </CategoriesBreadcrumbsWrapper>
        </Section>
    );
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PoppinsFont } from '../../../../assets/style-helpers/style-helpers';

export const ProductCardStyled = styled(Link)`
    padding: 24px 24px 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 60px rgba(62, 73, 59, 0.06);
    border-radius: 12px;
    align-items: center;
    text-align: center;
`;

export const IconWrapper = styled.div`
    background: #e7f0e7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
`;

export const ProductCardTitle = styled.div`
    font-size: 17px;
    ${PoppinsFont};
    margin-bottom: 8px;
`;

export const ProductCardSubtitle = styled.div`
    color: var(--secondary-text);
`;

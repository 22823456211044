
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    status: 'idle',
    name: "",
    description: "",
    allCategories: [
        { id: 1, name: 'Books & Stationary' },
        { id: 2, name: 'Clothing & Apparel' },
        { id: 3, name: 'Construction & Hardware Supplies' },
        { id: 4, name: 'Electronic Goods' },
        { id: 5, name: 'Farming Supplies' },
        { id: 6, name: 'Food & Groceries' },
        { id: 7, name: 'Health & Beauty' },
        { id: 8, name: 'Home & Garden' },
        { id: 9, name: 'Industrial Supplies' },
        { id: 10, name: 'Motor Spare & Accessories' },
        { id: 11, name: 'Motor Vehicles' },
        { id: 12, name: 'Real Estate' },
        { id: 13, name: 'Sports Hobbies & Leisure' },
        { id: 14, name: 'Trades & Services' }
    ],
    categories: [],
    phone: "",
    whatsApp: "",
    email: "",
    website: "",
    address: {
        shopAddress: "",
        streetAddress: "",
        suburb: "",
        city: "",
        province: "",
        country: "",
    },
    openingTimes: {
        monday: { open: '09:00', close: '17:00', disabled: false },
        tuesday: { open: '09:00', close: '17:00', disabled: false },
        wednesday: { open: '09:00', close: '17:00', disabled: false },
        thursday: { open: '09:00', close: '17:00', disabled: false },
        friday: { open: '09:00', close: '17:00', disabled: "" },
        saturday: { open: '09:00', close: '17:00', disabled: "" },
        sunday: { open: '09:00', close: '17:00', disabled: "" },
    },
    socials: {
        facebook: " ",
        linkedin: "",
        instagram: "",
        tiktok: "",
        twitter: "",
    },
    logo: "",
    coverImage: "",
    errorMessages: []
}

const businesssSettingSlice = createSlice({
    name: 'businessSettings',
    initialState,
    reducers: {

        updatename: (state, action) => {
            state.name = action.payload;
        },

        updateDescription: (state, action) => {
            state.description = action.payload;
        },


        addCategory: (state, action) => {

            state.categories.push(action.payload);


            state.allCategories = state.allCategories.filter(
                (category) => category.id !== action.payload.id
            );
        },
        removeCategory: (state, action) => {

            state.categories = state.categories.filter(
                (category) => category.id !== action.payload.id
            );


            state.allCategories.push(action.payload);
        },
        updatePhoneName: (state, action) => {
            state.phone = action.payload;
        },
        updateWhatsApp: (state, action) => {
            state.whatsApp = action.payload;
        },
        updateEmail: (state, action) => {
            state.email = action.payload;
        },
        updateWebsite: (state, action) => {
            state.website = action.payload;
        },
        updateWebsite: (state, action) => {
            state.website = action.payload;
        },
        updateAddressField: (state, action) => {
            const { field, value } = action.payload;
            state.address[field] = value;
        },

        updateSocialsField: (state, action) => {
            const { field, value } = action.payload;
            state.socials[field] = `https://${field}.com/${value}`;
        },
        updateOpeningTimes: (state, action) => {
            const { day, type, value } = action.payload;

            if (type === 'disabled') {
                state.openingTimes[day].disabled = value;
            } else {

                state.openingTimes = {
                    ...state.openingTimes,
                    [day]: {
                        ...state.openingTimes[day],
                        [type]: value
                    }
                };
            }
        },
        updateLogo: (state, action) => {
            state.logo = action.payload;
        },
        updateCoverImage: (state, action) => {
            state.coverImage = action.payload;
        },


    }
});

export const { updatename, updateDescription, addCategory, removeCategory, updatePhoneName,
    updateWhatsApp, updateEmail, updateWebsite, updateAddressField,
    updateSocialsField, updateOpeningTimes, updateLogo, updateCoverImage } = businesssSettingSlice.actions;
export default businesssSettingSlice.reducer;
import styled, { css } from 'styled-components';
import { buttonReset, mainContainer } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const StoresSectionStyled = styled.div`
  display: flex;
  margin-top: 40px;
  padding-bottom: 80px;
  @media ${device.tablet} {
    padding-bottom: 0;
  }
  @media ${device.mobile} {
    padding-bottom: 0;
  }
`;

export const Content = styled.div`
  flex: 0 0 100%;
`;

export const Container = styled.div`
  ${mainContainer};
`;

export const Map = styled.div`
  flex: 0 0 calc(50vw - (0.52 - 0.5) * (1632px - 32px));
  transform: translateX(-100%);
  @media ${device.laptop} {
    flex: 0 0 calc(50vw - (0.698 - 0.5) * (1264px - 32px));
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const Main = styled.div`
  // width: 52%;
  width: 100%;
  display: flex;
  @media ${device.laptop} {
    // width: 860px;
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const FilterWrapper = styled.div`
  flex-basis: 226px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const CardsSection = styled.div`
  flex-basis: calc(100% - 226px);
  padding: 0 32px;
  @media ${device.tablet} {
    flex-basis: 100%;
    padding: 0;
  }
`;

export const SearchWrapper = styled.div`
  // max-width: 218px;
  max-width: 100%;
  @media ${device.tablet} {
    max-width: 100%;
    //margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-products {
      flex-grow: 1;
    }
  }

  @media ${device.mobile} {
    .input-products {
      display: none;
    }
  }
`;

export const TopFilterSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  @media ${device.tablet} {
    //margin: 0;
    //justify-content: flex-end;
  }
`;

export const SelectWrapper = styled.div`
  flex-basis: 240px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row-reverse;
    > div:first-child {
      margin-left: 16px;
    }
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 18px;
  margin-bottom: 24px;
  ${(props) =>
    props.isListView &&
    css`
      grid-template-columns: 1fr;
    `}//
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${device.laptop_xs} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} {
    // max-width: 576px;
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
`;

export const MapBtn = styled.button`
  ${buttonReset};
  margin-right: 20px;
  margin-left: 8px;
  color: #c9cad3;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    display: none;
  }
  &:hover {
    color: var(--main-green);
  }
`;

export const TopFilterSectionWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const MobileFilterBtn = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    //margin-right: 24px;
  }
`;

export const MobileSort = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    flex-grow: 1;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    //margin-left: 24px;
    button {
      padding: 0;
      width: 40px;
      height: 40px;
      span {
        display: none;
      }
      svg {
        margin-right: 0;
      }
    }
  }
`;

export const MobileSearch = styled.div`
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`;

export const MapTop = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

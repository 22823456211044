import { SvgIcon } from '../../../../../../components/svg-icon/svg-icon';
import { RangeSlider } from '../../../../../../components/range-slider/range-slider';
import {
  Categories,
  CategoriesItem,
  CategoriesList,
  CategoriesTitle,
  Container,
  FilterMain,
  FilterTitle
} from './filter-styled';
import { filter, set } from 'lodash';
import { useEffect } from 'react';

export const Filter = (props) => {


  const { stores, setFilteredStores } = props;

  const handleClick = (category) => {
    setFilteredStores( filterStores(category) );
    console.log('Filtered Stores', filterStores(category) );
  }

  const filterStores = (category) => {
    return stores?.filter(store => store.category === category);
  }

  const categories = [
    'Books & Stationary',
    'Clothing & Apparel',
    'Construction & Hardware Supplies',
    'Electronic Goods',
    'Farming Supplies',
    'Food & Groceries',
    'Health & Beauty',
    'Home & Garden',
    'Industrial Supplies',
    'Motor Spare & Accessories',
    'Motor Vehicles',
    'Real Estate',
    'Sports Hobbies & Leisure',
    'Trades & Services'
  ]

  return (
    <Container>
      <FilterTitle>
        <span>Filters</span>
        <SvgIcon name={'filter'} />
      </FilterTitle>
      <FilterMain>
        <Categories>
          <CategoriesTitle>All Stores</CategoriesTitle>
          <CategoriesList>
            {
              categories.map((category, index) => {
                return (
                  <CategoriesItem key={index} onClick={() => handleClick(category)}>{category} <small>({filterStores(category)?.length})</small></CategoriesItem>
                )
              })
            }
          </CategoriesList>
        </Categories>
        {/* <CategoriesTitle>Search Radius</CategoriesTitle>
        <RangeSlider min={0.5} max={100} minVal={0.5} maxVal={100} onChange={() => {}}>
          <span>0.5Km</span>
          <span>100Km</span>
        </RangeSlider> */}
      </FilterMain>
    </Container>
  );
};

import styled, { css } from 'styled-components';
import { title20, title30 } from 'assets/style-helpers/typography';
import { buttonReset, cardStyle, LatoFont, PoppinsFont } from 'assets/style-helpers/style-helpers';
import imgPlaceholder from './img-placeholder.svg';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const Container = styled.div`
    margin-bottom: 106px;
`;

export const Title = styled.h1`
    ${title30};
    margin: 0 0 20px;
`;

export const Section = styled.div`
    ${cardStyle};
    padding: 32px;
    @media ${device.mobile} {
        padding: 0;
        box-shadow: none;
    }
    & + & {
        margin-top: 32px;
    }
`;

export const SectionTitle = styled.div`
    ${title20};
    margin-bottom: 16px;
`;

export const SectionText = styled.div`
    color: var(--secondary-text);
    ${LatoFont};
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const SectionSubtitle = styled.div`
    font-size: 14px;
    ${PoppinsFont};
    margin-top: 28px;
    margin-bottom: 18px;
`;

export const InputGroupWrapper = styled.div`
    span {
        ${LatoFont};
        a {
            color: var(--main-green);
        }
    }
    & + & {
        margin-top: 16px;
    }
`;

export const TopSection = styled(Section)``;

export const SelectWrapper = styled.div`
    max-width: 240px;
    flex-basis: 240px;
    margin-right: 40px;
`;

export const TopSectionInputGroupInner = styled.div`
    display: flex;
    ${LatoFont};
    align-items: center;
    @media ${device.mobile} {
        flex-wrap: wrap;
        ${SelectWrapper} {
            width: 100%;
            margin-right: 0;
            margin-bottom: 12px;
            max-width: 100%;
            flex-basis: 100%;
        }
    }
`;

export const CheckboxWrapper = styled.div`
    ${LatoFont};
    & + & {
        margin-left: 16px;
    }
`;

export const Subtext = styled.div`
    color: var(--main-subtext);
    ${LatoFont};
    margin: 8px 0;
`;

export const DateInput = styled.input`
    -webkit-appearance: none;
    padding: 14px 24px;
    border: 1px solid var(--divider);
    border-radius: 6px;
    ${LatoFont};
    &::-webkit-clear-button {
        font-size: 28px;
        height: 30px;
        position: relative;
    }

    &::-webkit-inner-spin-button {
        height: 28px;
    }

    &::-webkit-calendar-picker-indicator {
        font-size: 15px;
    }
`;

export const Wrapper = styled.div`
    margin-top: 32px;
    display: flex;
`;

export const Main = styled.div`
    flex-basis: 850px;
    margin-right: 32px;
    @media ${device.laptop} {
        flex-basis: 100%;
        margin-right: 0;
    }
`;

export const Side = styled.div`
    flex-basis: calc(100% - 850px - 32px);
    @media ${device.laptop} {
        flex-basis: auto;
        position: fixed;
        overflow-x: hidden;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        //transition: background 0.3s;
        ${(props) =>
            props.isSidebarHidden
                ? css`
                      background: rgba(31, 49, 36, 0);
                      transition-delay: 0.3s;
                      width: 60px;
                      overflow-y: hidden;
                      z-index: 1000;
                  `
                : css`
                      //transition-delay: 0.3s;
                      overflow-y: auto;

                      width: 100%;
                      background: rgba(31, 49, 36, 0.4);
                      z-index: 10000;
                  `};
    }
    @media ${device.tablet} {
        height: 100vh;
        background: white;
        width: 100%;
        transition: all.3s;
        ${(props) =>
            props.isSidebarHidden
                ? css`
                      opacity: 0;
                      transition-delay: 0ms;
                      pointer-events: none;
                  `
                : css`
                      opacity: 1;
                      pointer-events: all;
                  `};
    }
`;

export const CategoriesSectionSelectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    @media ${device.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const MobileOpenPreviwBtnWrapper = styled.div`
    display: none;
    @media ${device.laptop} {
        display: flex;
    }
    @media ${device.mobile} {
        width: 100%;
    }
`;

export const CategoriesBreadcrumbsWrapper = styled.div`
    display: flex;
    ${LatoFont};
    margin-top: 14px;
    color: var(--main-green);
    > div {
        padding: 0;
        margin-left: 8px;
    }
`;

export const ImgUploadWrapper = styled.div`
    display: flex;
    @media ${device.mobile} {
        flex-direction: column;
    }
`;

export const ImgPlaceholder = styled.div`
    border: 1px solid var(--divider);
    border-radius: 12px;
    background: url(${(props) => props.mainImage}) no-repeat center center;
    background-size: 72px 72px;
`;
export const ImgPlaceholderBig = styled(ImgPlaceholder)`
    height: 420px;
    background-size: 72px 72px;
    @media ${device.mobile} {
        height: 360px;
    }
`;
export const MainImage = styled.div`
    border: 1px solid var(--divider);
    border-radius: 12px;
    background: url(${(props) => props.mainImage}) no-repeat center center;
    background-size: cover;
    width: 300px;
    height: 80%;
    margin-bottom: 15px;
`;

export const ImgUploadMain = styled.div`
    margin-right: 40px;
    ${ImgPlaceholder} {
        padding: 156px 142px;
        margin-bottom: 16px;
    }
    @media ${device.mobile} {
        margin-right: 0;
        margin-bottom: 40px;
    }
`;

export const ImgUploadTitle = styled.div`
    color: var(--main-gray-3);
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

export const ImgUploadGallery = styled.div`
    ${ImgPlaceholder} {
        padding: 74px;
    }
`;

export const ImgUploadGalleryWrapper = styled.div`
    display: grid;
    margin-bottom: 16px;

    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
`;

export const ImgUploadBtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.mobile} {
        width: 100%;
        button {
            width: 100%;
        }
    }
`;

export const GridInputWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 22px;
    margin: 18px 0;
    label {
        ${PoppinsFont};
    }
    @media ${device.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const GridInputWrapper4Items = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 22px;
    margin: 18px 0;
    label {
        ${PoppinsFont};
    }
    @media ${device.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const PricingRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    margin-top: 24px;

    a {
        color: var(--main-green);
        text-decoration: underline;
    }
`;

export const PricingBtnWrapper = styled.div`
    ${PoppinsFont};
`;

export const AttributesInputGroupRow = styled.div`
    display: flex;
    align-items: center;

    ${CheckboxWrapper} {
        flex-shrink: 0;
        margin-left: 24px;
    }

    @media ${device.mobile} {
        flex-direction: column;
        align-items: flex-start;
        ${CheckboxWrapper} {
            margin-left: 0px;
            margin-top: 16px;
        }
    }
`;

export const TagsSection = styled.div`
    display: flex;
`;

const ColorDot = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 6px;
`;

export const ColorDotGreen = styled(ColorDot)`
    background: var(--main-green);
`;

export const ColorDotRed = styled(ColorDot)`
    background: #eb4335;
`;

export const ColorDotYellow = styled(ColorDot)`
    background: #ffbb00;
`;

export const FeaturesRow = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    @media ${device.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }

    button {
        ${buttonReset};
        color: var(--main-green);
        display: flex;
        align-items: center;
        justify-content: center;
        ${LatoFont};
        margin-top: 40px;
        svg {
            margin-right: 8px;
        }
    }
`;

export const SubmitWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    @media ${device.tablet} {
        align-items: flex-start;
    }
    @media ${device.mobile} {
        flex-direction: column;
        padding-top: 12px;
        border-top: 1px solid var(--divider);
        gap: 24px;
        button {
            width: 100%;
        }
    }
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    @media ${device.mobile} {
        width: 100%;
        flex-direction: column-reverse;
        gap: 8px;
    }
    &:first-child {
        @media ${device.tablet} {
            flex-direction: column;
            align-items: flex-start;
        }
        @media ${device.mobile} {
            flex-direction: column-reverse;
        }
    }
`;

export const ButtonInfo = styled.span`
    color: var(--main-subtext);
    @media ${device.mobile} {
        text-align: center;
        width: 100%;
    }
`;

import { Link } from 'react-router-dom';
import { TagsStyled } from './tags-styled';
import { getRouteById } from 'routes/routesHelper';

export const Tags = () => {
    return (
        <TagsStyled>
            <Link to={'#'}>#DealsOfTheDay</Link>
            <Link to={'#'}>Featured Products</Link>
            <Link to={getRouteById('popular-categories')}>Popular Categories</Link>
            <Link to={getRouteById('stores')}>#StoresYouWillLove</Link>
            <Link to={'#'}>#FindItOnAuction</Link>
        </TagsStyled>
    );
};

import React, { useEffect, useState } from 'react';
import {
    Container,
    Title,
    SidebarTitle,
    SidebarSubtitle,
    Section,

} from './all-order-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import search from '../../../../assets/search.svg';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Table from '../../../../components/table/table';
import {

    SecondSidebarColumnsData,

} from './mock';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import filter from '../../../../assets/filter.svg';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { InputGroup } from '../../../../components/input-group/input-group';
import Sidebar from '../../../../components/sidebar/sidebar';
import { GlobalInputPhone } from '../../../../components/global-input-phone/global-input-phone';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { ButtonsWrapper } from '../create-new-order/create-new-order-styled';
import { ReactComponent as PlusIcon } from '../../../dashboard/pages/create-new-order/icons/plus.svg';
import { ReactComponent as SaveIcon } from '../../../dashboard/pages/create-new-order/icons/save.svg';
import { ReactComponent as XSquareIcon } from '../../../dashboard/pages/create-new-order/icons/x-square.svg';
import axios from 'axios';
import { Item, List } from 'components/bid-history-widget/bid-history-widget-styled';
import { InputGroupWrapper } from 'pages/checkout/checkout-styled';
import { TableWrapper, Styles, Button } from 'components/table/orderProductTable';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalInputSelect } from 'components/global-input-select/global-input-select';
import { GlobalTextarea } from 'components/global-textarea/global-textarea';
import {
    BottomTotal,
    TotalAmount
} from './all-order-styled';
import { getProducts } from '../../../../redux/slices/product/productSlice';
import { Content } from './all-order-styled';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';

const CreateOrderWidget = ({ setShowOrderSidebar, setShowSidebar, onCreateOrder, onCreateOrderDraft, successMessage, successDraftMessage, loading, draftLoading }) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [tableData, setTableData] = useState([]);

    const [instructions, setInstructions] = useState('');

    const paymentMethod = [
        { value: 'cash-delivery', label: 'Cash on delivery' },
        { value: 'mukuru', label: 'Mukuru' },
        { value: 'ecocash', label: 'Ecocash' },
        { value: 'inn-bucks', label: 'InnBucks' },
        { value: 'visa-mastercard', label: 'Visa/Mastercard' },
        { value: 'contact-vendor', label: 'Contact Vendor' },


    ];
    // TODO create system to manage collection points
    const deliveryMethod = [
        { value: 'everything-zimbabwean', label: 'Everything Zimbabwean' },
        { value: 'collection', label: 'Shop collection' },
        { value: 'contact-vendor', label: 'Contact Vendor' },

    ];

    const statusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'on-hold', label: 'Awaiting payment' },
        { value: 'processing', label: 'Awaiting fulfillment' },
        { value: 'completed', label: 'Completed' },
        { value: 'cancelled', label: 'Cancelled' },

    ]
    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
        email: '',
        whatsapp: '',
        phone: ''
    });
    const [billingAddress, setBillingAddress] = useState({
        street: '',
        city: '',
        suburb: '',
        country: '',
        province: '',
    });

    const calculateTotalAmount = () => {

        return tableData.reduce((total, item) => {
            const itemsTotal = parseFloat(item.total);
            const totalAmount = total + itemsTotal;
            return totalAmount;
        }, 0)


    };

    const [order, setOrder] = useState({
        paymentMethod: 'Cash on delivery',
        status: 'Pending payment',

    });
    const [deliveryAddress, setDeliveryAddress] = useState({
        street: '',
        city: '',
        suburb: '',
        country: '',
        province: '',
    });
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        whatsapp: ''
    });

    const status = useSelector(state => state.product.status);
    const [localStatus, setLocalStatus] = useState(status);

    useEffect(() => {
        setLocalStatus(status);
    }, [status]);
    const bID = 80;
    // Get products from the redux store
    useEffect(() => {
        const params = {
            bID: bID
        };
        dispatch(getProducts(params)).then((result) => {
            // console.log(result);
            setProducts(result.payload);
        });
    }, []);

    const validateForm = () => {
        let formErrors = {};

        if (!customer.firstName) {
            formErrors.firstName = 'First name is required';
        }
        if (!customer.lastName) {
            formErrors.lastName = 'Last name is required';
        }

        if (!customer.email) {
            formErrors.email = 'Email is required';
        }

        if (!customer.phone) {
            formErrors.phone = 'Phone number is required';
        }
        if (tableData.length === 0) {
            formErrors.products = 'Select products';
        }

        setErrors(formErrors);


        return Object.keys(formErrors).length === 0;
    };

    // download order details as json   
    const downloadData = () => {
        const productData = tableData.map(item => ({
            id: item.id,
            quantity: item.quantity,
            productName: item.productName,

        }));

        const orderDetails = {
            bID: bID,
            customer,
            billingAddress,
            deliveryDetails: {
                deliveryMethod,
                address: deliveryAddress,
                instructions
            },
            orderDetails: {
                ...order,
            },
            products: productData
        };

        const jsonData = JSON.stringify(orderDetails, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "ordersDetails.json";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    // submit order details
    const handleSubmit = async () => {
        if (validateForm()) {


            const productData = tableData.map(item => ({
                id: item.id,
                quantity: item.quantity
            }))


            const orderDetails = {
                bID: bID,
                customer,
                billingAddress,
                deliveryDetails: {
                    deliveryMethod,
                    address: deliveryAddress,
                    instructions
                },
                orderDetails: {
                    ...order,
                },
                products: productData
            };

            onCreateOrder(orderDetails);

        }
    }
    const handleDraftSubmit = () => {
        if (validateForm()) {

            const productData = tableData.map(item => ({
                id: item.id,
                quantity: item.quantity
            }))

            const orderDetails = {
                bID: bID,
                customer,
                billingAddress,
                deliveryDetails: {
                    deliveryMethod,
                    address: deliveryAddress,
                    instructions
                },
                orderDetails: {
                    ...order,
                    status: 'draft'
                },
                products: productData
            };
            onCreateOrderDraft(orderDetails);
        }
    }
    useEffect(() => {
        const totalAmount = calculateTotalAmount();
        setOrder((prevOrder) => ({
            ...prevOrder,
            totalAmount: totalAmount,
        }));
    }, [tableData]);

    const handleSearchChange = (inputValue) => {
        if (inputValue) {
            const filtered = products.filter((product) =>
                product.title.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(products);
        }
    };


    const handleProductSelect = (selectedOption) => {
        const product = products.find(p => p.id === selectedOption.value);

        const isProductInTable = tableData.some(item => item.id === product.id);

        if (!isProductInTable) {
            const usdPrice = product.price.normalPrice.currency === 'USD' ? product.price.normalPrice.amount : 0;

            setTableData([
                ...tableData,
                {
                    id: product.id,
                    productName: product.title,
                    quantity: 1,
                    price: usdPrice,
                    total: usdPrice,
                    image: product.image.url
                },
            ]);
        }

        const updatedProducts = filteredProducts.filter((prod) => prod.id !== product.id);
        setFilteredProducts(updatedProducts);

        setSearchQuery('');
    };

    const updateQuantity = (productId, newQty) => {
        const updatedTableData = tableData.map(item => {
            if (item.id === productId) {
                const updatedQty = Math.max(1, parseInt(newQty, 10));  // Ensure quantity is always >= 1
                const updatedTotal = item.price * updatedQty;
                return { ...item, quantity: updatedQty, total: updatedTotal };  // Update item
            }
            return item;
        });
        setTableData(updatedTableData);
    };

    const removeProductFromOrder = (productId) => {
        const updatedTableData = tableData.filter(item => item.id !== productId);
        setTableData(updatedTableData);


        const removedProduct = updatedTableData.find(item => item.id === productId);
        if (removedProduct) {
            setFilteredProducts((prevProducts) => [...prevProducts, removedProduct]);
        }
    };

    return (
        <>
            <Content>
                <Section>
                    <SidebarTitle>Customer Billing Details</SidebarTitle>
                    <InputGroup label={'First name'}>
                        <GlobalInput
                            placeholder={'Enter first name'}
                            value={customer.firstName}
                            onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
                        />
                        {errors.firstName && <span style={{ color: 'red' }}>{errors.firstName}</span>}
                    </InputGroup>
                    <InputGroup label={'Last name'}>
                        <GlobalInput
                            placeholder={'Enter last name'}
                            value={customer.lastName}
                            onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
                        />
                        {errors.lastName && <span style={{ color: 'red' }}>{errors.lastName}</span>}
                    </InputGroup>
                    <InputGroup label={'email'}>
                        <GlobalInput
                            placeholder={'Enter your email'}
                            value={customer.email}
                            onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                    </InputGroup>
                    {/* TODO add country codes */}
                    <InputGroup label={'Phone Number'}>
                        <GlobalInputPhone placeholder={'Your Phone Number'}
                            value={customer.phone}
                            onChange={(e) => setCustomer({ ...customer, phone: e.target.value })}
                        />
                        {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
                    </InputGroup>
                    <InputGroup label={'WhatsApp Number'}>
                        <GlobalInputPhone placeholder={'Your WhatsApp Number'}
                            value={customer.whatsapp}
                            onChange={(e) => setCustomer({ ...customer, whatsapp: e.target.value })}
                        />

                    </InputGroup>
                    <SidebarSubtitle>Billing Address</SidebarSubtitle>
                    <InputGroup label={'street address'}>
                        <GlobalInput
                            placeholder={'Enter your street address'}
                            value={billingAddress.street}
                            onChange={(e) => setBillingAddress({ ...billingAddress, street: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'suburb'}>
                        <GlobalInput
                            placeholder={'Enter suburb'}
                            value={billingAddress.suburb}
                            onChange={(e) => setBillingAddress({ ...billingAddress, suburb: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'city'}>
                        <GlobalInput
                            placeholder={'Enter city'}
                            value={billingAddress.city}
                            onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'Province'}>
                        <GlobalInput
                            placeholder={'Enter province'}
                            value={billingAddress.province}
                            onChange={(e) => setBillingAddress({ ...billingAddress, province: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'country'}>
                        <GlobalInput
                            placeholder={'Enter country'}
                            value={billingAddress.country}
                            onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                        />
                    </InputGroup>
                </Section>
                <Section>
                    <SidebarTitle>Order Details</SidebarTitle>

                    <InputGroup label={'order status'}>
                        <GlobalSelect
                            options={statusOptions}
                            value={statusOptions.find(option => option.value === order.status)}
                            onChange={(selectedOption) => {
                                setOrder({ ...order, status: selectedOption.value });

                            }}

                        />
                    </InputGroup>
                    <InputGroup label={'payment method'}>
                        <GlobalSelect options={paymentMethod}
                            value={paymentMethod.find(option => option.value === order.paymentMethod)}
                            onChange={(selectedOption) => {
                                setOrder({ ...order, paymentMethod: selectedOption.label })
                            }}
                        />
                    </InputGroup>
                    <InputGroup label={'delivery method'}>
                        <GlobalSelect options={deliveryMethod} />
                    </InputGroup>
                    <SidebarSubtitle>Delivery Address</SidebarSubtitle>
                    <InputGroup label={'street address'}>
                        <GlobalInput
                            placeholder={'Enter street address'}
                            value={deliveryAddress.street}
                            onChange={(e) => setDeliveryAddress({ ...deliveryAddress, street: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'suburb'}>
                        <GlobalInput
                            placeholder={'Enter suburb'}
                            value={deliveryAddress.suburb}
                            onChange={(e) => setDeliveryAddress({ ...deliveryAddress, suburb: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'city'}>
                        <GlobalInput
                            placeholder={'Enter city'}
                            value={deliveryAddress.city}
                            onChange={(e) => setDeliveryAddress({ ...deliveryAddress, city: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'province'}>
                        <GlobalInput
                            placeholder={'Enter province'}
                            value={deliveryAddress.province}
                            onChange={(e) => setDeliveryAddress({ ...deliveryAddress, province: e.target.value })}
                        />
                    </InputGroup>
                    <InputGroup label={'country'}>
                        <GlobalInput
                            placeholder={'Enter country'}
                            value={deliveryAddress.country}
                            onChange={(e) => setDeliveryAddress({ ...deliveryAddress, country: e.target.value })}
                        />
                    </InputGroup>
                </Section>
                <Section>
                    <SidebarTitle>Order Items</SidebarTitle>
                    <InputGroup label={'select products'}>
                        <InputGroupWrapper>
                            {errors.products && <span style={{ color: 'red' }}>{errors.products}</span>}
                            <GlobalSelect

                                options={localStatus === 'loading' ? [] :
                                    products.map((product) => ({
                                        value: product.id,
                                        label: <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={product.image.url || 'default-image-url'}
                                                alt={product.title}
                                                style={{ width: 40, height: 40, marginRight: 10 }}
                                            />
                                            <span>
                                                {product.title} - ${product.price.normalPrice.currency === 'USD' ? product.price.normalPrice.amount : 0}
                                            </span>
                                        </div>
                                    }))
                                }
                    

                                value={searchQuery ? { value: selectedProduct?.id, label: selectedProduct?.title } : null}
                                onChange={handleProductSelect}
                                placeholder="Search products..."
                                onInputChange={handleSearchChange}
                            />

                        </InputGroupWrapper >
                    </InputGroup>
                    <TableWrapper>

                        <Styles>
                            {/* TODO add order summary eg total price, tax  */}
                            <table>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {tableData.length === 0 ?
                                        <tr>
                                            <td colSpan="9" style={{ textAlign: 'center', padding: '20px' }}>
                                                <div style={{ padding: '15px', borderRadius: '5px' }}>
                                                    No products selected. Please select products for this order.
                                                </div>
                                            </td>
                                        </tr>
                                        : (tableData.map((item) => (
                                            <tr key={item.id}>
                                                <td><img src={item.image} style={{ width: '50px', height: '50px', objectFit: 'cover' }} /></td>
                                                <td>{item.productName}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={item.quantity}
                                                        onChange={(e) => updateQuantity(item.id, e.target.value)}
                                                        min="1"

                                                        style={{ width: '60px' }}
                                                    />
                                                </td>
                                                <td>

                                                    <td>{item.price}</td>
                                                </td>
                                                <td>{item.total}</td>
                                                <td>
                                                    <Button onClick={() => removeProductFromOrder(item.id)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        )))}

                                </tbody>
                            </table>
                        </Styles>
                    </TableWrapper>
                    <BottomTotal>
                        <TotalAmount>Total Amount</TotalAmount>
                        <SidebarTitle>${order.totalAmount}</SidebarTitle>
                    </BottomTotal>
                </Section>
                <Section>
                    <InputGroupWrapper>
                        <InputGroup label={'Order Notes'}>
                            <GlobalTextarea placeholder={'Leave a note for your order (optional)'}
                                name="instructions"
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                </Section>
                {(errors.whatsapp || errors.phone || errors.firstName || errors.lastName || errors.email) && (
                    <span style={{ color: 'red', display: 'block', marginTop: '10px' }}>
                        Please fill in all required fields.
                    </span>
                )}
                {/* {successMessage && <span style={{ color: 'green', display: 'block', marginTop: '11px' }}>
                    Order created!
                </span>}
                {successDraftMessage && <span style={{ color: 'orange', display: 'block', marginTop: '11px' }}>
                    Draft order created!
                </span>} */}

                <ButtonsWrapper>

                    <GlobalButton
                        type='primary'
                        onClick={handleSubmit}
                    >
                        {loading ? <>
                            <ColourRingLoader width={30} height={30} />

                        </>
                            :
                            <PlusIcon />
                        }
                        Create Order
                    </GlobalButton>

                    <div>
                        <GlobalButton type='orange-secondary' onClick={handleDraftSubmit}>
                            {draftLoading ? <>
                                <ColourRingLoader width={30} height={30} />

                            </>
                                :
                                <SaveIcon />
                            }

                            Save Draft
                        </GlobalButton>
                        <GlobalButton type='gray' onClick={() => setShowSidebar(false)}>
                            <XSquareIcon />
                            Cancel Order
                        </GlobalButton>
                    </div>
                </ButtonsWrapper>
            </Content>
        </>
    )
}

export default CreateOrderWidget;
import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper';
import { GlobalCheckbox } from 'components/global-checkbox/global-checkbox';
import { Tooltip } from 'components/tooltip/tooltip';
import { GlobalButton } from 'components/global-button/global-button';
import { ReactComponent as CircleCheck } from './check-circle.svg';
import {
    ButtonsWrapper,
    CheckboxContent,
    Content,
    Price,
    PromoteItem,
    SelectWrapper,
    Subtitle,
    Title,
    TotalWrapper
} from './promote-popup-styled';
import {
    customDropdownIndicatorSmall,
    GlobalSelect,
    selectCustomStyleSmall
} from '../../../../components/global-select/global-select';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';

const selectOptions = [
    { label: 'test', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];

export const SaveDraftPopup = ({ onClose, isVisible, onPayBtnClick }) => {
    return (
        <ModalWrapper onClose={onClose} isVisible={isVisible} alignCenter>
            <Content
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '100%',
                    gap: '20px'
                }}
            >
                <Title>Saving Draft</Title>
                <PromoteItem style={{ border: 'none' }}>
                    <ColourRingLoader />
                </PromoteItem>
                {/* <TotalWrapper>
                    <span>Total Amount</span>
                    <span>$2,99</span>
                </TotalWrapper> */}
                {/* <ButtonsWrapper>
                    <GlobalButton onClick={onPayBtnClick}>
                        <CircleCheck />
                        Pay
                    </GlobalButton>
                    <GlobalButton type={'secondary'}>Publish without advertising</GlobalButton>
                </ButtonsWrapper> */}
            </Content>
        </ModalWrapper>
    );
};

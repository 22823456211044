import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Thumbs } from 'swiper';
import {
  Container,
  MainSlider,
  MainSliderSlide,
  NextButton,
  PrevButton,
  ThumbsContainer,
  ThumbSlierSlide,
  FavBtn
} from './product-gallery-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToWishlist, removeProductFromWishlist } from '../../redux/slices/product/productSlice';

const mockImagesArray = [
  'https://picsum.photos/500/500',
  'https://picsum.photos/800/500',
  'https://picsum.photos/600/600',
  'https://picsum.photos/400/400',
  'https://picsum.photos/600/400'
];

export const ProductGallery = ({ product }) => {

    //retrieve the images

    const [isFav, setIsFav] = useState(false);
    const [animationVisible, setAnimationVisible] = useState(false);
    const { wishlist } = useSelector((state) => state.product);
    const dispatch = useDispatch();
    const images = product?.data?.images || [];
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [mainSwiper, setMainSwiper] = useState(null);

    const handleFavClick = () => {
        setIsFav(!isFav);
        setAnimationVisible(true);
        // Hide the animation after it plays
        setTimeout(() => {
            setAnimationVisible(false);
        }, 2000); // Adjust duration to your animation length
    };

      const handleAddToWishlist = () => {
            dispatch(addProductToWishlist({ product }));
        };
    
        const handleRemoveFromWishlist = () => {
            dispatch(removeProductFromWishlist({ productId: product.id }));
        };
    
        const isProductInWishlist = wishlist?.some(item => item.id === product.id);

    return (
        <Container>
            <MainSlider>
          
                <FavBtn onClick={isProductInWishlist ? handleRemoveFromWishlist : handleAddToWishlist} isFav={isProductInWishlist}>
                    <SvgIcon name={'heart-2'} />
                </FavBtn>
                <PrevButton
                    onClick={() => {
                        mainSwiper.slidePrev();
                    }}
                >
                    <SvgIcon name={'slider-arrow'} />
                </PrevButton>
                <NextButton
                    onClick={() => {
                        mainSwiper.slideNext();
                    }}
                >
                    <SvgIcon name={'slider-arrow'} />
                </NextButton>

                <Swiper
                    onSwiper={setMainSwiper}
                    loop={true}
                    className="main-slider"
                >
                    {images.map((image, index) => ( 
                        <SwiperSlide key={index}>
                            <img src={image.url} alt={image.altText || `Product image ${index + 1}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </MainSlider>

            <ThumbsContainer>
                <Swiper
               modules={[Thumbs]}
               watchSlidesProgress
               onSwiper={setThumbsSwiper}
               slidesPerView={4}
               spaceBetween={8}
            >
            {images.map((image, key) => (
            <SwiperSlide key={key}>
              <ThumbSlierSlide>
                <img src={image.url} alt='' />
              </ThumbSlierSlide>
            </SwiperSlide>
            ))}
            </Swiper>
            </ThumbsContainer>
            {animationVisible && (
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}>
                    <Lottie animationData={animationData} loop={false} />
                </div>
            )}
        </Container>
    );
};
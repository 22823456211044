import {
    ImgPlaceholder,
    ImgUploadBtnWrapper,
    ImgUploadGallery,
    ImgUploadGalleryWrapper,
    ImgUploadMain,
    ImgUploadTitle,
    ImgUploadWrapper,
    MainImage,
    Section,
    SectionTitle
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import imgPlaceholder from 'assets/icons/img-placeholder.svg';
import { useState } from 'react';
import styled from 'styled-components';
import { device } from 'assets/style-helpers/breakpoints';

const ImgWrapper = styled.div`
  height: 150px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: center;
  .img-placeholder {
    width: 70px;
    height:70px
    border-radius: 12px;
  }
    .coverImage{
    width: 100%;
    height:100%;
    border-radius: 12px;

    }
    
`;

const CoverWrapper = styled.div`
    flex-basis: calc(100% - 150px - 24px);
    @media ${device.laptop} {
        max-width: 460px;
    }
    @media ${device.mobile} {
        max-width: 100%;
        flex-basis: auto;
    }
`;

export const ProductImage = ({
    title = 'Product Image',
    updateProductDetails,
    productImages,
    setProductImages
}) => {
    const [mainImage, setMainImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newMainImage = { url: reader.result };
                const updatedImages = [
                    newMainImage,
                    ...productImages.filter((img, index) => index !== 0)
                ];
                setProductImages(updatedImages);
                updateProductDetails('productImages', updatedImages);
            };
            reader.readAsDataURL(file);
        }
        console.log('ProductImages', productImages);
    };

    const handleGalleryImageUpload = (e) => {
        const files = e.target.files;
        if (files && productImages.length - 1 + files.length <= 5) {
            const newGalleryImages = [];
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    newGalleryImages.push({ url: reader.result });
                    if (newGalleryImages.length === files.length) {
                        const updatedImages = [
                            productImages[0],
                            ...productImages.slice(1),
                            ...newGalleryImages
                        ];
                        setProductImages(updatedImages);
                        updateProductDetails('productImages', updatedImages);
                    }
                };
                reader.readAsDataURL(file);
            });
        } else {
            alert('You can only upload a maximum of 4 gallery images');
        }
    };
    const handleRemoveImage = (index) => {
        const updatedImages = [...productImages];
        updatedImages.splice(index, 1);
        setProductImages(updatedImages);
        updateProductDetails('productImages', updatedImages);
    };
    return (
        <Section>
            <SectionTitle>{title}</SectionTitle>
            <ImgUploadWrapper>
                <ImgUploadMain>
                    <ImgUploadTitle>main product image</ImgUploadTitle>

                    {productImages[0] ? (
                        <ImgPlaceholder
                            mainImage={productImages[0].url}
                            style={{ backgroundSize: 'cover' }}
                        />
                    ) : (
                        <ImgPlaceholder mainImage={imgPlaceholder} />
                    )}

                    <ImgUploadBtnWrapper>
                        <GlobalButton
                            type={'orange-secondary'}
                            onClick={() => document.getElementById('product-main-image').click()}
                        >
                            <input
                                id='product-main-image'
                                type='file'
                                accept='image/*'
                                style={{ display: 'none' }}
                                onChange={(e) => handleMainImageUpload(e)}
                            />
                            <SvgIcon name={'upload'} />
                            Upload
                        </GlobalButton>
                    </ImgUploadBtnWrapper>
                </ImgUploadMain>
                <ImgUploadGallery>
                    <ImgUploadTitle>product image Gallery</ImgUploadTitle>

                    <ImgUploadGalleryWrapper>
                        {Array(4)
                            .fill(null)
                            .map((_, index) => (
                                <div key={index}>
                                    {productImages[index + 1] ? (
                                        <div
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block'
                                            }}
                                        >
                                            <ImgPlaceholder
                                                mainImage={productImages[index + 1].url}
                                            />{' '}
                                            <button
                                                type={'orange-secondary'}
                                                style={{
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    backgroundColor: '#F76D21',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    width: '20px',
                                                    height: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    zIndex: '100',
                                                    fontSize: '12px'
                                                }}
                                                onClick={() => handleRemoveImage(index + 1)}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ) : (
                                        <ImgPlaceholder mainImage={imgPlaceholder} />
                                    )}
                                </div>
                            ))}
                    </ImgUploadGalleryWrapper>
                    {productImages.length - 1 < 4 && (
                        <ImgUploadBtnWrapper>
                            <GlobalButton
                                type={'orange-secondary'}
                                onClick={() =>
                                    document.getElementById('product-gallery-image').click()
                                }
                            >
                                <input
                                    id='product-gallery-image'
                                    type='file'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    multiple
                                    onChange={handleGalleryImageUpload}
                                />
                                <SvgIcon name={'upload'} />
                                Upload
                            </GlobalButton>
                        </ImgUploadBtnWrapper>
                    )}
                </ImgUploadGallery>
            </ImgUploadWrapper>
        </Section>
    );
};

import styled from 'styled-components';
import { LatoFont, scrollbarStyles } from 'assets/style-helpers/style-helpers';
import { Link } from 'react-router-dom';
import { device } from 'assets/style-helpers/breakpoints';

export const OtherBusinesses = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 5px;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        margin: 15px 15px 0 15px;
    }
`;

export const BusinessesCheckMark = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: rgb(16, 17, 16);
    margin-left: 20px;
    border-radius: 50%;


    @media ${device.mobile} {
        margin-left: 0px !important;
        width: 30px;
        height: 20px;
        justify-content: center;
    }
`;

export const Content = styled.div`
    min-width: 500px;
    max-width: 100%;
    @media ${device.tablet} {
        width: 100%;
    }
`;

export const ActiveBusiness = styled.section`
    width: 90%;
    border-radius: 12px;
    // background-color: #e2e5eb;
    margin: 0px 10px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.mobile} {
        margin: 0;
        padding: 12px 12px 25px 12px;
        width: 100%;
    }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
    justify-content: space-between;

`;
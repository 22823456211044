import { useEffect, useState } from 'react';
import {
  Inner,
  MapWrapper,
  OpenSidebarBtn,
  SidebarWrapper,
  SidebarBackground
} from './sidebar-styled';
import {
  AddressTitleWrapper,
  Avatar,
  Info,
  InfoWrapper,
  Main,
  Properties,
  RateInfo,
  Reviews,
  Subtitle,
  Title
} from '../../../store-front-trades-services/components/other-stores-sidebar/components/other-store-card/other-store-card-styled';
import { RateStars } from 'components/rate-stars/rate-stars';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';
import {
  AddressText,
  AddressTitle,
  OpenHoursStatus,
  SocialLinks
} from '../../../../components/store-front-top/store-front-top-styled';
import mapIcon from '../../../../components/store-front-top/img/location.svg';
import avatar from 'assets/temp-img/single-shopping-center-avatar.png';
import { BannerImg } from '../../../dashboard/pages/business-information/components/business-settings-sidebar-styled';
import banner from '../../../dashboard/pages/business-information/img/sidebar-banner.jpg';
import map from 'assets/temp-img/map.png';
import { MobileCloseBtn } from '../../../store-finder/components/filter/filter-styled';
import { CloseBtn } from '../../../store-finder/components/filter/filter';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';


export const Sidebar = ({ isSidebarHidden, setIsSidebarHidden, business, product }) => {

  const businessName = business?.data?.name;
  const email = business.data?.email;
  const coverImage = business.data?.coverImage;
  const address = business.data?.address?.streetAddress;
  const logo = business.data?.logo;
  const bSlug = business.bSlug;
  
  return (
    <>
      <SidebarBackground setIsSidebarHidden={isSidebarHidden} />
      <SidebarWrapper isVisible={isSidebarHidden}>
        <MobileCloseBtn>
          <CloseBtn onClick={() => setIsSidebarHidden(true)} />
        </MobileCloseBtn>
        <Inner>
          <BannerImg>
            <img src={coverImage} alt='Banner' />
          </BannerImg>
          <InfoWrapper>
            <Avatar>
              <img src={logo} alt='Avatar' />
            </Avatar>
            <Info>
              <Title>{businessName}</Title>
              {/* <Subtitle>Real Estate</Subtitle> */}
              <RateStars rate={3} height={14} width={14} isRateCounter={false} />
              <RateInfo>
                <Reviews>10 Reviews</Reviews>
                {/* <Properties>25 Properties Sold</Properties> */}
              </RateInfo>
            </Info>
          </InfoWrapper>
          <GlobalButton fullWidth>
                <Link to={ getRouteById('storefront', {bSlug:bSlug} ) } target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <SvgIcon name={'eye'} />
                  View Storefront
                </Link>
          </GlobalButton>
          <Main>
            <AddressTitleWrapper>
              <AddressTitle>
                <img src={mapIcon} alt='Map Icon' />
                Address
              </AddressTitle>
              <OpenHoursStatus>Open now</OpenHoursStatus>
            </AddressTitleWrapper>
            <AddressText>
              {address}
            </AddressText>
            {/*<SocialLinks>
              <a href={`https://wa.me/${formattedWhatsAppNumber}`} target="_blank" rel="noopener noreferrer">
                <SvgIcon name={'whatsup'} />
              </a>
              <a href={`tel:${formattedPhoneNumber}`}>
                <SvgIcon name={'phone'} />
              </a>
              <a href={`mailto:${email}`}>
                <SvgIcon name={'email'} />
              </a>
            </SocialLinks>*/}
          </Main>
          <MapWrapper>
            <img src={map} alt='Map' />
          </MapWrapper>
        </Inner>
      </SidebarWrapper>
      <OpenSidebarBtn
        isSidebarHidden={isSidebarHidden}
        onClick={() => setIsSidebarHidden(!isSidebarHidden)}
      >
        Contact Seller
      </OpenSidebarBtn>
    </>
  );
};

import avatar from 'pages/dashboard/img/avatar.png';
import markerPin from 'pages/dashboard/img/marker-pin.svg';
import { ReactComponent as RefreshIcon } from 'pages/dashboard/img/refresh.svg';
import { ReactComponent as LineChartUp } from 'pages/dashboard/img/line-chart-up.svg';
import { ReactComponent as LineChartUp2 } from 'pages/dashboard/img/line-chart-up-2.svg';
import { ReactComponent as MessageTextCircle } from 'pages/dashboard/img/message-text-circle.svg';
import { ReactComponent as MessageNotificationCircle } from 'pages/dashboard/img/message-notification-circle.svg';
import { ReactComponent as Inbox02 } from 'pages/dashboard/img/inbox.svg';
import { ReactComponent as File06 } from 'pages/dashboard/img/file-06.svg';
import { ReactComponent as FileDownload } from 'pages/dashboard/img/file-download.svg';
import { ReactComponent as FileSearch } from 'pages/dashboard/img/file-search.svg';
import { ReactComponent as FilePlus } from 'pages/dashboard/img/file-plus.svg';
import { ReactComponent as Tag03 } from 'pages/dashboard/img/tag-03.svg';
import { ReactComponent as CurrencyDollar } from 'pages/dashboard/img/circle-dollar.svg';
import { ReactComponent as DotPoints } from 'pages/dashboard/img/dot-points.svg';
import { ReactComponent as Upload } from 'pages/dashboard/img/upload.svg';
import { ReactComponent as HeartHand } from 'pages/dashboard/img/heart-hands.svg';
import { ReactComponent as PlusSquare } from 'pages/dashboard/img/plus-square.svg';
import { ReactComponent as File02 } from 'pages/dashboard/img/file-02.svg';
import { ReactComponent as UsersRight } from 'pages/dashboard/img/users-right.svg';
import { ReactComponent as Users01 } from 'pages/dashboard/img/users-01.svg';
import { ReactComponent as Bell } from 'pages/dashboard/img/bell-01.svg';
import { ReactComponent as Announcments } from 'pages/dashboard/img/announcment.svg';
import { ReactComponent as Settings } from 'pages/dashboard/img/settings-02.svg';
import { ReactComponent as Phone } from 'pages/dashboard/img/phone.svg';
import { ReactComponent as Clock } from 'pages/dashboard/img/clock.svg';
import { ReactComponent as Globe } from 'pages/dashboard/img/globe-01.svg';
import { ReactComponent as Storefront } from 'pages/dashboard/img/store-front.svg';
import { ReactComponent as ClipboardCheck } from 'pages/dashboard/img/clipboard-check.svg';
import { ReactComponent as UserEdit } from 'pages/dashboard/img/user-edit.svg';
import { ReactComponent as CreditCard } from 'pages/dashboard/img/credit-card-02.svg';
import { ReactComponent as CreditCardEdit } from 'pages/dashboard/img/credit-card-edit.svg';

import { ReactComponent as CloseIcon } from 'pages/dashboard/img/close-icon.svg';

import {
    Container,
    Address,
    User,
    UserInfo,
    UserMain,
    name,
    Avatar,
    Submenu,
    MenuItem,
    MenuTitle,
    SubmenuLink,
    MenuWrapper,
    CloseDashboardBtnWrapper,
    Logout
} from './sidebar-styled';
import { GlobalButton } from 'components/global-button/global-button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/slices/auth/authSlice';
import { getRouteById } from 'routes/routesHelper';
import { ApointmentBookingPopup } from 'pages/store-front-trades-services/components/apointment-booking-popup/apointment-booking-popup';
import { useState } from 'react';
import { SwitchBusinessPopup } from '../switch-business-widget/switch-business-popup';

const menu = [
    {
        name: 'Dashboard',
        icon: <LineChartUp />,
        submenu: [
            {
                name: 'Dashboard',
                icon: <LineChartUp2 />,
                notifications: 25,
                link: getRouteById('dashboard')
            }
        ]
    },
    // {
    //   name: 'Messages',
    //   icon: <MessageTextCircle />,
    //   submenu: [
    //     {
    //       name: 'Notifications',
    //       icon: <MessageNotificationCircle />,
    //       notifications: 25,
    //       link: 'notifications'
    //     },
    //     {
    //       name: 'Inbox',
    //       icon: <Inbox02 />,
    //       notifications: 25,
    //       link: 'inbox'
    //     }
    //   ]
    // },

    {
        name: 'Selling',
        icon: <Tag03 />,
        submenu: [
            {
                name: 'Sell An Item',
                icon: <CurrencyDollar />,
                link: getRouteById('createProduct')
            },
            {
                name: 'All Sell Items',
                icon: <DotPoints />,
                link: 'all-sell-items'
            }
            // {
            //   name: 'Bulk Upload',
            //   icon: <Upload />,
            //   link: 'bulk-upload'
            // }
        ]
    },
    {
        name: 'Orders',
        icon: <File06 />,
        submenu: [
            {
                name: 'All Orders',
                icon: <FileSearch />,
                link: getRouteById('allOrders')
            },
            {
                name: 'New Orders',
                icon: <FileDownload />,
                notifications: 25,
                link: getRouteById('allOrders')
            },
            {
                name: 'Create New Order',
                icon: <FilePlus />,
                link: getRouteById('createOrder')
            }
        ]
    },
    // {
    //   name: 'My Appointments',
    //   icon: <HeartHand />,
    //   submenu: [
    //     {
    //       name: 'My Calendar',
    //       icon: <PlusSquare />,
    //       link: 'calendar'
    //     },
    //     {
    //       name: 'All appointments',
    //       icon: <File02 />,
    //       link: 'all-appointments'
    //     }
    //   ]
    // },
    // {
    //   name: 'My Services',
    //   icon: '',
    //   submenu: [
    //     {
    //       name: 'Add New Service',
    //       icon: <PlusSquare />,
    //       link: 'add-new-service'
    //     },
    //     {
    //       name: 'All Services',
    //       icon: <File02 />,
    //       link: 'all-services'
    //     }
    //   ]
    // },
    // {
    //   name: 'My Customers',
    //   icon: <UsersRight />,
    //   submenu: [
    //     {
    //       name: 'All Customers',
    //       icon: <Users01 />,
    //       link: 'add-new-service'
    //     },
    //     {
    //       name: 'Customer List',
    //       icon: <DotPoints />,
    //       link: 'all-services'
    //     },
    //     {
    //       name: 'Followers',
    //       icon: <Bell />,
    //       link: 'add-new-service'
    //     },
    //     {
    //       name: 'Newsletters',
    //       icon: <Announcments />,
    //       link: 'all-services'
    //     }
    //   ]
    // },
    {
        name: 'Business Settings',
        icon: <Settings />,
        submenu: [
            {
                name: 'Business Information',
                icon: <FileDownload />,
                link: getRouteById('businessInformation')
            }
            // {
            //   name: 'Contact Details',
            //   icon: <Phone />,
            //   link: 'all-services'
            // },
            // {
            //   name: 'Opening Times',
            //   icon: <Clock />,
            //   link: 'add-new-service'
            // },
            // {
            //   name: 'Social Media Accounts',
            //   icon: <Globe />,
            //   link: 'all-services'
            // },
            // {
            //   name: 'Store Front',
            //   icon: <Storefront />,
            //   link: 'add-new-service'
            // },
            // {
            //   name: 'Business Documents',
            //   icon: <ClipboardCheck />,
            //   link: 'all-services'
            // }
        ]
    }
    // {
    //   name: 'Manage Users',
    //   icon: <UserEdit />,
    //   submenu: [
    //     {
    //       name: 'Manage All Staff Members',
    //       icon: <UserEdit />,
    //       link: 'add-new-service'
    //     }
    //   ]
    // },
    // {
    //   name: 'Subscriptions',
    //   icon: <CreditCard />,
    //   submenu: [
    //     {
    //       name: 'Manage Subscriptions',
    //       icon: <CreditCardEdit />,
    //       link: 'add-new-service'
    //     }
    //   ]
    // }
];

export const Sidebar = ({ onCloseBtnClick }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { activeBusiness } = useSelector((state) => state.business);
    // console.log(activeBusiness);
    const handleLogout = () => {
        dispatch(logout(auth));
    };
    const switchBusinessButton = () => {
        setSwitchBusinessPopup(true);
    };
    const [switchBusinessPopup, setSwitchBusinessPopup] = useState(false);
    return (
        <>
            <div
                style={{
                    position: 'sticky',
                    height: '100vh',
                    top: '150px',
                    paddingBottom: '50px',
                    alignSelf: 'flex-start',
                    flexBasis: '300px'
                }}
            >
                <Container style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <CloseDashboardBtnWrapper>
                        <GlobalButton type={'orange'} onClick={onCloseBtnClick}>
                            <CloseIcon />
                            Dashboard Menu
                        </GlobalButton>
                    </CloseDashboardBtnWrapper>
                    <UserInfo>
                        <User>
                            <Avatar>
                                <img
                                    src={activeBusiness ? activeBusiness.logo : avatar}
                                    alt=''
                                    style={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px'
                                    }}
                                />
                            </Avatar>
                            <UserMain>
                                <name>
                                    {activeBusiness
                                        ? activeBusiness.name
                                        : 'Business Name Goes Here'}
                                </name>
                                <Address>
                                    {activeBusiness
                                        ? activeBusiness.category
                                        : 'Business Category Goes Here'}
                                    {/* <img src={markerPin} alt='marker' />
                                    <span>33 Robson Manyika/Orr Street 12</span> */}
                                </Address>
                            </UserMain>
                        </User>
                        <GlobalButton type={'orange-secondary'} onClick={switchBusinessButton}>
                            <RefreshIcon />
                            Switch to Another Business
                        </GlobalButton>
                    </UserInfo>
                    <MenuWrapper>
                        {menu.map((item, i) => {
                            return (
                                <MenuItem key={i}>
                                    <MenuTitle>
                                        {item.icon}
                                        {item.name}
                                    </MenuTitle>
                                    <Submenu>
                                        {item.submenu.map((item, i) => {
                                            return (
                                                <SubmenuLink to={item.link} key={i}>
                                                    {item.icon}
                                                    {item.name}
                                                </SubmenuLink>
                                            );
                                        })}
                                    </Submenu>
                                </MenuItem>
                            );
                        })}
                    </MenuWrapper>
                </Container>
                <Logout onClick={handleLogout}>Logout</Logout>
            </div>
            <SwitchBusinessPopup
                isApointmentBookingVibisle={switchBusinessPopup}
                onClose={() => {
                    setSwitchBusinessPopup(false);
                }}
            />
        </>
    );
};

import Header from 'components/header/header';
import { StoresYouLoveSection } from 'components/stores-you-love-section/stores-you-love-section';
import { Container, Title } from './stores-you-love-styled';
import { AdSpaceBanner } from './components/ad-space-banner/ad-space-banner';
import { StoresSection } from './components/stores-section/stores-section';
import { AdvSection } from './components/adv-section/adv-section';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { getRouteById } from 'routes/routesHelper';

const breadcrumbs = [
  { link: '/', name: 'Home' },
  { link: getRouteById('stores'), name: 'Vendors' }
];

export const StoresYouLove = () => {
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs links={breadcrumbs} />
        <Title>Stores You`ll Love</Title>
        {/* <StoresYouLoveSection title={'Featured Stores'} /> */}
      </Container>
      <StoresSection />
      {/* <Container advSection={true}>
        <AdvSection />
      </Container> */}
      {/* <AdSpaceBanner /> */}
    </>
  );
};

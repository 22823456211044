import { GlobalButton } from 'components/global-button/global-button';
import { StyledSecondaryButton } from 'components/global-button/global-button-styled';
import { CalendarBtn } from 'pages/dashboard/pages/dashboard-main/dashboard-main-styled';
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import "react-day-picker/style.css";


const DateRangePicker = (props) => {
    const { range = { start: '', end: '' }, handleDateRangeChange } = props;

    const [selected, setSelected] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    
    const handleApply = () => {
        handleDateRangeChange({ start: selected.from, end: selected.to });
        setIsOpen(false);
    }

    useEffect(() => {
        setStart( new Date (selected?.from)?.getTime() );
        if (selected?.to) {
            const endOfDay = new Date(selected.to);
            endOfDay.setHours(23, 59, 59, 999);
            setEnd(endOfDay.getTime());
        }
    }, [selected])

    const handleClickOutside = (event) => {
        if (event.target.closest('.overlay') === null && event.target.closest('button') === null) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatDate = (date) => {
        if (!date) return '';
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options).replace(/ /g, '-');
    };

    const selectedStyle = {}

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ position: 'relative' }}>
            <style>
                {`
                    .rdp-root {
                        --rdp-accent-color: var(--main-green);
                        --rdp-accent-background-color: rgb(231, 240, 231);
                    }
                    .overlay {
                        position: absolute;
                        top: 100%;
                        right: 0;
                        z-index: 50;
                        background: white;
                        border-radius: 4px;
                        padding: 25px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    }
                `}
            </style>
            <CalendarBtn onClick={toggleDropdown}>
                { selected ? `${formatDate(selected.from)} - ${formatDate(selected.to)}` : `${formatDate(range.start)} - ${formatDate(range.end)}` }
                <svg
                width='8'
                height='6'
                viewBox='0 0 8 6'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                >
                <path
                    d='M1 1.5L4 4.5L7 1.5'
                    stroke='#29263A'
                    strokeWidth='1.7'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                </svg>
            </CalendarBtn>
            {isOpen && (
                <div className="overlay">
                    <DayPicker
                        mode='range'
                        selected={selected}
                        onSelect={setSelected}
                        classNames={{
                            selected: 'selected-class',
                        }}
                        footer={
                            <>
                                <GlobalButton type={'primary'} onClick={handleApply}> Apply
                                </GlobalButton>
                                
                            </>
                        }
                    />
                    
                </div>
            )}
        </div>
    )
}

export default DateRangePicker
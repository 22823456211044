export const ROUTES = {
    // Front-end Routes
    HOME: { id: 'home', path: '/' },

    // Authentication Routes
    LOGIN: { id: 'login', path: '/login' },
    REGISTER: { id: 'register', path: '/register' },
    PASSWORD_RESET: { id: 'passwordReset', path: '/password-reset' },
    
    // Dashboard Routes - Orders
    DASHBOARD: { id: 'dashboard', path: '/dashboard' },
    ALL_ORDERS: { id: 'allOrders', path: '/dashboard/orders' },
    NEW_ORDERS: { id: 'newOrders', path: '/dashboard/orders/new-orders' },
    MY_ORDERS: { id: 'myOrders', path: '/dashboard/orders/my-orders' },
    ORDER: { id: 'order', path: '/dashboard/order/:id' },
    CREATE_ORDER: { id: 'createOrder', path: '/dashboard/orders/create-order' },
    
    B_INFO: { id: 'businessInformation', path: '/dashboard/settings/business-information' },
    CREATE_PRODUCT: { id: 'createProduct', path: '/dashboard/add-new-product' },

    // Products Routes
    PRODUCT: { id: 'product', path: '/product/:slug' },
    ADD_PRODUCT_SINGLE: { id: 'addProductSingle', path: '/dashboard/add-new-product' },

    // Storefront Routes
    STORES: { id: 'stores', path: '/vendors' },
    STOREFRONT: { id: 'storefront', path: '/vendor/:bSlug' },
    STOREFINDER: { id: 'storeFinder', path: '/store-finder' },

    // Categories Routes
    CATEGORY: { id: 'category', path: '/category/:slug/:page?' },
    POPULAR_CATEGORIES: { id: 'popular-categories', path: '/popular-categories' }
};
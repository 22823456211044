import React from 'react';

export const formatMoney = (amount, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const formatWhatsAppPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith('0')) {
    return '263' + phoneNumber.slice(1);
  } else if (phoneNumber.startsWith('+')) {
    return phoneNumber.slice(1);
  }
  return phoneNumber;
};


export const buildQueryParams = (params) => {
  const query = new URLSearchParams();
  if (params.slug) query.append('slug', params.slug);
  if (params.name) query.append('name', params.name);
  if (params.id) query.append('id', params.id);
  query.append('page', params.page);
  query.append('per_page', params.per_page);
  return query.toString();
};

export const getToken = () => {
  try {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : {};
    return user.token || null;
  } catch (error) {
    console.error('Error retrieving token from localStorage', error);
    return null;
  }
}
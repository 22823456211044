import img1 from '../../assets/temp-img/temp4.png';
import img2 from '../../assets/temp-img/temp5.png';
import img3 from '../../assets/temp-img/temp6.png';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import Header from '../../components/header/header';
import { PCard, ProductCardTypes } from '../../components/p-card/p-card';
import { ProductGallery } from '../../components/product-gallery/product-gallery';
import { ProductInfoLayout } from '../../components/product-info-layout/product-info-layout';
import { ProductDetails } from '../../components/product-info/components/product-details/product-details';
import { ProductInfo } from '../../components/product-info/product-info';
import { ProductsSlider } from '../../components/products-slider/products-slider';
import { SideBanner } from './components/side-banner/side-banner';
import { SponsoredBanners } from './components/sponsored-banners/sponsored-banners';
import { Container, Products, Title } from './single-product-general-styled';
import { Sidebar } from './components/sidebar/sidebar';
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getProductsBySlug, getRelatedProducts } from '../../redux/slices/product/productSlice';
import { getBusiness } from '../../redux/slices/business/businessSlice';
import { getProductReviews } from '../../redux/slices/products/reviews/productReviewsSlice';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ImageLoader from './loaders/imageLoader.jsx'; // Ensure relative path
import ProductInfoLoader from './loaders/productInfoLoader.jsx'; // Ensure relative path


const dealsMock = [
    {
        img: img1,
        title: 'ALD / New Era Yankees Hat, black',
        rate: 4.8,
        reviews: 51,
        old_price: '$39.99',
        price: '$19.90'
    },
    {
        img: img2,
        title: 'NEW YORK - Embroidered hip hop snapback adjustable',
        rate: 3.8,
        reviews: 28,
        old_price: '$39.99',
        price: '$19.90'
    },
    {
        img: img1,
        title: 'ALD / New Era Yankees Hat, black',
        rate: 4.1,
        reviews: 30,
        old_price: '$19.99',
        price: '$10.00'
    },
    {
        img: img3,
        title: 'NEW YORK - Embroidered hip hop snapback adjustable',
        rate: 3.5,
        reviews: 51,
        old_price: '$39.99',
        price: '$19.90'
    },
    {
        img: img3,
        title: 'NEW YORK - Embroidered hip hop snapback adjustable',
        rate: 3.5,
        reviews: 51,
        old_price: '$39.99',
        price: '$19.90'
    },
    {
        img: img3,
        title: 'NEW YORK - Embroidered hip hop snapback adjustable',
        rate: 3.5,
        reviews: 51,
        old_price: '$39.99',
        price: '$19.90'
    },
    {
        img: img3,
        title: 'NEW YORK - Embroidered hip hop snapback adjustable',
        rate: 3.5,
        reviews: 51,
        old_price: '$39.99',
        price: '$19.90'
    }
];

export const SingleProductGeneral = () => {

    const dispatch = useDispatch();
    const [business, setBusiness] = useState(true);
    const [isSidebarHidden, setIsSidebarHidden] = useState(true);
    const {slug}=useParams();
    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    
    useEffect(() => {

     const params = {
         slug: slug 
    };
    
      dispatch(getProductsBySlug(params)).then((result) => {
        setProduct(result.payload);
        const businessParams = {
          bSlug: result.payload.data.business.bSlug
        }
        dispatch(getBusiness(businessParams)).then((result) => {
          setBusiness(result.payload);
        });
        setIsLoading(false);
    });

    const relatedParams = {
      slug: slug,
      limit: 8
    }
    dispatch(getProductReviews(params))
      dispatch(getRelatedProducts(relatedParams)).then((result) => {
        setProducts(result.payload.data.load);
      }
      );
      
    }, [dispatch, slug]);
  


  
  return (
    <>
      <Header />
      <Container>
        <Breadcrumbs />
        <ProductInfoLayout>
          {/* Show skeleton loader for ProductGallery while loading */}
          {isLoading ? (
            <ImageLoader />  // Placeholder loader for the image section
          ) : (
            <ProductGallery product={product} />
          )}

          <SponsoredBanners />
         {/* ProductInfo Loader */}
         {isLoading ? (
            <ProductInfoLoader/> 
          ) : (
            <ProductInfo
              product={product}
              onContactSellerBtnClick={() => {
                setIsSidebarHidden(false);
              }}
            />
          )}
          <ProductDetails product={product} />
          {product && product.data && <SideBanner business={product?.data?.business} />}
        </ProductInfoLayout>
      </Container>
      <Products>
        <Container>
          <Title>Related Products</Title>
        </Container>
        <ProductsSlider>
          {products?.map((product, index) => {
            return <PCard product={product} business={product.business} type={ProductCardTypes.STORE} key={index} />
          })} 
        </ProductsSlider>
      </Products>
      <Sidebar isSidebarHidden={isSidebarHidden} business={business} setIsSidebarHidden={setIsSidebarHidden} />
    </>
  );
};
import {
    ButtonsWrapper,
    Container,
    Main,
    MobileOpenPreviewBtnWrapper,
    OpenSidebarBtn,
    OpenSidebarBtnSidebar,
    Side,
    SidebarBackground,
    Title,
    Wrapper
} from './business-information-styled';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { GlobalButton } from 'components/global-button/global-button';
import { SvgIcon } from 'components/svg-icon/svg-icon';

import { BusinessInformationSection } from './components/business-information-section';
import { ContactInformationSection } from './components/contact-information-section';
import { OpeningTimesSection } from './components/opening-times-section';
import { SocialMediaSection } from './components/social-media-section';
import { StoreFrontSettingsSection } from './components/store-front-settings-section';
import { BusinessDocumentsSection } from './components/business-documents-section';

import { BusinessSettingsSidebar } from './components/business-settings-sidebar';
import { useState } from 'react';
import { ApproveVendorBanner } from '../../../../components/approve-vendor-banner/approve-vendor-banner';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';

const breadcrumbs = [
    { link: '#', name: 'Sell Items' },
    { link: '#', name: 'Business Settings' }
];

const selectOptions = [
    { label: 'test', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];
// TODO ADD CATEGORIES FROM CURRENT WEBSITE
export const BusinessInformation = () => {
    const [isSidebarHidden, setIsSidebarHidden] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [bID, setBID] = useState('');
    
    const [businessInformation, setBusinessInformation] = useState({
        name: '',
        description: '',
        categories: []
    });

    const [error, setError] = useState({
        name: '',
        description: '',
        category: ''
    });

    const businessInfo = useSelector((state) => state.businessSettingsReducer);

    const isAuth = useSelector((state) => state.auth);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);

    // TODO check if user has a business created before they can access certain features

    const dataToSend = {
        name: businessInfo.name,
        description: businessInfo.description,
        categories: businessInfo.categories,
        phone: businessInfo.phone,
        whatsApp: businessInfo.whatsApp,
        email: businessInfo.email,
        address: businessInfo.address,
        openingTimes: businessInfo.openingTimes,
        socials: businessInfo.socials,
        logo: businessInfo.logo,
        coverImage: businessInfo.coverImage
    };
    const saveBusinessSettings = async (event) => {
        event.preventDefault();

        setErrorMessages([]);
        setResponseMessage('');
        //  error handling
        const errors = [];
        if (!businessInfo.name) errors.push('business name');
        if (!businessInfo.description) errors.push('business description');
        if (businessInfo.categories.length === 0) errors.push('at least one category');
        if (!businessInfo.phone) errors.push('phone number');

        if (!businessInfo.email) errors.push('email');
        if (!businessInfo.address.shopAddress) errors.push('shop address');

        if (errors.length > 0) {
            const errorMessage = `Business settings save failed. Please provide the missing details: ${errors.join(
                ', '
            )}.`;
            setErrorMessages([errorMessage]);
            return;
        }
        setErrorMessages([]);
        // downloadBusinessInfo();
        setLoading(true);

        try {
            const token = user.token;
            console.log(token);
            const APIUrl =
                process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_BUSINESS_CREATE_API_URL;
            const response = await fetch(APIUrl, {
                method: 'POST',
                headers: { 'Content-type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify(dataToSend)
            });
            const result = await response.json();
            if (!response.ok) {
                console.log('Failed to save details');
                setErrorMessages(['Failed to save. Please try again.']);
            } else {
                console.log('Business saved', result);
                setBID(result.data.bID);
                setResponseMessage('Business settings saved successfully');
            }
            // const result = await response.json();
        } catch (error) {
            console.error('Failed to save business', error);
        }
        setLoading(false);
    };

    const downloadBusinessInfo = () => {
        const fileData = JSON.stringify(dataToSend, null, 2);
        const blob = new Blob([fileData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'business-info.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Container>
                <Breadcrumbs links={breadcrumbs} />
                <Title>Business Settings</Title>

                <Wrapper>
                    <Main style={{ flex: 1, overflowY: 'auto' }}>
                        <BusinessInformationSection
                            businessInformation={businessInformation}
                            setBusinessInformation={setBusinessInformation}
                            error={error}
                            setError={setError}
                        />
                        <ContactInformationSection selectOptions={selectOptions} />
                        <OpeningTimesSection />
                        <SocialMediaSection />
                        <StoreFrontSettingsSection />
                        {/* Display error messages*/}
                        {errorMessages.length > 0 && (
                            <div style={{ color: 'red', marginBottom: '20px' }}>
                                {errorMessages[0]}
                            </div>
                        )}
                        {responseMessage && (
                            <div style={{ color: 'green', marginBottom: '20px' }}>
                                {responseMessage}
                            </div>
                        )}
                        <ButtonsWrapper>
                            <div>
                                <GlobalButton type={'primary'} onClick={saveBusinessSettings}>
                                    {loading && <ColourRingLoader width={30} height={30} />}
                                    {responseMessage && <SvgIcon name={'check-square'} />}
                                    Save Changes
                                </GlobalButton>

                                {bID && !loading && (
                                    <>
                                        <Link
                                            style={{ marginLeft: '20px', padding: '0px' }}
                                            target={'_blank'}
                                            to={getRouteById('home') + 'vendor/' + bID}
                                        >
                                            <GlobalButton type={'secondary'}>
                                                View Store
                                            </GlobalButton>
                                        </Link>
                                    </>
                                )}
                                {/* TODO add another store */}
                            </div>
                            <MobileOpenPreviewBtnWrapper>
                                <GlobalButton
                                    type={'primary'}
                                    onClick={() => {
                                        setIsSidebarHidden(false);
                                    }}
                                >
                                    <SvgIcon name={'eye'} />
                                    Store Front Preview
                                </GlobalButton>
                            </MobileOpenPreviewBtnWrapper>
                        </ButtonsWrapper>
                        {/* TODO Vendor verification */}
                        {/* <ApproveVendorBanner />
          <br />
          <BusinessDocumentsSection /> */}
                    </Main>
                    <Side
                        isSidebarHidden={isSidebarHidden}
                        style={{
                            position: 'sticky',
                            top: '100px',
                            alignSelf: 'flex-start',
                            marginBottom: '20%',
                            height: '100vh'
                        }}
                    >
                        <BusinessSettingsSidebar
                            isSidebarHidden={isSidebarHidden}
                            setIsSidebarHidden={setIsSidebarHidden}
                            businessInformation={businessInformation}
                        />
                        <SidebarBackground />
                    </Side>
                    <OpenSidebarBtn
                        isSidebarHidden={isSidebarHidden}
                        onClick={() => {
                            setIsSidebarHidden(!isSidebarHidden);
                        }}
                    >
                        <SvgIcon name={'eye'} />
                        Store Front Preview
                    </OpenSidebarBtn>
                    <OpenSidebarBtnSidebar
                        isSidebarHidden={isSidebarHidden}
                        onClick={() => {
                            setIsSidebarHidden(!isSidebarHidden);
                        }}
                    >
                        <SvgIcon name={'eye'} />
                        Hide Store Front Preview
                    </OpenSidebarBtnSidebar>
                </Wrapper>
            </Container>
        </div>
    );
};

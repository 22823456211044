import Background from './background/background';
import General from './categories-general/general';
import { MainStyled } from './main-styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { Container } from '../../../categories-motor-vehicles/components/main/main-styled';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';



export const slides = [
  {
    title: 'General Categories',
    subtitle: 'General',
    buttonText: '000-120-456-789'
  },
  {
    title: 'General Categories',
    subtitle: 'General',
    buttonText: '000-120-456-000'
  },
  {
    title: 'General Categories',
    subtitle: 'General',
    buttonText: '000-000-000-000'
  }
];

const Main = ({ onFilterBtnClick }) => {
    const { category } = useSelector((state) => state.categories);

  return (
    <MainStyled>
      <Container>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 2500 }}
          pagination={{ clickable: true }}
        >
          {category?.subCategories?.map((item) => {
            return (
              <SwiperSlide key={item.name}>
                <Background
                  title={item.name}
                  subtitle={item.description.slice(0, 25)}
                  buttonText={`Placeholder`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <General onFilterBtnClick={onFilterBtnClick} />
    </MainStyled>
  );
};

export default Main;

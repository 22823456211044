import { Link, useNavigate } from 'react-router-dom';

import Header from 'components/header/header';
import { AuthPagesContainer } from 'components/auth-pages-container/auth-pages-container';
import { InputGroup } from 'components/input-group/input-group';
import { GlobalInputPassword } from 'components/global-input-password/global-input-password';
import { GlobalInput } from 'components/global-input/global-input';
import { GoogleFacebookAuth } from 'components/google-facebook-auth/google-facebook-auth';
import { GrayText, InputGroupWrapper, StyledLink } from '../login/login-styled';
import {
  AcceptTerms,
  AccountType,
  CompareLink,
  LoginSection,
  RadiosWrapper,
  RegisterButton,
  RegisterForm,
  WhatsupNumberLabelStyled,
  WhatsupNumberLabelStyledText,
  WhatsupNumberLabelStyledTitle
} from './register-styled';
import { SvgIcon } from '../../components/svg-icon/svg-icon';
import { GlobalInputSelect } from '../../components/global-input-select/global-input-select';
import { Tooltip } from '../../components/tooltip/tooltip';
import { Radio } from '../../components/radio/radio';
import { useEffect, useRef, useState } from 'react';
import { GlobalCheckbox } from '../../components/global-checkbox/global-checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/slices/auth/authSlice';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { Alert } from 'pages/dashboard/components/alert/alert';
import PasswordStrengthBar from 'react-password-strength-bar';
import localStorage from 'redux-persist/es/storage';
import { set } from 'lodash';

const WhatsupNumberLabel = () => {
  return (
    <WhatsupNumberLabelStyled>
      <WhatsupNumberLabelStyledTitle>WhatsApp Number</WhatsupNumberLabelStyledTitle>
      <Tooltip placement='bottom-start'>
        <WhatsupNumberLabelStyledText>
          Enter your WhatsApp number here so we can contact you
        </WhatsupNumberLabelStyledText>
      </Tooltip>
    </WhatsupNumberLabelStyled>
  );
};

export const Register = () => {
    const isAuth = useSelector((state) => state.auth);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState('idle');

    const navigate = useNavigate();

    const [accountType, setAccountType] = useState('business');
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [checkedTerms, setCheckedTerms] = useState('');

    const elementRef = useRef(null);
    useEffect(() => {
        if (loading === 'loading') {
            elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elementRef.current.focus();
        }
    }, [loading]);
    
    const dispatch = useDispatch();
    
    const handleChange = (e) => {
        setAccountType(e.target.value);
    };
    
    useEffect(() => {
        if (isAuth.isAuth) {
            navigate('/dashboard');
        }
    }, [loading]);

    // TODO: Do error handling for the form
    
    const handleSubmit = () =>{
        setErr(null);
        const details = {
            accountType: accountType,
            whatsAppNumber: whatsAppNumber,
            email: username,
            firstName:firstName,
            lastName:lastName,
            password:password,
            passwordConfirmation:passwordConfirmation,
        }

        if (details.password !== details.passwordConfirmation) {
            setErr(`Passwords do not match. Please try again.`);
            if (err && errRef.current) {
                errRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                errRef.current.focus();
            }
            return;
        }

        for (const key in details) {
            if (details[key] === '') {
                setErr(`Please fill in all the required fields.`);
                return;
            }
            if (err && errRef.current) {
                errRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                errRef.current.focus();
            }
        }
        
        setLoading('loading');
        dispatch(registerUser(details)).then((result) => {
            setLoading('idle');
            if(result.error) {
                setErr(result.error);
            }
        });
        
    }
    const errRef = useRef(null);
    
    return (
        <>
        <Header />
        <AuthPagesContainer title={'Sign Up'}>

            { loading == 'loading'
                ? 
                    <ColourRingLoader/>
                :   
                    isAuth.isAuth 
                    ?
                    <>
                        <ColourRingLoader/>
                        <Alert type={'success'}>Success</Alert>
                    </>
                :
                    err && <div ref={errRef}><Alert type={'error'}>{err}</Alert></div> 
            }<br/>

            <RegisterForm ref={elementRef}>
                { loading !== 'loading' ?
                    <>
                    <AccountType>
                        <RadiosWrapper>
                            <Radio
                                id={'personalAccount'}
                                name={'accountType'}
                                value={'personal'}
                                title={'Personal Account'}
                                cb={(e) => handleChange(e)}
                                isChecked={accountType === 'personal'}
                            />
                            <Radio
                                id={'businessAccount'}
                                name={'accountType'}
                                value={'business'}
                                title={'Business Account'}
                                isChecked={accountType === 'business'}
                                cb={(e) => handleChange(e)}
                            />
                        </RadiosWrapper>
                        {/* <CompareLink>
                            <Link to={'#'}>Compare accounts</Link>
                        </CompareLink> */}
                    </AccountType>
                    <InputGroupWrapper>
                        <InputGroup label={<WhatsupNumberLabel />}>
                            <GlobalInputSelect
                                onChange={(combinedValue) => setWhatsAppNumber(combinedValue)}
                                placeholder={'Your WhatsApp number'}
                                type={'number'}
                                value={whatsAppNumber}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'First name'}>
                            <GlobalInput
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder={'Your first name'}
                                value={firstName}
                            />
                            </InputGroup>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'last name'}>
                            <GlobalInput
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder={'Your last name'}
                                value={lastName}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'E-mail'}>
                            <GlobalInput placeholder={'Your email address'} 
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'Password'}>
                            <GlobalInputPassword 
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </InputGroup>
                        {/* <PasswordStrengthBar password={password} /> */}
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                        <InputGroup label={'confirm password'}>
                            <GlobalInputPassword
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                eyeButton={'false'}
                            />
                        </InputGroup>
                    </InputGroupWrapper>
                    <AcceptTerms>
                        <GlobalCheckbox isChecked={true} onChange={(e) => setCheckedTerms(e.target.value)}>
                            I accept the <Link to={'#'}>Terms and Conditions</Link>
                        </GlobalCheckbox>
                    </AcceptTerms>

                    <RegisterButton
                        height={52} fullWidth
                        onClick={handleSubmit}
                    >
                        
                        <SvgIcon name={'account'} />
                        Sign Up
                    </RegisterButton>

                    <LoginSection>
                        <GrayText>Already have an account?</GrayText>
                        <StyledLink>
                            <Link to={'/login'}>Login</Link>
                        </StyledLink>
                    </LoginSection>

                    {/*
                    
                    TODO: Add OAuth registration

                    <GoogleFacebookAuth description={'Or continue with'}>
                        <GrayText>Already have an account?</GrayText>
                        <StyledLink>
                            <Link to={'/login'}>Login</Link>
                        </StyledLink>
                    </GoogleFacebookAuth> */}
                </>
                : null
            }
            </RegisterForm>
        </AuthPagesContainer>
    </>
  );
};

import React from 'react'
import ContentLoader from 'react-content-loader'

const ProductLoaderDefault = (props) => {
    return (
        <ContentLoader
            speed={2}
            // width={476}
            // height={750}
            viewBox="0 0 476 750"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="11" y="247" rx="1" ry="1" width="16" height="6" /> 
            <path d="M 108.75 177.02 v 3.28 c 0 1.67 -1.36 3.02 -3.02 3.02 H 13.68 c -1.67 0 -3.03 -1.35 -3.03 -3.02 v -3.28 c 0 -1.67 1.36 -3.02 3.03 -3.02 h 92.05 c 1.66 0 3.02 1.35 3.02 3.02 z" /> 
            <circle cx="150" cy="236" r="13" /> 
            <path d="M 149.59 147.15 c 0 -3.07 2.48 -5.56 5.54 -5.56 s 5.55 2.49 5.55 5.56 s -2.48 5.54 -5.55 5.54 s -5.54 -2.48 -5.54 -5.54 z M 174 7.32 v 120.52 H 0 V 5.46 C 0 2.44 2.44 0 5.46 0 h 161.22 c 4.04 0 7.32 3.28 7.32 7.32 z M 117.4 152.69 c -3.06 0 -5.54 -2.48 -5.54 -5.54 s 2.48 -5.56 5.54 -5.56 s 5.55 2.49 5.55 5.56 s -2.48 5.54 -5.55 5.54 z" /> 
            <rect x="11" y="160" rx="3" ry="3" width="129" height="9" /> 
            <path d="M 136.49 152.69 c -3.06 0 -5.55 -2.48 -5.55 -5.54 s 2.49 -5.56 5.55 -5.56 s 5.55 2.49 5.55 5.56 s -2.48 5.54 -5.55 5.54 z" />
        </ContentLoader>
    )
}

export default ProductLoaderDefault
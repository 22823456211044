import React, { useState, useMemo} from 'react';
import { ReactComponent as BurgerIcon } from '../../../../assets/burger-icon.svg';
import { ReactComponent as DeleteIco } from '../../../../assets/icons/delete-ico.svg';
import ico1 from '../../../../assets/icons/ico1.png';
import ico2 from '../../../../assets/icons/ico2.png';
import ico3 from '../../../../assets/icons/ico3.png';
import starMobile from '../../../../assets/icons/star-ico.svg';
import star from '../../../../assets/icons/star.svg';
import { ReactComponent as View } from '../../../../assets/icons/view.svg';
import { Breadcrumbs } from '../../../../components/breadcrumbs/breadcrumbs';
import { GlobalButton } from '../../../../components/global-button/global-button';
import Header from '../../../../components/header/header';
import InputWithLabel from '../../../../components/input-with-label/input-with-label';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../company-page/components/products/dropdown/dropdown';
import { Sidebar } from '../../components/sidebars/sidebar-menu/menu';
import {
  Box,
  ButtonAllShow,
  ButtonMobile,
  Categories,
  Category,
  Container,
  ControlsSecond,
  DashboardBtnWrapper,
  DeleteButton,
  Img,
  ImgMobile,
  Main,
  Navigation,
  Photo,
  Product,
  ProductBox,
  ProductButtons,
  ProductDescription,
  ProductInfo,
  ProductPhoto,
  ProductPrice,
  ProductRating,
  ProductRatingTitle,
  Products,
  ProductsBox,
  ProductStatus,
  ProductTitle,
  RatingCount,
  SidebarWrapper,
  SortBy,
  Span,
  Title,
  ViewButton
} from './favorite-products-styled.jsx';
import { addProductToCart, addProductToWishlist, removeProductFromWishlist } from '../../../../redux/slices/product/productSlice';
import { useDispatch , useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

const breadcrumbs = [
  { link: '#', name: 'Favorites' },
  { link: '#', name: 'Favorite Products' }
];

const categories = [
  {
    name: 'All Categories',
    quantity: 120,
    isActive: true
  },
  {
    name: 'Clothing & Apparel',
    quantity: 27,
    isActive: false
  },
  {
    name: 'Food & Grocery',
    quantity: 87,
    isActive: false
  },
  {
    name: 'Real Estate',
    quantity: 35,
    isActive: false
  },
  {
    name: 'Food & Grocery',
    quantity: 115,
    isActive: false
  },
  {
    name: 'Real Estate',
    quantity: 5,
    isActive: false
  }
];

const products = [
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico1,
    reting: 31,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$150',
    isActive: true
  },
  {
    title: 'NEW YORK - Embroidered hip hop snapback adjustable',
    category: 'General Merchandise',
    ico: ico2,
    reting: 26,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$450',
    isActive: false
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico3,
    reting: 37,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$280',
    isActive: true
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico1,
    reting: 36,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$320',
    isActive: true
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico2,
    reting: 46,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$550',
    isActive: true
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico3,
    reting: 86,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$750',
    isActive: true
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico1,
    reting: 36,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$150',
    isActive: true
  },
  {
    title: 'General Plumbing',
    category: 'General Merchandise',
    ico: ico2,
    reting: 42,
    review: 4.1,
    description:
      'Lorem ipsum dolor sit amet consectetur. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo ornare viverra amet. At orci elit tellus etiam. Quam etiam nec nullam morbi leo ornare viverra. Quam etiam nec nullam morbi leo...',
    price: '$550',
    isActive: true
  }
];

export const FavoriteProducts = () => {
  const dispatch = useDispatch();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [search, setSearch] = React.useState('');
  const { productsInCart, wishlist } = useSelector((state) => state.product);
  
  const handleAddToCart = (product) => {
      // dispatch(addProductToCart({ product, quantity: 1, variation: { color: 'red', size: 'M' } }));
      dispatch(addProductToCart({ product, quantity: 1, variation: { } }))
  };

  const handleRemoveFromWishlist = (product) => {
      dispatch(removeProductFromWishlist({ productId: product.id }));
  };

  const hideSidebar = () => {
    setIsSidebarVisible(false);
    document.querySelector('body').style.overflow = 'auto';
  };

  const showSidebar = () => {
    setIsSidebarVisible(true);
    document.querySelector('body').style.overflow = 'hidden';
  };

  const isProductInCart = (product) => productsInCart?.some(item => item.product.id === product.id);

 // Generate categories from wishlist products
 const categories = useMemo(() => {
  const categoryMap = {};
  wishlist?.forEach(product => {
    product?.categories?.forEach(category => {
      if (!categoryMap[category]) {
        categoryMap[category] = 0;
      }
      categoryMap[category]++;
    });
  });
  return Object.entries(categoryMap).map(([name, quantity]) => ({ name, quantity, isActive: false }));
 }, [wishlist]);
  
  return (
    <>
      <Breadcrumbs links={breadcrumbs} />
      <Title>Favorite Products</Title>
      <Navigation>
        <InputWithLabel
          height={'38px'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={'Enter what you are looking for...'}
          borderColor={'#7CCC67'}
        />
        <ControlsSecond>
          <SortBy>Sort by:</SortBy>
          <Dropdown name={'Default'} />
        </ControlsSecond>
        <ButtonMobile>
          <SvgIcon name='sort' />
          <p>Sort</p>
        </ButtonMobile>
      </Navigation>
      <Categories>
      {categories?.map((category, i) => (
          <Category key={i} isActive={category.isActive}>
            {category.name} ({category.quantity})
          </Category>
        ))}
      </Categories>
      <ProductsBox>
        <Products>
          {wishlist?.map((product, i) => (
            <Product key={i}>
              <ProductPhoto>
                <Photo src={product.image?.url} alt={product?.title} />
              </ProductPhoto>
              <ProductBox>
                <ProductTitle>{product?.title}</ProductTitle>
                <ProductInfo>
                  {product?.category}
                  <ProductRating>
                    <Img src={star} alt='star' />
                    <ImgMobile src={starMobile} alt='star' />
                    <ProductRatingTitle>{product?.rating}</ProductRatingTitle>
                    <RatingCount>({product?.totalReviews})</RatingCount>
                  </ProductRating>
                </ProductInfo>
                <ProductDescription>{product.description?.substring(0, 80)}</ProductDescription>
                <Box>
                  <ProductPrice>{product.price?.normalPrice?.amount}</ProductPrice>
                  <ProductButtons>
                    {product?.availability?.status ? (
                      <>
                        {isProductInCart(product) ? 
                          <Link to={'/checkout'}>
                           <GlobalButton type={'green'}>
                            <Span>Checkout</Span>
                            </GlobalButton>
                          </Link> :
                            <GlobalButton type={'green'} onClick={() => handleAddToCart(product)}>
                            <SvgIcon name='cart' />
                            <Span>Add to cart</Span>
                          </GlobalButton>
                        }
                      </>
                    ) : (
                      <ViewButton>
                        <GlobalButton type={'secondary'} isActive={product?.availability?.status}>
                          <View />
                          View Similar
                        </GlobalButton>
                      </ViewButton>
                    )}
                    {product?.availability?.status && (
                      <GlobalButton type={'orange-secondary'}>
                        <SvgIcon name='wallet' />
                        Buy Now
                      </GlobalButton>
                    )}
                    <DeleteButton onClick={() => handleRemoveFromWishlist(product)}>
                      <DeleteIco />
                    </DeleteButton>
                  </ProductButtons>
                </Box>
                {product?.availability?.status ? (
                  <ProductStatus isActive={product?.availability?.status}>Available</ProductStatus>
                ) : (
                  <ProductStatus isActive={product?.availability?.status}>Not Available</ProductStatus>
                )}
              </ProductBox>
            </Product>
          ))}
        </Products>
        {/* <ButtonAllShow>
          <GlobalButton type={'orange-secondary'}>
            <View />
            See more products
          </GlobalButton>
        </ButtonAllShow> */}
      </ProductsBox>
    </>
  );
};

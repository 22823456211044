import styled, { css } from 'styled-components';

export const RadioContainer = styled.div`
    position: relative;
    padding-left: 28px;
`;

export const Icon = styled.span`
    cursor: pointer;
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
    svg {
        display: block;
    }
    circle {
        &:first-child {
            stroke: var(--main-gray-2);
        }
        fill: transparent;
    }
`;

export const Label = styled.label`
    ${(props) =>
        !props.isActiveRadio
            ? `
    & > div {
      margin-left: 0px !important;
    }  
  `
            : ''}
`;

export const Input = styled.input`
  display: none;
  &:checked + ${Label} {
    span {
      circle:first-child {
        stroke: var(--main-green);
      }
      circle:nth-child(2) {
        fill: var(--main-green);
      }
    }
  }
  & + ${Label} {
    ${(props) =>
        props.disabled &&
        css`
            ${Icon} {
                cursor: default;
            }
        `}
`;

export const Title = styled.div`
    font-family: 'Lato', sans-serif;
    line-height: 24px;
`;

export const Description = styled.div``;

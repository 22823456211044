// categoriesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const cattegoriesMenuURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_GET_CATTEGORY_MENU_URL;


const preCategories =  [
  {
      "title": "Electronic Goods",
      "url": "http://e-zim.com/category/electronic-goods/",
      "ID": 27008,
      "parent": "0",
      "subitems": [
          {
              "id": 27009,
              "title": "Home appliances",
              "url": "http://e-zim.com/category/electronic-goods/home-appliances/"
          },
          {
              "id": 27010,
              "title": "Computers",
              "url": "http://e-zim.com/category/electronic-goods/computers/"
          },
          {
              "id": 27011,
              "title": "Mobile &amp; smart phones",
              "url": "http://e-zim.com/category/electronic-goods/mobile-smart-phones-electronic-goods/"
          },
          {
              "id": 27030,
              "title": "Other performance & dj",
              "url": "http://e-zim.com/category/electronic-goods/sound-vision/performance-dj-equipment/other-performance-dj/"
          }
      ]
  },
  {
      "title": "Motor Vehicles",
      "url": "http://e-zim.com/category/motor-vehicles/",
      "ID": 27014,
      "parent": "0",
      "subitems": [
          {
              "id": 27013,
              "title": "Cars",
              "url": "http://e-zim.com/category/motor-vehicles/cars-motor-vehicles/"
          },
          {
              "id": 27016,
              "title": "Cars, trucks &amp; vans",
              "url": "http://e-zim.com/category/home-garden/toys-activities-home-garden/diecast-vehicles-toys-activities-home-garden/cars-trucks-vans-diecast-vehicles-toys-activities-home-garden/"
          },
          {
              "id": 27017,
              "title": "Caravans",
              "url": "http://e-zim.com/category/motor-vehicles/caravans-motor-vehicles/"
          },
          {
              "id": 27018,
              "title": "Farm",
              "url": "http://e-zim.com/category/motor-vehicles/farm-motor-vehicles/"
          }
      ]
  },
  {
      "title": "Books &amp; Stationery",
      "url": "http://e-zim.com/category/books-stationery/",
      "ID": 27019,
      "parent": "0",
      "subitems": [
          {
              "id": 27020,
              "title": "Advanced level",
              "url": "http://e-zim.com/category/books-stationery/advanced-level/"
          },
          {
              "id": 27021,
              "title": "Ordinary level",
              "url": "http://e-zim.com/category/books-stationery/ordinary-level/"
          },
          {
              "id": 27022,
              "title": "Biography &amp; autobiography books",
              "url": "http://e-zim.com/category/books-stationery/biography-autobiography-books-books-stationery/"
          },
          {
              "id": 27029,
              "title": "Children's books",
              "url": "http://e-zim.com/category/books-stationery/childrens-books-books-stationery/"
          }
      ]
  },
  {
      "title": "Health & Beauty",
      "url": "http://e-zim.com/category/health-beauty/",
      "ID": 27025,
      "parent": "0",
      "subitems": [
          {
              "id": 27024,
              "title": "Massage",
              "url": "http://e-zim.com/category/health-beauty/massage/"
          },
          {
              "id": 27026,
              "title": "Face",
              "url": "http://e-zim.com/category/health-beauty/face/"
          },
          {
              "id": 27027,
              "title": "Aging hair",
              "url": "http://e-zim.com/category/health-beauty/hair/aging-hair/"
          },
          {
              "id": 27028,
              "title": "Mom &amp; baby",
              "url": "http://e-zim.com/category/health-beauty/mom-baby/"
          }
      ]
  },
  {
      "title": "Home &amp; Garden",
      "url": "http://e-zim.com/category/home-garden-2/",
      "ID": 27031,
      "parent": "0",
      "subitems": [
          {
              "id": 27032,
              "title": "Garden & patio",
              "url": "http://e-zim.com/category/home-garden/garden-patio/"
          },
          {
              "id": 27033,
              "title": "Diy tools &amp; workshop equipment",
              "url": "http://e-zim.com/category/home-garden/diy-tools-workshop-equipment-home-garden/"
          },
          {
              "id": 27034,
              "title": "Celebrations & occasions",
              "url": "http://e-zim.com/category/home-garden/celebrations-occasions/"
          },
          {
              "id": 27035,
              "title": "Baby essentials",
              "url": "http://e-zim.com/category/home-garden/baby-essentials/"
          }
      ]
  },
  {
      "title": "Construction & Hardware Supplies",
      "url": "http://e-zim.com/category/construction-hardware-supplies/",
      "ID": 27037,
      "parent": "0",
      "subitems": [
          {
              "id": 27039,
              "title": "Tools & equipment",
              "url": "http://e-zim.com/category/construction-hardware-supplies/tools-equipment/"
          },
          {
              "id": 27036,
              "title": "Building &amp; construction",
              "url": "http://e-zim.com/category/trades-services/building-construction-trades/"
          },
          {
              "id": 27040,
              "title": "Garden",
              "url": "http://e-zim.com/category/construction-hardware-supplies/garden/"
          },
          {
              "id": 27041,
              "title": "Boreholes",
              "url": "http://e-zim.com/category/construction-hardware-supplies/boreholes/"
          }
      ]
  }
]


const initialState = {
  categories: preCategories,
  status: 'idle',
  error: null,
};

export const getCattegoryMenu = createAsyncThunk(
    'cattegories/getCategoryMenu',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          cattegoriesMenuURL,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return response.data; // Assuming response contains the category menu
      } catch (err) {
        console.error(err);
        return rejectWithValue(err.response?.data?.message || 'Error fetching categories');
      }
    }
  );


const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher((action) => action.type.endsWith('/pending'), (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addMatcher((action) => action.type.endsWith('/fulfilled'), (state, action) => {
        state.status = 'success';
        if(action.payload?.data?.categoriesMenu !== undefined){
          state.categories = action.payload?.data?.categoriesMenu;
        }else{
          state.categories = preCategories;
        }
        state.error = null;
      })
      .addMatcher((action) => action.type.endsWith('/rejected'), (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default categoriesSlice.reducer;

import { SectionHeader } from 'components/section-header/section-header';
import { ProductsSlider } from 'components/products-slider/products-slider';
import { Container, Wrapper } from './section-with-slider-styled';
import { PCard, ProductCardTypes } from '../../../../components/p-card/p-card';
import { useEffect, useState} from 'react';
import { ProductCardLoader } from './section-loader';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryAndProducts } from '../../../../redux/slices/category/categorySlice';
import { getRouteById } from 'routes/routesHelper';
import ProductCard from 'components/product-card/product-card';

const productss = [
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '30K'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '30K'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '20K',
        badge: 'Sponsored'
    },
    {
        title: 'Master Duplex in Mutare City Centre',
        description: 'Samar Haute Plug | Norton',
        img: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
        ],
        rate: 3.7,
        reviews: 15,
        price: '30K'
    }
];
const loader = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const SectionWithSlider = ({ sectionTitle, slug="", cardType=ProductCardTypes.STORE, isLoading = true }) => {
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState([]);
    const dispatch = useDispatch();
    

    useEffect(() => {
        const params = {
            page: 1,
            per_page: 12,
            ...(slug ? { slug } : { name: sectionTitle }) // Include slug if available, otherwise include name
        };
        dispatch(getCategoryAndProducts(params)).then((res) => {
            setProducts(res.payload?.data?.products);
            setCategory(res.payload?.data?.category);
        });
    }, [sectionTitle]);

    return (
        <Wrapper>
            <Container>
                <SectionHeader link={`/category/${category?.slug}`} title={sectionTitle} seeAllMobile />
            </Container>
            <ProductsSlider>
                {products?.length > 0 ? (
                    products.map((product, index) => (
                        <PCard product={product} business={product.business} type={cardType} key={index} />
                    ))
                ) : (
                    Array.from({ length: 6 }).map((_, index) => (
                        <ProductCardLoader key={index} />
                    ))
                )}
            </ProductsSlider>
        </Wrapper>
    );
};

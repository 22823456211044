import { IconWrapper, Inner, Item, Title } from './featured-categories-cards-styled';
import { SvgIcon } from '../svg-icon/svg-icon';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';

export const PopularCategoriesCards = ({ popularCategories }) => {
    const categoriesArray = Array.isArray(popularCategories?.popularCategories)
        ? popularCategories.popularCategories
        : [];

    const categoryPage = (url) => {
        // TODO match url with categories page
        window.location.href = url;
    };
    return (
        <Inner>
            {categoriesArray?.map((category, index) => {
                const iconName = category.title.split(' ')[0].toLowerCase();
                return (
                    <Link to={`/category/${category?.slug}`} key={index}>
                        <Item key={category.id}>
                            <IconWrapper>
                                <SvgIcon name={iconName} />
                            </IconWrapper>
                            <Title>{category.title}</Title>
                        </Item>
                    </Link>
                );
            })}
        </Inner>
        // <Inner>
        // <Item>
        //     <IconWrapper>
        //         <SvgIcon name={'sport'} />
        //     </IconWrapper>
        //     <Title>Sports, Hobbies & Leisure</Title>
        // </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'home'} />
        //         </IconWrapper>
        //         <Title>Home & Garden</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'clothing'} />
        //         </IconWrapper>
        //         <Title>Clothing & Apparel</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'electronic'} />
        //         </IconWrapper>
        //         <Title>Electronic Goods</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'motor'} />
        //         </IconWrapper>
        //         <Title>Motor Spares & Accesories</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'trades'} />
        //         </IconWrapper>
        //         <Title>Trades & Services</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'farming'} />
        //         </IconWrapper>
        //         <Title>Farming Supplies</Title>
        //     </Item>
        //     <Item>
        //         <IconWrapper>
        //             <SvgIcon name={'vehicles'} />
        //         </IconWrapper>
        //         <Title>Motor Vehicles</Title>
        //     </Item>
        // </Inner>
    );
};

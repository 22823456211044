import { SectionHeader } from 'components/section-header/section-header';
import { PCardSmall } from 'components/p-card-small/p-card-small';

import {
  Card,
  CardContent,
  CardsWrapper,
  CardTop,
  Container,
  Title,
  VisitLink
} from './stores-you-love-section-styled';

import temp1 from 'assets/temp-img/temp1.png';
import temp2 from 'assets/temp-img/temp2.png';
import temp3 from 'assets/temp-img/temp3.png';
import { SectionLink } from '../section-link/section-link';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBusinesses, getPromotedBusinesses } from '../../redux/slices/business/businessSlice';
import { getProducts } from '../../redux/slices/product/productSlice';
import { Link } from 'react-router-dom';
import { getRouteById } from 'routes/routesHelper';

const productList = [
  [
    {
      img: temp1,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'sale',
      labelColor: '#E54D45',
      stock: false
    },
    {
      img: temp2,
      title: 'Peach Slices / Del Monte, 980 g',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: false,
      label: 'sale',
      labelColor: '#E54D45',
      stock: false
    },
    {
      img: temp3,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'sale',
      labelColor: '#E54D45',
      stock: true
    },
    {
      img: temp1,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'new',
      labelColor: '#7CCC67',
      stock: false
    }
  ],
  [
    {
      img: temp1,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'new',
      labelColor: '#7CCC67',
      stock: true
    },
    {
      img: temp2,
      title: 'Peach Slices / Del Monte, 980 g',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: false,
      label: '50%',
      labelColor: '#FFBB00',
      stock: false
    },
    {
      img: temp3,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'sale',
      stock: true
    },
    {
      img: temp3,
      title: 'Fanta orange, 2l',
      old_price: '$9.99',
      price: '$5.50',
      description: 'Samar Haute Plug | Norton',
      rate: 3.5,
      reviews: 51,
      delivery: true,
      label: 'sale',
      stock: false
    }
  ]
];

export const StoresYouLoveSection = ({ link, title }) => {
  const updatedStores = [];
  const [stores, setStores] = useState([]);
  const dispatch = useDispatch();

  const params = {
    limit: 2
  }

  useEffect(() => {
    dispatch( getPromotedBusinesses(params) ).then((response) => {
      setStores(response.payload);
      console.log( 'Stores', response.payload);
    })
  }, [dispatch]);

  return (
    <Container>
      <SectionHeader link={link} title={title} />
      <CardsWrapper>
        {stores?.map((store, index) => {
          return (
            <Card key={index}>
              <CardTop>
                <Title>{store?.name}</Title>
                <VisitLink>
                  <Link to={ getRouteById('storefront', { bSlug:store?.link } ) }>Visit Shop</Link>
                </VisitLink>
              </CardTop>
              <CardContent>
                {store.products.map((product, index) => {
                  return <PCardSmall vendor={store.name} product={product} key={index} />;
                })}
              </CardContent>
            </Card>
          );
        })}
      </CardsWrapper>
      {link && <SectionLink link='#' isMobile />}
    </Container>
  );
};

import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import {
    Container,
    Main,
    Side,
    Title,
    Wrapper
} from '../../pages/add-new-product-single/add-new-product-single-styled';

import { SidePreview } from '../side-preview/side-preview';
import { ProductPromotion } from '../product-promotion/product-promotion';
// import { ProductOffer } from '../add-product-form-components/product-offer';
// import { ProductServiceData } from '../add-product-form-components/product-service-data';

const breadcrumbs = [
    { link: '#', name: 'Sell Items' },
    { link: '#', name: 'My Products' },
    { link: '#', name: 'Add New Product' }
];

// const selectOptions = [
//   { label: 'test', value: 'test' },
//   { label: 'test1', value: 'test1' },
//   { label: 'test2', value: 'test2' }
// ];

export const AddNewProductLayout = ({
    children,
    isSidebarHidden,
    setIsSidebarHidden,
    pageTitle = 'Add New Product',
    isProductPromotion = false,
    sidebarDetails,
    product,
    business
    // isServicePage = false
}) => {
    return (
        <Container>
            <Breadcrumbs links={breadcrumbs} />
            <Title>{pageTitle}</Title>
            {isProductPromotion && <ProductPromotion />}
            {/* {isServicePage ? <ProductOffer selectOptions={selectOptions} /> : <ProductServiceData />}*/}
            <Wrapper>
                <Main>{children}</Main>
                <div
                    style={{
                        position: 'sticky',
                        height: '100vh',
                        top: '150px',
                        alignSelf: 'flex-start',
                        flexBasis: '500px'
                    }}
                >
                    <Side isSidebarHidden={isSidebarHidden}>
                        <SidePreview
                            sidebarDetails={sidebarDetails}
                            product={product}
                            isSidebarHidden={isSidebarHidden}
                            onPreviewButtonClick={() => {
                                setIsSidebarHidden(!isSidebarHidden);
                            }}
                            business={business}
                        />
                    </Side>
                </div>
            </Wrapper>
        </Container>
    );
};

import {
  ButtonMobile,
  FilterButton,
  Header,
  HorizontalLine,
  NearMeBtnWrapper,
  RightSection,
  SearchWrapper,
  SortWrapper,
  TagsWrapper,
  Title,
  TradesNav,
  TradesNavMobile,
  ClearAll,
  Tag,
  TagsSection,
  NearMeDesktopText,
  NearMeMobileText
} from './categories-top-filter-styled';
import { Description } from 'components/product-info/product-info-styled';
import { ViewSelection } from '../view-selection/view-selection';
import filter from '../../assets/filter.svg';
import InputWithLabel from '../input-with-label/input-with-label';
import { GlobalButton } from '../global-button/global-button';
import { ReactComponent as Pin } from '../../pages/categories-real-estate/components/main/real-estate/pin.svg';
import { SelectWithLabel } from '../select-with-label/select-with-label';
import { SvgIcon } from '../svg-icon/svg-icon';
import closeIcon from '../../assets/tag-close-icon.svg';
import { ResultsPerPage } from '../results-per-page/results-per-page';
import React from 'react';
import { Tags } from './components/tags/tags';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { MobSearch } from '../mob-search/mob-search';
import { MobSort } from '../mob-sort/mob-sort';

export const TAGS = [
  {
    name: '$0 - $1mln'
  },
  {
    name: 'Used'
  },
  {
    name: 'Original'
  },
  {
    name: 'Harare Province'
  },
  {
    name: 'Harare CBD'
  },
  {
    name: 'Harare'
  }
];

export const CategoriesTopFilter = ({
  title,
  description,
  searchPlaceholder,
  currentViewType,
  setCurrentViewType,
  onFilterBtnClick,
  setSearch,
  search,
  onNearBtnClick,
  isMobileViewHidden,
  searchTitle = 'Search in real estate'
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Header>
        <Title>{title}</Title>
        {currentViewType && (
          <ViewSelection
            currentViewType={currentViewType}
            setCurrentViewType={setCurrentViewType}
            isMobileHidden={isMobileViewHidden}
          />
        )}
      </Header>
      <HorizontalLine />
      <TradesNav>
        <FilterButton onClick={onFilterBtnClick}>
          <img src={filter} alt='' />
          Filters
        </FilterButton>
        <SearchWrapper>
          <InputWithLabel
            height={'38px'}
            value={search}
            name={searchTitle}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={searchPlaceholder}
            borderColor={'#7CCC67'}
            width={'100%'}
          />
        </SearchWrapper>
        <NearMeBtnWrapper>
          <GlobalButton
            type={'orange-secondary'}
            onClick={() => {
              navigate('near-you');
            }}
          >
            <Pin />
            <NearMeDesktopText>Find near you</NearMeDesktopText>
            <NearMeMobileText>Near you</NearMeMobileText>
          </GlobalButton>
        </NearMeBtnWrapper>

        <SortWrapper>
          <SelectWithLabel label={'Sort by:'}></SelectWithLabel>
        </SortWrapper>
      </TradesNav>
      <TradesNavMobile>
        <FilterButton onClick={onFilterBtnClick}>
          <img src={filter} alt='' />
          Filters
        </FilterButton>
        <RightSection>
          <Link to={onNearBtnClick ? '/store-finder' : ''}>
            <NearMeBtnWrapper>
              <GlobalButton
                type={'orange-secondary'}
                onClick={() => {
                  navigate('near-you');
                }}
              >
                <Pin />
                <NearMeDesktopText>Find near you</NearMeDesktopText>
                <NearMeMobileText>Near you</NearMeMobileText>
              </GlobalButton>
            </NearMeBtnWrapper>
          </Link>
          <MobSearch />
          <MobSort />
        </RightSection>
      </TradesNavMobile>
      <TagsWrapper>
        <Tags />
        <ResultsPerPage />
      </TagsWrapper>
    </>
  );
};

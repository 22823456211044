import {
    AttributesInputGroupRow,
    CheckboxWrapper,
    ColorDotGreen,
    ColorDotRed,
    ColorDotYellow,
    FeaturesRow,
    InputGroupWrapper,
    Section,
    SectionTitle,
    TagsSection
} from '../../pages/add-new-product-single/add-new-product-single-styled';
import { InputGroup } from '../../../../components/input-group/input-group';
import { GlobalSelect } from '../../../../components/global-select/global-select';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import { Tag } from '../../pages/add-new-product-single/components/tag/tag';
import { TagsWrapper } from 'components/categories-top-filter/categories-top-filter-styled';
import { GlobalInput } from '../../../../components/global-input/global-input';
import { SvgIcon } from '../../../../components/svg-icon/svg-icon';
import { GlobalButton } from '../../../../components/global-button/global-button';
import { useState } from 'react';
import { CreatableGlobalSelect } from 'components/global-select/creatableSelect';
import CreatableSelect from 'react-select/creatable';
import { useEffect } from 'react';
import { min } from 'lodash';

export const ProductAttributes = ({
    selectOptions,

    setProductAttributes,
    productAttributes,
    updateProductDetails,
    categoryAttributes
}) => {
    useEffect(() => {
        console.log(categoryAttributes);
    }, [categoryAttributes]);
    const [attributes, setAttributes] = useState([{ name: '', value: '' }]);
    const [attributesValues, setAttributesValues] = useState([]);

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleAddTag = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };
    const handleChange = (event, value) => {
        console.log('Received value:', value);
        setTags(value);
    };
    useEffect(() => {
        console.log('these are the received attibutes', categoryAttributes);
    }, [categoryAttributes]);
    const handleAddAttribute = () => {
        setAttributes([...attributes, { name: '', value: '' }]);
        setTags([]);
    };

    const handleAttributeChange = (index, selectedOption) => {
        console.log('selected option', selectedOption);

        const isCustomAttribute = !categoryAttributes.some(
            (attr) => attr.name === selectedOption.label
        );

        const updatedAttributes = [...attributes];
        updatedAttributes[index] = {
            name: selectedOption.label,
            isNew: isCustomAttribute,
            value: ''
        };
        setAttributes(updatedAttributes);
        setProductAttributes(updatedAttributes);
        updateProductDetails('productAttributes', updatedAttributes);
        setAttributesValues(selectedOption.value);

        // const selectedAttrDetails = categoryAttributes.find(
        //     (attr) => attr.id === selectedOption.value
        // );
        // setAttributesValues(selectedAttrDetails.values);
        // console.log('Selected attribute value:', attributesValues);

        // const updatedAttributes = [...attributes];
        // updatedAttributes[index].name = selectedOption.label;
        // setAttributes(updatedAttributes);
        // setProductAttributes(updatedAttributes);
        // updateProductDetails('productAttributes', updatedAttributes);
    };

    const handleValueChange = (index, selectedOption) => {
        const updatedAttributes = [...attributes];
        updatedAttributes[index].value = selectedOption.label;
        setAttributes(updatedAttributes);
        setProductAttributes(updatedAttributes);
        updateProductDetails('productAttributes', updatedAttributes);
    };

    const customStyles = {
        menuPortal: (base) => ({
            ...base,
            zIndex: 1050
        }),
        control: (base) => ({
            ...base,
            borderColor: '#D6D6D6',
            '&:hover': { borderColor: 'darkgreen' },
            boxShadow: 'none'
        }),
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isFocused ? 'green' : isSelected ? 'green' : undefined,
            height: '35px',
            color: isFocused || isSelected ? 'white' : undefined,
            ':active': {
                backgroundColor: 'green'
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: 'green'
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: 'white'
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: 'white',
            ':hover': {
                backgroundColor: 'darkgreen',
                color: 'white'
            }
        })
    };

    return (
        <Section>
            <SectionTitle>Product Attributes</SectionTitle>
            {attributes.map((attr, index) => (
                <InputGroupWrapper key={index}>
                    <AttributesInputGroupRow
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px'
                        }}
                    >
                        <InputGroup label={'Attribute name'}>
                            <CreatableSelect
                                styles={customStyles}
                                options={
                                    categoryAttributes
                                        ? categoryAttributes.map((attribute) => ({
                                              label: attribute.name,
                                              value: attribute.values
                                          }))
                                        : []
                                }
                                placeholder={'Select or type attribute name'}
                                value={{ label: attr.name, value: attr.name }}
                                onChange={(value) => handleAttributeChange(index, value)}
                                menuPortalTarget={document.body}
                                menuPosition='absolute'
                            />
                        </InputGroup>
                        <InputGroup label={'Value'}>
                            <CreatableSelect
                                styles={customStyles}
                                options={
                                    Array.isArray(attributesValues)
                                        ? attributesValues.map((attribute) => ({
                                              label: attribute.name,
                                              value: attribute.id
                                          }))
                                        : []
                                }
                                placeholder={'Select or type attribute value'}
                                value={{ label: attr.value, value: attr.value }}
                                onChange={(value) => handleValueChange(index, value)}
                                menuPortalTarget={document.body}
                                menuPosition='absolute'
                            />
                        </InputGroup>
                    </AttributesInputGroupRow>
                </InputGroupWrapper>
            ))}

            <InputGroupWrapper>
                <GlobalButton type={'orange-secondary'} onClick={handleAddAttribute}>
                    <SvgIcon name={'plus'} /> Add New Attributes
                </GlobalButton>
            </InputGroupWrapper>
        </Section>
    );
};
{
    /* <CheckboxWrapper style={{ marginTop: '20px' }}>
                            <GlobalCheckbox
                                id={'variants'}
                                checked={hasVariants}
                                onChange={handleHasVariantsChange}
                            >
                                Has Variations
                            </GlobalCheckbox>
                        </CheckboxWrapper> */
}
{
    // const selectedAttrDetails = categoryAttributes.find(
    //     (attr) => attr.id === selectedOption.value
    // );
    // setAttributesValues(selectedAttrDetails.values);
    // const updatedAttributes = [...attributes];
    // updatedAttributes[index].name = selectedOption.label;
    // // Reset or set the value for the new attribute if necessary
    // updatedAttributes[index].value = ''; // Or set default value if you want
    // setAttributes(updatedAttributes);
    // setProductAttributes(updatedAttributes);
    // updateProductDetails('productAttributes', updatedAttributes);
    /* <CreatableSelect
                                styles={customStyles}
                                options={
                                    attributesValues
                                        ? attributesValues.map((attribute) => ({
                                              label: attribute.name,
                                              value: attribute.id
                                          }))
                                        : []
                                }
                                placeholder={'Select or type attribute value'}
                                value={{ label: attr.value, value: attr.value }}
                                onChange={(value) => handleValueChange(index, value)}
                                menuPortalTarget={document.body}
                                menuPosition='absolute'
                            />

                            <TagsWrapper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',

                                    marginTop: '1px'
                                }}
                            >
                                <Tag onClick={() => {}}>Value</Tag>
                            </TagsWrapper> */
}
// <InputGroupWrapper key={index}>
//     <AttributesInputGroupRow>
//         <InputGroup label={'Attribute name'}>
//             <CreatableSelect
//                 styles={customStyles}
//                 options={
//                     categoryAttributes
//                         ? categoryAttributes.map((attribute) => ({
//                               label: attribute.name,
//                               value: attribute.id
//                           }))
//                         : []
//                 }
//                 placeholder={'Select or type attribute name'}
//                 value={{ label: attr.name, value: attr.name }}
//                 onChange={(value) => handleAttributeChange(index, value)}
//                 menuPortalTarget={document.body}
//                 menuPosition='absolute'
//             />
//         </InputGroup>
//         &nbsp;
//         <InputGroup label={'Value'}>
//             <CreatableSelect
//                 styles={customStyles}
//                 options={
//                     attributesValues
//                         ? attributesValues.map((attribute) => ({
//                               label: attribute.name,
//                               value: attribute.id
//                           }))
//                         : []
//                 }
//                 placeholder={'Select or type attribute value'}
//                 value={{ label: attr.value, value: attr.value }}
//                 onChange={(value) => handleValueChange(index, value)}
//                 menuPortalTarget={document.body}
//                 menuPosition='absolute'
//             />
//             <InputGroupWrapper>
//                 <TagsWrapper>
//                     {/* {categories.map((cat) => (
//                     <Tag key={cat.id} onClick={() => handleRemoveCategory(cat)}>
//                         {cat.name}
//                     </Tag>
//                 ))} */}
//                     <Tag onClick={() => {}}>Value</Tag>
//                 </TagsWrapper>
//             </InputGroupWrapper>
//         </InputGroup>
//     </AttributesInputGroupRow>
// </InputGroupWrapper>
// <InputGroupWrapper key={attr.id}>
//     <AttributesInputGroupRow>
//         <InputGroup label={'Attribute name'}>
//             <GlobalSelect
//                 options={selectOptions}
//                 placeholder={'Select or type attribute name'}
//                 value={{ label: attr.name, value: attr.name }}
//                 onChange={(value) => handleAttributeChange(index, value)}
//             />
//         </InputGroup>
//         &nbsp;
//         <InputGroup label={'Value'}>
//             <GlobalSelect
//                 options={attributesValues}
//                 value={{ label: attr.value, value: attr.value }}
//                 onChange={(value) => handleValueChange(index, value)}
//             />
//         </InputGroup>
//     </AttributesInputGroupRow>
// </InputGroupWrapper>
{
    /* <Box sx={{ padding: 0, height: '36px', maxHeight: '36px' }}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    options={
                                        attributesValues
                                            ? attributesValues.map((attribute) => ({
                                                  label: attribute.name,
                                                  value: attribute.id
                                              }))
                                            : []
                                    }
                                    value={tags}
                                    onChange={handleChange}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={index}
                                                label={option.label}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            onChange={(e) =>
                                                handleValueChange(index, e.target.value)
                                            }
                                            {...params}
                                            // label='Add or select value'
                                            variant='outlined'
                                            // placeholder='Add or select value'
                                            sx={{
                                                height: '38px',
                                                padding: '0px 4px',
                                                '.MuiOutlinedInput-root': {
                                                    height: '38px',
                                                    padding: '0px'
                                                },
                                                '.MuiInputBase-input': {
                                                    padding: '6px',
                                                    height: '16px',
                                                    lineHeight: '16px'
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box> */
}

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { linkWithUnderline } from '../../assets/style-helpers/typography';
import { device } from '../../assets/style-helpers/breakpoints';

export const Container = styled.div``;

export const CardsWrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    justify-content: space-between;
  }
  @media ${device.mobile} {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const Card = styled.div`
  background: var(--light-bg);
  border-radius: 12px;
  padding: 24px 20px 30px;
  flex-grow: 1;

  @media ${device.tablet} {
    flex-basis: 100%;
    padding: 0;
    background: none;
  }

  & + & {
    margin-left: 20px;
    @media ${device.tablet} {
      margin-left: 44px;
    }
    @media ${device.mobile} {
      margin-top: 24px;
      margin-left: 0;
    }
  }
`;

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-weight: 600;
`;

export const VisitLink = styled(Link)`
  ${linkWithUnderline};
`;

export const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  @media (max-width: 1680px) {
    grid-template-columns: 1fr 1fr  1fr;

    > div:last-child {
      display: none;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    > div {
      &:nth-child(2),
      &:nth-child(4) {
        display: none;
      }
    }
  }
`;

import React from 'react';
import ContentLoader from 'react-content-loader';
import { MyItem } from 'components/featured-categories-cards/featured-categories-cards-styled';

export const ProductCardLoader = ({ height = 360, width = 650, ...props }) => (
    <MyItem>
      <ContentLoader viewBox={`0 0 ${width} ${height}`} height={height} width={width} {...props}>
        <rect x='0' y='0' rx='1' ry='1' width={width} height={height} />
      </ContentLoader>
    </MyItem>
  );
import {
  ButtonMobile,
  CardsSection,
  ClearTags,
  Close,
  ControlsSecond,
  FilterButton,
  FilterTags,
  FindBox,
  FoodAndGroceryStyled,
  Header,
  HorizontalLine,
  RightSection,
  ShowMore,
  SortBy,
  Tag,
  Title,
  TradesNav,
  TradesNavMobile
} from './general-styled';

import { GlobalButton } from '../../../../../components/global-button/global-button';
import {
  Container,
  TitleWrapper
} from 'pages/store-front-trades-services/components/our-services/our-services-styled';
import { ReactComponent as CloseIco } from '../../../../../assets/close.svg';
import { PCard, ProductCardTypes } from '../../../../../components/p-card/p-card';
import {PCardStoreFront} from '../../../../../components/p-card/p-card-store-front';
import { SvgIcon } from '../../../../../components/svg-icon/svg-icon';
import Dropdown from '../../../../company-page/components/products/dropdown/dropdown';
import { PaginationWrapper } from 'pages/categories-motor-vehicles/components/main/motor-vehicles/motor-vehicles-styled';
import Pagination from 'components/pagination/pagination';
import image1 from '../../../../../assets/categories_motor_vehicles_image1.png';
import image2 from '../../../../../assets/categories_motor_vehicles_image2.png';
import image3 from '../../../../../assets/categories_motor_vehicles_image3.png';
import filter from '../../../../../assets/filter.svg';
import miles from '../../../../../assets/miles.svg';
import product from '../../../../../assets/product2.png';
import year from '../../../../../assets/year.svg';
import { useNavigate } from 'react-router';
import { CARS } from '../../../../../dummy-data/cars';
import { CategoriesTopFilter } from '../../../../../components/categories-top-filter/categories-top-filter';
import React, { useState, useEffect} from 'react';
import { viewType } from '../../../../../components/view-selection/view-selection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCategoryAndProducts } from '../../../../../redux/slices/category/categorySlice';
import { use } from 'react';
import { Link } from 'react-router-dom';
import ColourRingLoader from 'components/loaders/colour-ring-loader/ColourRingLoader';
import { ProductCardLoader } from 'pages/home/components/section-with-slider/section-loader';
import { LoadMore } from 'components/load-more/load-more';
import { CardSection } from 'pages/checkout/checkout-styled';

export const TYPES = [
  {
    name: 'Vehicle Type'
  },
  {
    name: 'Make'
  },
  {
    name: 'Model'
  },
  {
    name: 'Steering'
  },
  {
    name: 'Suburb'
  },
  {
    name: 'City'
  }
];

export const CARDS = CARS;

const FOOD_PRODUCTS_LIST = [
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: false,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  },
  {
    title: 'ALD / New Era Yankees Hat, black',
    subtitle: 'General Merchandise | Norton',
    price: '$19.90',
    rate: 4.8,
    reviews: 51,
    description: 'Samar Haute Plug | Norton',
    img: product,
    status: true,
    old_price: '$39.99'
  }
];

const ActiveTags = ['$0 - $1mln', 'Used', 'Original', 'Harare Province', 'Harare'];

const General = ({ onFilterBtnClick }) => {
  const { slug, page } = useParams();
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [currentView, setCurrentView] = useState(viewType.grid);
  const [search, setSearch] = React.useState('');
  const [moreProducts, setMoreProducts] = React.useState([]);
  const { category, products, productsPagination, categoryLoading } = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPage(1);
    setMoreProducts([]);
    dispatch(getCategoryAndProducts({ slug: slug, page: 1, per_page: 12 })).then((result) => {
      setMoreProducts(result.payload?.data?.products);
    });
  }, [dispatch, slug]);

  useEffect(() => {
    if (currentPage > 1) {
      dispatch(getCategoryAndProducts({ slug: slug, page: currentPage, per_page: 12 })).then((result) => {
        setMoreProducts((prevProducts) => [...prevProducts, ...result?.payload?.data?.products]);
      });
    }
  }, [dispatch, slug, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <FoodAndGroceryStyled>
      {/* <CategoriesTopFilter
        title={category?.name}
        description={category?.description}
        searchTitle={`Search in ${category?.name}`}
        onFilterBtnClick={onFilterBtnClick}
        currentViewType={currentView}
        setCurrentViewType={setCurrentView}
        isMobileViewHidden={false}
        setSearch={setSearch}
        search={search}
        searchPlaceholder={`Type what you need`}
      /> */}
       <TitleWrapper>
            <Title>{category?.name}</Title>
        </TitleWrapper>
   
        <CardsSection currentView={currentView}>
            {moreProducts?.map((data, index) => (
              <PCardStoreFront
                product={data}
                business={data.business}
                withLabel={true}
                type={ProductCardTypes.CLOTHING}
                key={index}
                currentViewType={currentView}
              />
            ))}
      </CardsSection>

      {
        categoryLoading &&
          <h2>Products Loading .....</h2>
      }

      {
        !categoryLoading && products?.length === 0 &&
          <h2>No products found</h2>
      }    

     {productsPagination?.totalProducts > moreProducts?.length && products?.length > 0 && (
        <ShowMore>
          <GlobalButton type="orange" onClick={handleLoadMore}>Load More</GlobalButton>
        </ShowMore>
      )}
    </FoodAndGroceryStyled>
  );
};

export default General;
import { useEffect, useState } from 'react';

import { ProductDetails } from '../../components/add-product-form-components/product-details';
import { ProductCategories } from '../../components/add-product-form-components/product-categories';
import { ProductImage } from '../../components/add-product-form-components/product-image';
import { ProductPricing } from '../../components/add-product-form-components/product-pricing';
import { ProductInventory } from '../../components/add-product-form-components/product-inventory';
import { ProductAttributes } from '../../components/add-product-form-components/product-attributes';
import { ProductSubmitBtns } from '../../components/add-product-form-components/product-submit-btns';
import { AddNewProductLayout } from '../../components/add-new-product-layout/add-new-product-layout';
import { set } from 'react-hook-form';
import { getBusiness } from '../../../../redux/slices/business/businessSlice';
import { useDispatch, useSelector } from 'react-redux';
import { method } from 'lodash';
import { getCategories, getAttributes } from '../../../../redux/slices/category/categorySlice';

const categoriesExample = [
    { link: '#', name: 'Electronics' },
    { link: '#', name: 'Laptops' },
    { link: '#', name: 'Gaming uploading' }
];
const selectOptions = [
    { label: 'test', value: 'test' },
    { label: 'test1', value: 'test1' },
    { label: 'test2', value: 'test2' }
];
const productConditions = [
    { label: 'New', value: 'New' },
    { label: 'Used', value: 'Used' },
    { label: 'Refurbished', value: 'Refurbished' }
];

const attributesValues = [
    { label: 'Nike', value: 'nike' },
    { label: 'Red', value: 'red' },
    { label: '5 meters', value: '' }
];
const currencies = [
    { label: 'USD', value: 'usd' },
    { label: 'ZIG', value: 'zig' },
    { label: 'Rand', value: 'Rand' }
];
export const AddNewProductSingle = ({ isProductPromotion = true }) => {
    const [isSidebarHidden, setIsSidebarHidden] = useState(true);
    const [sidebarDetails, setSidebarDetails] = useState({});

    const [productDetails, setProductDetails] = useState({
        productName: '',
        productDescription: '',
        productCondition: ''
    });
    const [productPrices, setProductPrices] = useState({
        regularPrice: { currency: 'USD', amount: '' },
        salePrice: { currency: 'USD', amount: '' }
    });
    const [productCategory, setProductCategory] = useState([]);
    const [productStock, setProductStock] = useState('');
    const [backOrders, setBackOrders] = useState(false);
    const [productAttributes, setProductAttributes] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [deliveryMethod, setDeliveryMethods] = useState([]);
    // const [categoryId, setCategoryId] = useState(null);

    // Business stuff
    const [business, setBusiness] = useState(null);
    const bSlug = 'world-class-homes';
    const dispatch = useDispatch();
    useEffect(() => {
        const getBusinessDetails = async () => {
            try {
                const response = await dispatch(getBusiness({ bSlug }));
                if (response.payload.message === 'success') {
                    console.log(response.payload.data);
                    setBusiness(response.payload.data);
                } else {
                    console.log('Failed to get business', response);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getBusinessDetails();
    }, []);

    // Categories and attributes stuff
    const [categoryId, setCategoryId] = useState(null);

    const { attributes, status, error } = useSelector((state) => state.categories);
    const [categories, setCategories] = useState([]);
    const [categoryAttributes, setCategoryAttributes] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    // useEffect(() => {
    //     const getAllCategories = async () => {
    //         try {
    //             const response = await dispatch(getCategories({ page, per_page: perPage }));
    //             console.log('Response', response);
    //             setCategories(response.payload.data);
    //             // const response = await dispatch(getCategories());
    //             // if (response.payload.message === 'success') {
    //             //     console.log(response.payload.data);
    //             //     setCategories(response.payload.data);
    //             // }
    //         } catch (err) {
    //             console.log('Error', err);
    //         }
    //     };
    //     getAllCategories();
    // }, [page, perPage, dispatch]);

    useEffect(() => {
        const getAllCategories = async () => {
            try {
                const response = await dispatch(getCategories({ page, per_page: perPage }));
                console.log(response);
                if (response.payload.message === 'success') {
                    setCategories((prevCategories) => [
                        ...prevCategories,
                        ...response.payload.data
                    ]);
                }
            } catch (err) {
                console.log('Error', err);
            }
        };

        getAllCategories();
    }, [page, perPage, dispatch]); // Fetch data when `page` or `perPage` changes

    const handleMenuScrollToBottom = () => {
        setPage((prevPage) => prevPage + 1); // Increment the page when user scrolls to the bottom
    };

    useEffect(() => {
        const getCategoryAttibutes = async () => {
            if (categoryId) {
                try {
                    const response = await dispatch(getAttributes(categoryId));
                    if (response.payload.message === 'success') {
                        setCategoryAttributes(response.payload.data);
                        console.log('category attributes', categoryAttributes);
                    }
                } catch (err) {
                    console.log('Error fertching attributes', err);
                }
            }
        };
        getCategoryAttibutes();
    }, [categoryId, dispatch]);

    const handleCategoryChange = (newCategoryId) => {
        setCategoryId(newCategoryId);
    };

    // Product stuff
    const updateProductDetails = (key, value) => {
        if (key in productDetails) {
            setProductDetails((prevDetails) => ({
                ...prevDetails,
                [key]: value
            }));
        } else if (key === 'productPrices') {
            setProductPrices(value);
        } else if (key in productCategory) {
            setProductCategory((prevCategories) => ({
                ...prevCategories,
                [key]: value
            }));
        } else if (key === 'productAttributes') {
            setProductAttributes(value);
        } else if (key === 'productImages') {
            setProductImages(value);
        } else if (key === 'deliveryMethod') {
            setDeliveryMethods(value);
        }
    };
    const [product, setProduct] = useState({
        title: '',
        description: '',
        condition: '',
        availability: { status: false },
        price: productPrices,
        categories: [],
        inventory: { quantity: '', backOrders: false },
        images: [],
        attributes: [],
        deliveryMethod: []
    });

    useEffect(() => {
        const updatedProduct = {
            title: productDetails.productName,
            description: productDetails.productDescription,
            condition: productDetails.productCondition.label,
            availability: { status: productStock > 0 ? true : false },
            price: {
                regularPrice: productPrices.regularPrice,
                salePrice: productPrices.salePrice
            },
            categories: [
                productCategory.parentCategory,
                productCategory.childCategory,
                productCategory.grandChildCategory
            ],
            inventory: { quantity: productStock, backOrders: backOrders },
            images: productImages,
            attributes: productAttributes,
            deliveryMethod: deliveryMethod
        };
        if (updatedProduct.categories[1] !== categoryId) {
            setCategoryId(updatedProduct.categories[1]);
        }
        setProduct(updatedProduct);
    }, [
        productDetails,
        productPrices,
        productCategory,
        productStock,
        backOrders,
        productImages,
        productAttributes,
        deliveryMethod,
        categoryId
    ]);

    return (
        <AddNewProductLayout
            isSidebarHidden={isSidebarHidden}
            setIsSidebarHidden={setIsSidebarHidden}
            pageTitle={'Add Product'}
            sidebarDetails={sidebarDetails}
            product={product}
            business={business}
            // isProductPromotion={isProductPromotion}
        >
            <ProductDetails
                selectOptions={productConditions}
                updateProductDetails={updateProductDetails}
                productDetails={productDetails}
            />
            <ProductPricing
                selectOptions={currencies}
                updateProductDetails={updateProductDetails}
                productPrices={productPrices}
            />
            <ProductImage
                productImages={productImages}
                setProductImages={setProductImages}
                updateProductDetails={updateProductDetails}
            />
            <ProductInventory
                updateProductDetails={updateProductDetails}
                productStock={productStock}
                setProductStock={setProductStock}
                backOrders={backOrders}
                setBackOrders={setBackOrders}
            />

            <ProductCategories
                selectOptions={selectOptions}
                updateSidebar={setSidebarDetails}
                breadcrumbs={categoriesExample}
                updateProductDetails={updateProductDetails}
                productCategory={productCategory}
                setProductCategory={setProductCategory}
                categories={categories}
            />
            <ProductAttributes
                selectOptions={attributes}
                attributesValues={attributesValues}
                productAttributes={productAttributes}
                setProductAttributes={setProductAttributes}
                updateProductDetails={updateProductDetails}
                categoryAttributes={categoryAttributes}
            />
            <ProductSubmitBtns
                isSidebarHidden={isSidebarHidden}
                setIsSidebarHidden={setIsSidebarHidden}
                updateProductDetails={updateProductDetails}
                product={product}
                bID={business ? business.bID : null}
            />
        </AddNewProductLayout>
    );
};

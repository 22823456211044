import { defaults } from 'chart.js/auto';
import React from 'react'
import { Bar, Chart, Line } from 'react-chartjs-2';

const GeneralLineChart = (props) => {
    const { data, title } = props

        defaults.maintainAspectRatio = false;
        defaults.responsive = true;

        defaults.plugins.title.display = true;
        defaults.plugins.title.align = "start";
        defaults.plugins.title.font.size = 20;
        defaults.plugins.title.color = "black";
    return (
        <div style={{ padding: "20px", position: "relative", width: "100%", height: "100%" }}>
            <Line
                data={{
                    labels: data?.labels,
                    datasets: [
                        {
                            label: "Product Views",
                            data: data?.products,
                            backgroundColor: "#388638",
                            borderColor: "#388638",
                        },
                        {
                            label: "Storefront Views",
                            data: data?.storefront,
                            backgroundColor: "#465b83",
                            borderColor: "#465b83",
                        },
                        {
                            label: "Attempted Calls",
                            data: data?.calls,
                            backgroundColor: "#FF3030",
                            borderColor: "#FF3030",
                        },
                        {
                            label: "WhatsApp Messages",
                            data: data?.whatsapp,
                            backgroundColor: "#f76d21",
                            borderColor: "#f76d21",
                        },
                    ],
                }}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    elements: {
                        line: {
                            tension: 0.2,
                        },
                    },
                    plugins: {
                        title: {
                            text: title,
                        },
                        legend: {
                            align: "start",
                            labels: {
                                usePointStyle: true, 
                                pointStyle: 'circle',
                                boxWidth: 9, 
                                boxHeight: 9, 
                                padding:20,
                                font: {
                                    weight: 'bold',
                                }
                            },
                            
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                        },
                    }
                }}
            />
        </div>
    )
} 

export default GeneralLineChart
import { GlobalButton } from 'components/global-button/global-button';

import { BusinessLinkCard } from '../business-link-card/business-link-card';
import { Bottom, CartBlockStyled, ProductsList, Total } from './cart-block-styled';
import { ProductItem } from '../product-item/product-item';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckIcon } from './img/check.svg';
import { Link } from 'react-router-dom';
import { PromocodeInput } from '../promocode-input/promocode-input';
import { formatMoney } from 'utils';

export const CartBlock = () => {

  const { productsInCart } = useSelector((state) => state.product);

  const totalValue = productsInCart?.reduce((total, item) => {
    const itemTotal = item.quantity * item.product?.price?.normalPrice?.amount;
    return total + itemTotal;
  }, 0);


  return (
    <CartBlockStyled>
      <BusinessLinkCard />
      <ProductsList>
        {productsInCart?.map((item, i) => {
          return <ProductItem item={item} key={i} />;
        })}
      </ProductsList>
      {productsInCart?.length > 0 && <PromocodeInput />}
      <Total>
        {/* <div>
          <span>Sub Total</span>
          <span>$1150</span>
        </div> */}
        <div>
          <span>Total</span>
          <span>{formatMoney(totalValue)}</span>
        </div>
      </Total>
      <GlobalButton type='primary' fullWidth>
        <CheckIcon />
        Proceed to Checkout
      </GlobalButton>
      <Bottom>
        <Link to={'login'}>Login</Link> for a quick checkout
      </Bottom>
    </CartBlockStyled>
  );
};
